<div class="row no-gutters rounded">
  <ui-location-list-item-header
    class="col-12 col-md pb-2 pl-4 pt-4"
    [number]="number"
    [isWalgreens]="isWalgreens"
    [isBusy]="isBusy"
    [address]="address"
    [distanceMi]="distanceMi"
    (click)="headerClicked.emit()"
  ></ui-location-list-item-header>
  <div
    class="col-12 col-md-8 px-1 px-sm-4 pb-2 pt-4 d-flex flex-column justify-content-between"
  >
    <ui-location-list-item-appointments
      class="mb-1"
      [appointments]="appointments"
      [reasonForNoAppointments]="reasonForNoAppointments"
      (appointmentSelected)="appointmentSelected.emit($event)"
    ></ui-location-list-item-appointments>
    <div
      *ngIf="waitTime != null && waitTime.minutes != null"
      class="small font-weight-bold pl-4"
    >
      Walk-in wait time:
      <span *ngIf="waitTime.hours">{{ waitTime.hours }}&nbsp;hr</span>
      {{ waitTime.minutes }}&nbsp;min
    </div>
  </div>
</div>
