import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import { ProfileSectionEditing } from '@patient-ui/shared/constants';
import {
  CardType,
  IAppointment,
  Patient,
  PaymentMethod,
  PaymentType,
} from '@patient-ui/shared/models';
import * as patientActions from '../patient/patient.actions';
import * as ProfileActions from '../profile/profile.actions';
import { PatientState } from './patient.reducer';
import { patientQuery } from './patient.selectors';
import { PatientService } from './patient.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PatientEffects {
  loadLoggedInPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.loadLoggedInPatient),
      exhaustMap((action) =>
        this.patientService.populatePatient(action.payload).pipe(
          map((payload: Patient) =>
            patientActions.loadLoggedInPatientSuccess({ payload })
          ),
          catchError((_error) =>
            of(patientActions.loadLoggedInPatientFailure())
          )
        )
      )
    )
  );

  loadPatientAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.loadPatientAppointments),
      exhaustMap((_action) =>
        this.patientService.getAppointments().pipe(
          map((appointments: IAppointment[]) =>
            patientActions.loadPatientAppointmentsSuccess({ appointments })
          ),
          catchError((error) =>
            of(patientActions.loadPatientAppointmentsFailure({ error }))
          )
        )
      )
    )
  );

  getEncryptedLpid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.getEncryptedLpid),
      switchMap((action) =>
        this.patientService.getEncryptedLPID(action.patientId).pipe(
          map((encryptedLpid) =>
            patientActions.getEncryptedLpidSuccess({
              encryptedLpid,
            })
          ),
          catchError((error) =>
            of(patientActions.getEncryptedLpidFailure({ error }))
          )
        )
      )
    )
  );

  createOrUpdateOrDeleteAddressSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.createOrUpdateOrDeleteAddressSuccess),
      concatLatestFrom((_status) =>
        this.patientStore.select(
          patientQuery.selectCreateOrUpdateAddressRequestStatus
        )
      ),
      exhaustMap(([_action, status]) =>
        this.patientService
          .updatePatientAddress(
            status.addressOwner!,
            status.addressId? status.addressId : _action.response,
            status.addressAction,
            status.address!,
            status.switchToPrimary
          )
          .pipe(
            switchMap((updatedPatient: Patient) => [
              patientActions.updatePatientAfterProfileChange({
                updatedPatient: updatedPatient,
              }),
              ProfileActions.setSectionInEditMode({
                section: ProfileSectionEditing.none,
              }),
            ]),
            catchError((_error) =>
              of(patientActions.upadatePatientProfileFailure())
            )
          )
      )
    )
  );

  createOrUpdateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.createOrUpdateAddress),
      exhaustMap((action) =>
        this.patientService
          .createOrUpdateAddress(action.addressToUpdate, action.dependentId)
          .pipe(
            map((response) =>
              patientActions.createOrUpdateOrDeleteAddressSuccess({
                response: response,
              })
            ),
            catchError((error) =>
              of(
                patientActions.createOrUpdateOrDeleteAddressFailure({
                  errorResponse: error,
                })
              )
            )
          )
      )
    )
  );

  deleteAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.deleteAddress),
      exhaustMap((action) =>
        this.patientService.deleteAddress(action.addressToDelete).pipe(
          map((response) =>
            patientActions.createOrUpdateOrDeleteAddressSuccess({
              response: response,
            })
          ),
          catchError((error) =>
            of(
              patientActions.createOrUpdateOrDeleteAddressFailure({
                errorResponse: error,
              })
            )
          )
        )
      )
    )
  );

  createOrUpdatePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.createOrUpdatePhone),
      exhaustMap((action) =>
        this.patientService
          .createOrUpdatePhone(action.phoneToUpdate, action.dependentId)
          .pipe(
            map((response) =>
              patientActions.createOrUpdateOrDeletePhoneSuccess({
                response: response,
              })
            ),
            catchError((error) =>
              of(
                patientActions.createOrUpdateOrDeletePhoneFailure({
                  errorResponse: error,
                })
              )
            )
          )
      )
    )
  );

  deletePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.deletePhone),
      exhaustMap((action) =>
        this.patientService.deletePhone(action.phoneToDelete).pipe(
          map((response) =>
            patientActions.createOrUpdateOrDeletePhoneSuccess({
              response: response,
            })
          ),
          catchError((error) =>
            of(
              patientActions.createOrUpdateOrDeletePhoneFailure({
                errorResponse: error,
              })
            )
          )
        )
      )
    )
  );

  createOrUpdateOrDeletePhoneSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.createOrUpdateOrDeletePhoneSuccess),
      concatLatestFrom((_status) =>
        this.patientStore.select(
          patientQuery.selectCreateOrUpdatePhoneRequestStatus
        )
      ),
      exhaustMap(([_action, status]) =>
        this.patientService
          .updatePatientPhone(
            status.phoneOwner!,
            status.phoneId!,
            status.phoneAction,
            status.phone!,
            status.switchToPrimary
          )
          .pipe(
            switchMap((updatedPatient: Patient) => [
              patientActions.updatePatientAfterProfileChange({
                updatedPatient: updatedPatient,
              }),
              ProfileActions.setSectionInEditMode({
                section: ProfileSectionEditing.none,
              }),
            ]),
            catchError((_error) =>
              of(patientActions.upadatePatientProfileFailure())
            )
          )
      )
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.updatePassword),
      exhaustMap((action) =>
        this.patientService.updatePassword(action.passwords).pipe(
          map((_response) => patientActions.updatePasswordSuccess()),
          catchError((error) =>
            of(patientActions.updatePasswordFailure({ errorResponse: error }))
          )
        )
      )
    )
  );

  createDependent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.createDependent),
      exhaustMap((action) =>
        this.patientService.createDependent(action.dependentRegistration).pipe(
          map((response) => {
            if (response.status === 201 && !!response.body) {
              const dependent = this.patientService.patientFactory(
                response.body,
                true
              );
              dependent.relationshipType =
                action.dependentRegistration.relationshipType;
              dependent.usePrimaryAddressOfPrimaryPatient =
                action.dependentRegistration.usePrimaryAddressOfPrimaryPatient;
              dependent.usePrimaryEmailOfPrimaryPatient =
                action.dependentRegistration.usePrimaryEmailOfPrimaryPatient;
              dependent.usePrimaryPhoneOfPrimaryPatient =
                action.dependentRegistration.usePrimaryPhoneOfPrimaryPatient;
              return patientActions.createDependentSucess({
                dependent,
              });
            }
            return patientActions.createDependentFailure({
              errorResponse: response,
              dependentRegistration: action.dependentRegistration,
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              patientActions.createDependentFailure({
                errorResponse: error,
                dependentRegistration: action.dependentRegistration,
              })
            )
          )
        )
      )
    )
  );

  deleteDependent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.deleteDependent),
      exhaustMap((action) =>
        this.patientService.deleteDependent(action.depId).pipe(
          map(() =>
            patientActions.deleteDependentSuccess({ depId: action.depId })
          ),
          catchError((_error) => of(patientActions.deleteDependentFailure()))
        )
      )
    )
  );

  updatePatientDemographics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.updatePatientDemographics),
      exhaustMap((action) =>
        this.patientService
          .updatePatientDemographics(
            action.patientDemographics,
            action.dependentId
          )
          .pipe(
            switchMap((response) => [
              patientActions.updatePatientDemographicsSuccess({
                response: response,
              }),
              ProfileActions.setSectionInEditMode({
                section: ProfileSectionEditing.none,
              }),
            ]),
            catchError((error) =>
              of(patientActions.updatePasswordFailure({ errorResponse: error }))
            )
          )
      )
    )
  );

  updatePatientDemographicsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.updatePatientDemographicsSuccess),
      concatLatestFrom((_status) =>
        this.patientStore.select(
          patientQuery.selectPatientDetailsForDemographicUpdate
        )
      ),
      exhaustMap(([_action, status]) =>
        this.patientService
          .updatePatientWithNewDemographics(
            status.patientToUpdate!,
            status.demographicsToUpdate!
          )
          .pipe(
            switchMap((updatedPatient: Patient) => [
              patientActions.updatePatientAfterProfileChange({
                updatedPatient: updatedPatient,
              }),
              ProfileActions.setSectionInEditMode({
                section: ProfileSectionEditing.none,
              }),
            ]),
            catchError((_error) =>
              of(patientActions.upadatePatientProfileFailure())
            )
          )
      )
    )
  );

  updatePatientNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.updatePatientNotifications),
      exhaustMap((action) =>
        this.patientService
          .updatePatientNotifications(
            action.patientNotifications,
            action.dependentId
          )
          .pipe(
            switchMap((response) => [
              patientActions.updatePatientNotificationsSuccess({
                response: response,
              }),
              ProfileActions.setSectionInEditMode({
                section: ProfileSectionEditing.none,
              }),
            ]),
            catchError((error) =>
              of(patientActions.updatePatientNotificationsFailure({ errorResponse: error }))
            )
          )
      )
    )
  );

  updatePatientNotificationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.updatePatientNotificationsSuccess),
      concatLatestFrom((_status) =>
        this.patientStore.select(
          patientQuery.selectPatientDetailsForNotificationsUpdate
        )
      ),
      exhaustMap(([_action, status]) =>
        this.patientService
          .updatePatientWithNewNotifications(
            status.patientToUpdate!,
            status.notificationsToUpdate!,
            status.notificationPrimaryEmail!
          )
          .pipe(
            switchMap((updatedPatient: Patient) => [
              patientActions.updatePatientAfterProfileChange({
                updatedPatient: updatedPatient,
              }),
              ProfileActions.setSectionInEditMode({
                section: ProfileSectionEditing.none,
              }),
            ]),
            catchError((_error) =>
              of(patientActions.upadatePatientProfileFailure())
            )
          )
      )
    )
  );

  getPatientWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.getPatientWallet),
      exhaustMap(() =>
        this.patientService.getPatientWallet().pipe(
          map((response) => {
            const paymentMethodArray: PaymentMethod[] = [];
            if (response.isSuccessful) {
              if (response.listWalletPayments.length > 0) {
                response.listWalletPayments.forEach((walletInfo) => {
                  let paymentMethod: PaymentMethod;
                  paymentMethod = new PaymentMethod();
                  paymentMethod.walletId = walletInfo.walletId
                    ? walletInfo.walletId
                    : '';
                  paymentMethod.walletName = walletInfo.walletName
                    ? walletInfo.walletName
                    : '';
                  paymentMethod.cardId = walletInfo.cardId
                    ? walletInfo.cardId
                    : '';
                  paymentMethod.firstName = walletInfo.firstName
                    ? walletInfo.firstName
                    : '';
                  paymentMethod.lastName = walletInfo.lastName
                    ? walletInfo.lastName
                    : '';
                  paymentMethod.addressLine1 = walletInfo.addressLine1
                    ? walletInfo.addressLine1
                    : '';
                  paymentMethod.addressLine2 = walletInfo.addressLine2
                    ? walletInfo.addressLine2
                    : '';
                  paymentMethod.city = walletInfo.city ? walletInfo.city : '';
                  paymentMethod.state = walletInfo.state
                    ? walletInfo.state
                    : '';
                  paymentMethod.zip = walletInfo.zip ? walletInfo.zip : '';
                  paymentMethod.country = walletInfo.country
                    ? walletInfo.country
                    : '';
                  paymentMethod.cardType = walletInfo.cardType as CardType;
                  paymentMethod.institutionName = this.getCardName(
                    walletInfo.cardType as CardType
                  );
                  paymentMethod.debitLast4 = walletInfo.maskCard;
                  paymentMethod.accountType = walletInfo.accountType
                    ? walletInfo.accountType
                    : '';
                  paymentMethod.routingNumber = walletInfo.routingNumber
                    ? walletInfo.routingNumber
                    : '';
                  paymentMethod.expirationDate =
                    walletInfo.expMon + '/' + walletInfo.expYr;
                  paymentMethod.accountId = walletInfo.accountId
                    ? walletInfo.accountId
                    : '';
                  paymentMethod.token = walletInfo.token
                    ? walletInfo.token
                    : '';
                  paymentMethod.paymentType =
                    walletInfo.paymentMethod === 'CC'
                      ? PaymentType.CREDIT
                      : PaymentType.ECHECK;
                  paymentMethodArray.push(paymentMethod);
                });
              }
              return patientActions.getPatientWalletSuccess({
                payload: paymentMethodArray,
              });
            } else {
              return patientActions.getPatientWalletFailure();
            }
          }),
          catchError(() => of(patientActions.getPatientWalletFailure()))
        )
      )
    )
  );

  addPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.addPaymentMethod),
      exhaustMap((action) =>
        this.patientService.addPaymentMethod(action.payload).pipe(
          switchMap((response) => [
            patientActions.getPatientWallet(),
            patientActions.addPaymentMethodSuccess({ payload: response }),
          ]),
          catchError(() => of(patientActions.addPaymentMethodFailure()))
        )
      )
    )
  );

  deletePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.deletePaymentMethod),
      exhaustMap((action) =>
        this.patientService
          .deletePaymentMethod(
            action.payload.cardId,
            action.payload.forceDelete
          )
          .pipe(
            map((response) => {
              return patientActions.deletePaymentMethodSuccess({
                payload: response,
              });
            }),
            catchError((response) =>
              of(
                patientActions.deletePaymentMethodFailure({ payload: response })
              )
            )
          )
      )
    )
  );

  setClinicalTrialsPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.setClinicalTrialsPreference),
      exhaustMap((action) =>
        this.patientService.setClinicalTrialPreference(action.payload).pipe(
          map(() => {
            return patientActions.setClinicalTrialsPreferenceSuccess({
              payload: action.payload,
            });
          }),
          catchError((_error) =>
            of(patientActions.setClinicalTrialsPreferenceFailure())
          )
        )
      )
    )
  );

  updateAuthenticationQuizStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.updateAuthenticationQuizStatus),
      exhaustMap((action) =>
        this.patientService
          .populateUserListWithDependents(
            action.updatedPatient,
            action.primaryAccountHolder,
            action.dependents
          )
          .pipe(
            switchMap((_response) => [
              patientActions.updateAuthQuizStatus({
                updatedPatient: action.updatedPatient,
              }),
              patientActions.updatePatientUserList({ payload: _response }),
            ]),
            catchError(() =>
              of( patientActions.updateAuthQuizStatus({
                updatedPatient: action.updatedPatient,
              }))
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private patientStore: Store<PatientState>
  ) {}

  getCardName(_cardTypeCD: CardType) {
    switch (_cardTypeCD) {
      case 'AX':
        return 'AmericanExpress';
      case 'DI':
        return 'Discover';
      case 'MC':
        return 'Mastercard';
      case 'VI':
        return 'Visa';
      case 'EC':
        return 'Bank Payment';
      default:
        return 'OTHER';
    }
  }
}
