<!-- External navigation button -->
<ng-container *ngIf="link && !useRouterLink; else internalNavButton">
  <a
    class="btn"
    [href]="buttonDisabled ? null : link"
    [class.btn-primary]="rank === 'primary' && !alertStatus"
    [class.btn-secondary]="rank === 'secondary' && !alertStatus"
    [class.btn-light]="rank === 'light' && !alertStatus"
    [class.btn-link]="rank === 'link' && !alertStatus"
    [class.btn-success]="alertStatus === 'success'"
    [class.btn-danger]="alertStatus === 'danger'"
    [class.btn-warning]="alertStatus === 'warning'"
    [class.btn-info]="alertStatus === 'info'"
    [class.btn-sm]="size === 'small'"
    [class.btn-lg]="size === 'large'"
    [class.full-width]="fullWidth"
    [class.disabled]="buttonDisabled || inProgress"
    [ngClass]="addClass"
    [style.minWidth.px]="minWidth"
  >
    <i
      *ngIf="iconLeft && !inProgress"
      [ngClass]="{
        'material-icons': true,
        'icon-sm': size === 'small',
        'icon-lg': size === 'large',
        'pad-icon': buttonText.length > 0
      }"
      >{{ iconLeft }}</i
    >
    <span
      *ngIf="inProgress"
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
    <span>{{ buttonText }}</span>
  </a>
</ng-container>

<!-- Internal navigation button -->
<ng-template #internalNavButton>
  <ng-container *ngIf="link && useRouterLink; else regularButton">
    <a
      class="btn"
      [routerLink]="buttonDisabled ? null : link"
      [class.btn-primary]="rank === 'primary' && !alertStatus"
      [class.btn-secondary]="rank === 'secondary' && !alertStatus"
      [class.btn-light]="rank === 'light' && !alertStatus"
      [class.btn-link]="rank === 'link' && !alertStatus"
      [class.btn-success]="alertStatus === 'success'"
      [class.btn-danger]="alertStatus === 'danger'"
      [class.btn-warning]="alertStatus === 'warning'"
      [class.btn-info]="alertStatus === 'info'"
      [class.btn-sm]="size === 'small'"
      [class.btn-lg]="size === 'large'"
      [class.full-width]="fullWidth"
      [class.disabled]="buttonDisabled || inProgress"
      [ngClass]="addClass"
      [style.minWidth.px]="minWidth"
    >
      <i
        *ngIf="iconLeft && !inProgress"
        [ngClass]="{
          'material-icons': true,
          'icon-sm': size === 'small',
          'icon-lg': size === 'large',
          'pad-icon': buttonText.length > 0
        }"
        >{{ iconLeft }}</i
      >
      <span
        *ngIf="inProgress"
        class="spinner-border spinner-border-sm mr-1"
        role="status"
        aria-hidden="true"
      ></span>
      <span>{{ buttonText }}</span>
    </a>
  </ng-container>
</ng-template>

<!-- Regular button -->
<ng-template #regularButton>
  <button
    class="btn"
    [class.btn-primary]="rank === 'primary' && !alertStatus"
    [class.btn-secondary]="rank === 'secondary' && !alertStatus"
    [class.btn-light]="rank === 'light' && !alertStatus"
    [class.btn-link]="rank === 'link' && !alertStatus"
    [class.btn-success]="alertStatus === 'success'"
    [class.btn-danger]="alertStatus === 'danger'"
    [class.btn-warning]="alertStatus === 'warning'"
    [class.btn-info]="alertStatus === 'info'"
    [class.btn-sm]="size === 'small'"
    [class.btn-lg]="size === 'large'"
    [class.full-width]="fullWidth"
    [class.disabled]="buttonDisabled || inProgress"
    [ngClass]="addClass"
    [disabled]="buttonDisabled"
    [type]="buttonType"
    [style.minWidth.px]="minWidth"
    (click)="onClick()"
  >
    <i
      *ngIf="iconLeft && !inProgress"
      [ngClass]="{
        'material-icons': true,
        'icon-sm': size === 'small',
        'icon-lg': size === 'large',
        'pad-icon': buttonText.length > 0
      }"
      >{{ iconLeft }}</i
    >
    <span
      *ngIf="inProgress"
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
    <span>{{ buttonText }}</span>
  </button>
</ng-template>
