import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ui-location-list-item-appointments',
  templateUrl: './location-list-item-appointments.component.html',
  styleUrls: ['./location-list-item-appointments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListItemAppointmentsComponent implements AfterViewInit {
  // TODO: Adapt the input and output data types to what comes back from the API service, and perform formatting
  @Input() appointments: string[] = [];
  /** Text to display when no appointments are available to display */
  @Input() reasonForNoAppointments =
    'There are no available appointments at this location.';
  @Output() appointmentSelected = new EventEmitter<string>();

  @ViewChild('appointmentsArea') private appointmentsArea!: ElementRef<
    HTMLDivElement
  >;
  showPrev = false;
  showNext = false;

  prev(): void {
    this.appointmentsArea.nativeElement.scrollTop -= this.appointmentsArea.nativeElement.offsetHeight;
  }

  next(): void {
    this.appointmentsArea.nativeElement.scrollTop += this.appointmentsArea.nativeElement.offsetHeight;
  }

  @HostListener('window:resize', [])
  updateButtonVisibility(): void {
    const {
      scrollTop,
      offsetHeight,
      scrollHeight,
    } = this.appointmentsArea.nativeElement;
    this.showPrev = scrollTop !== 0;
    // The -1 is added due to high-density displays not scrolling *exactly* the offsetHeight, which makes this
    // calculation return an unexpected result while being off by a partial pixel unit
    this.showNext = scrollHeight - scrollTop - 1 > offsetHeight;
  }

  constructor(public changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.updateButtonVisibility();
    this.changeDetector.detectChanges();
  }
}
