import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function radioValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === '') {
      return {
        invalidRadio: true
      };
    } else {
      return null
    }
  };
}
