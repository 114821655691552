import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Component({
  selector: 'patient-ui-root',
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent implements OnInit {
  constructor(private readonly envService: EnvironmentService) {}

  ngOnInit(): void {
    if (!this.envService.isProduction) {
      this.printEnvData();
    }
    this.uaToLocalStorage();

    const currentUrl: string = window.location.href;
    if (
      sessionStorage.getItem('covid-19-state') &&
      !currentUrl.endsWith('terms-of-use')
    ) {
      window.location.assign('/v1/covid-19-antibody-test');
    }
  }

  uaToLocalStorage(): void {
    localStorage.setItem('requestUserAgent', window.navigator.userAgent);
  }

  printEnvData(): void {
    console.log('Environment:', this.envService.environmentName);
  }
}
