export class OrderEntity {
  pid: Number;
  orderDate: string;
  pwnOrderId: string;
  orderedTests: string;
  firstName: string;
  lastName: string;

  constructor(orderEntity: OrderEntity) {
    this.pid = orderEntity.pid;
    this.orderDate = orderEntity.orderDate;
    this.pwnOrderId = orderEntity.pwnOrderId;
    this.orderedTests = orderEntity.orderedTests;
    this.firstName = orderEntity.firstName;
    this.lastName = orderEntity.lastName;
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get testName(): string {
    switch (this.orderedTests) {
      case '164055': {
        return 'COVID-19 IgG antibody test';
      }
      case '164090': {
        return 'COVID-19 Semi-Quantitative Antibody Test';
      }
      default: {
        return 'Unknown Test';
      }
    }
  }

  get orderId(): number {
    // tslint:disable-next-line:radix
    return parseInt(this.pwnOrderId);
  }
}
