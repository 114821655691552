import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import {
  PortalUser,
  Report,
  ReportHeader,
  ReportHeaderSummary,
} from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getDashboardResults(dependentId?: number): Observable<any> {
    const dependentsUrlSegment = this.getDependentsURlSegment(dependentId);
    const url = `${this.envService.baseUrl}/patients/current${dependentsUrlSegment}/results/headers/summary`;
    return this.http.get<ReportHeaderSummary>(url);
  }

  getResultHeaders(): Observable<any> {
    const url = `${this.envService.baseUrl}/patients/current/results/headers/all`;
    // const url = `http://localhost:8080/patients/current/results/headers/summary/all`;
    return this.http.get<ReportHeader[]>(url);
  }

  private getDependentsURlSegment(dependentId?: number): string {
    return dependentId ? `/dependents/${dependentId}` : '';
  }

  getResultDetail(resultId: string, patient?: PortalUser): Observable<any> {
    let dependentsUrlSegment = '';
    if (patient && patient.isPrimary === 'dependent' && patient.id !== 0) {
      dependentsUrlSegment = this.getDependentsURlSegment(patient.id);
    }
    const url = `${this.envService.baseUrl}/patients/current${dependentsUrlSegment}/results/${resultId}`;
    // console.log(resultId);
    // const url = `http://localhost:8080/patients/current/results`;
    return this.http.get<Report>(url);
  }

  getResultPdf(resultId: string, patient?: PortalUser): Observable<any> {
    let dependentsUrlSegment = '';
    if (patient && patient.isPrimary === 'dependent' && patient.id !== 0) {
      dependentsUrlSegment = this.getDependentsURlSegment(patient.id);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob' as 'text',
    };
    const resultPdfUrl = `${this.envService.baseUrl}/patients/current${dependentsUrlSegment}/results/${resultId}/pdf`;
    return this.http.get<any>(resultPdfUrl, httpOptions as object);
  }
}
