import { Action } from '@ngrx/store';
import {
  IPortalAddress,
  ISmartyStreetsQuery,
  ISmartyStreetsSuggestResponse,
} from '@patient-ui/shared/models';

export enum SmartyStreetsActionTypes {
  SmartyStreetsInitializeSuccess = '[SmartyStreets] SmartyStreets Initialize Success',
  SmartyStreetsSearch = '[SmartyStreets] SmartyStreets Search',
  SmartyStreetsSuggestResults = '[SmartyStreets] SmartyStreets Suggest Results',
  SmartyStreetsAddZipCode = '[SmartyStreets] SmartyStreets Add Zip Code',
  ClearSmartyStreetsSuggestResults = '[SmartyStreets] SmartyStreets Clear Suggest Results',
}

export class SmartyStreetsInitializeSuccess implements Action {
  readonly type = SmartyStreetsActionTypes.SmartyStreetsInitializeSuccess;
  constructor(public readonly payload: boolean) {}
}
export class SmartyStreetsSearch implements Action {
  readonly type = SmartyStreetsActionTypes.SmartyStreetsSearch;
  constructor(public readonly payload: ISmartyStreetsQuery) {}
}
export class SmartyStreetsAddZipCode implements Action {
  readonly type = SmartyStreetsActionTypes.SmartyStreetsAddZipCode;
  constructor(public readonly payload: ISmartyStreetsSuggestResponse) {}
}
export class SmartyStreetsSuggestResults {
  readonly type = SmartyStreetsActionTypes.SmartyStreetsSuggestResults;
  constructor(public readonly payload: IPortalAddress[]) {}
}

export class ClearSmartyStreetsSuggestResults {
  readonly type = SmartyStreetsActionTypes.ClearSmartyStreetsSuggestResults;
}

export type SmartyStreetsActions =
  | SmartyStreetsInitializeSuccess
  | SmartyStreetsSearch
  | SmartyStreetsSuggestResults
  | SmartyStreetsAddZipCode
  | ClearSmartyStreetsSuggestResults;
