<header class="fixed-top">
  <patient-ui-portal-header></patient-ui-portal-header>
</header>
<section class="main-content-wrapper flex-fill pt-8 pt-lg-9">
  <router-outlet></router-outlet>
</section>
<footer class="bg-primary text-light">
  <patient-ui-portal-footer></patient-ui-portal-footer>
</footer>
<patient-ui-portal-timout-modal></patient-ui-portal-timout-modal>
<ng-template #ieNotificationModalRef let-modal>
  <div class="modal-header">
    <h2 class="modal-title flex-fill text-center mt-6">
      <span class="alert-icon material-icons">warning</span> Browser Not
      Supported
    </h2>
  </div>
  <div class="modal-body">
    <div class="custom-modal-body">
      <p>
        Internet Explorer is no longer supported. You will be unable to access
        important features of the site on this browser. We recommend using the
        latest version of Google Chrome or Microsoft Edge.
      </p>
      <p>
        If you’re unable to use a different browser, contact Customer Service at
        1-800-845-6167, Monday through Friday between 8 am and 8 pm EST.
      </p>
    </div>
  </div>
</ng-template>
