import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import {
  FastRegistrationInput,
  FastRegistrationSubmitRequest,
} from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FastRegistrationService {

  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  verifyFastRegistrationInputs(
    fastRegistrationInput: FastRegistrationInput
  ): Observable<any> {
    const FastRegistrationVerifyUrl = `${this.envService.baseUrl}/guest/fastregister-verify`;
    return this.http.post<any>(
      FastRegistrationVerifyUrl,
      JSON.stringify(fastRegistrationInput)
    );
  }

  submitFastRegistrationInputs(
    fastRegistrationInput: FastRegistrationSubmitRequest
  ): Observable<any> {
    const FastRegistrationSubmitUrl = `${this.envService.baseUrl}/guest/fastregister-submit`;
    return this.http.post<any>(
      FastRegistrationSubmitUrl,
      JSON.stringify(fastRegistrationInput)
    );
  }
}
