import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import {
  MedicalRecordShareConsent,
  MedicalRecordShareInput,
} from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MedicalRecordShareService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  verifyMedicalRecordShareInputs(
    medicalRecordShareInput: MedicalRecordShareInput
  ): Observable<any> {
    const medicalRecordShareVerifyUrl = `${this.envService.baseUrl}/guest/celeste-verify`;

    return this.http.post<any>(
      medicalRecordShareVerifyUrl,
      JSON.stringify(medicalRecordShareInput)
    );
  }

  submitMedicalRecordShareConsent(
    medicalRecordShareConsent: MedicalRecordShareConsent
  ): Observable<any> {
    const medicalRecordShareSubmitUrl = `${this.envService.baseUrl}/guest/celeste-submit`;

    return this.http.post<any>(
      medicalRecordShareSubmitUrl,
      JSON.stringify(medicalRecordShareConsent)
    );
  }
}
