import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import * as authActions from '@patient-ui/patient-web/store';
import { AuthState } from '@patient-ui/patient-web/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'patient-ui-portal-timout-modal',
  templateUrl: './portal-timout-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalTimoutModalComponent implements OnDestroy {
  @ViewChild('modalPortalIdle') modal!: TemplateRef<any>;
  destroyed = new Subject();
  timedOut = false;
  timeToIdle = 60 * 15;
  timeToTimeout = 60 * 5;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private store: Store<AuthState>,
    private router: Router
  ) {
    idle.setIdle(this.timeToIdle);
    // sets a timeout period of 5 minutes. after 15 minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(this.timeToTimeout);

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.reset();
    });

    idle.onTimeout.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.modalService.dismissAll();
      this.store.dispatch(authActions.logOut());
      window.history.replaceState(null, '', '/');
      this.router.navigate(['/landing']);
    });

    idle.onIdleStart.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.openModal();
    });

    this.keepalive.interval(15);

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  openModal() {
    this.modalService.dismissAll();
    this.modalService
      .open(this.modal, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          if (result === 'continue') {
            this.reset();
          } else {
            this.store.dispatch(authActions.logOut());
            window.history.replaceState(null, '', '/');
            this.router.navigate(['/landing']);
          }
        },
        (reason) => reason
      );
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
