import { createReducer, on } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export type AuthState = {
  loggedIn: boolean;
  sessionToken: string;
  user: {
    id: string | null;
    login: string | null;
    firstName: string | null;
    lastName: string | null;
  };
  authenticateRequestStatus: RequestStatus;
};

export const AuthInitialState: AuthState = {
  loggedIn: false,
  sessionToken: '',
  user: {
    id: null,
    login: null,
    firstName: null,
    lastName: null,
  },
  authenticateRequestStatus: RequestStatus.NotSent,
};

export const authReducer = createReducer(
  AuthInitialState,
  on(AuthActions.authenticateSuccess, (state, { response }) => ({
    ...state,
    loggedIn: true,
    sessionToken: response.sessionToken,
    id: response._embedded.user.id,
    login: response._embedded.user.profile.login,
    firstName: response._embedded.user.profile.firstName,
    lastName: response._embedded.user.profile.lastName,
    authenticateRequestStatus: RequestStatus.Success,
  })),

  on(AuthActions.authenticateFailure, (state) => ({
    ...state,
    loggedIn: false,
    sessionToken: '',
    id: null,
    login: null,
    firstName: null,
    lastName: null,
    authenticateRequestStatus: RequestStatus.Failure,
  })),

  on(AuthActions.logOut, (state) => ({
    ...state,
    loggedIn: false,
    sessionToken: '',
    user: {
      id: null,
      login: null,
      firstName: null,
      lastName: null,
    },
    authenticateRequestStatus: RequestStatus.NotSent,
  })),
  on(AuthActions.updateLoginInfoSuccess, (state) => ({
    ...state,
    loggedIn: true,
  }))
);
