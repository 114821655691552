import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'ui-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {
  @Input() alertText!: string;
  @Input() alertTextTemplate!: TemplateRef<any>;
  @Input() alertStatus: 'primary' | 'success' | 'danger' | 'warning' | 'info' =
    'danger';
  @Input() sideIconLink!: string;
  @Input() sideIconIcon!: string;
  @Input() sideIconText!: string;
  @Input() showCloseBtn = false;
  @Input() fullWidth = true;
  @Output() handleCloseClick: EventEmitter<void> = new EventEmitter();

  mainIcon = 'warning';

  constructor() {}

  ngOnInit(): void {
    switch (this.alertStatus) {
      case 'primary': {
        this.mainIcon = 'get_app';
        break;
      }
      case 'success': {
        this.mainIcon = 'check_circle';
        break;
      }
      case 'danger': {
        this.mainIcon = 'warning';
        break;
      }
      case 'warning': {
        this.mainIcon = 'error';
        break;
      }
      case 'info': {
        this.mainIcon = 'info';
        break;
      }
      default: {
        this.mainIcon = 'warning';
        break;
      }
    }
  }

  closeAlert(): void {
    if (this.showCloseBtn) {
      this.handleCloseClick.emit();
    }
  }
}
