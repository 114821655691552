import { createReducer, on } from '@ngrx/store';
import { ProfileSectionEditing, RequestStatus } from '@patient-ui/shared/constants';
import { Address, IPhoneValidationResponse} from '@patient-ui/shared/models';
import * as ProfileActions from './profile.actions';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  getPhoenixAddressStatus: RequestStatus;
  phoenixReturnedAddress: Address;
  profileAddressToDeleteId: number;
  deleteAddressRequestStatus: RequestStatus;
  deletePatientAccountRequestStatus: RequestStatus;
  sectionInEditMode: ProfileSectionEditing;
  profileActiveId: number;
  validatePhoneRequestStatus: RequestStatus;
  validatePhoneRequestResponse?: IPhoneValidationResponse;
}

export const ProfileInitialState: ProfileState = {
  getPhoenixAddressStatus: RequestStatus.NotSent,
  phoenixReturnedAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipCode: '',
    valid: false,
  },
  profileAddressToDeleteId: -1,
  deleteAddressRequestStatus: RequestStatus.NotSent,
  deletePatientAccountRequestStatus: RequestStatus.NotSent,
  sectionInEditMode: ProfileSectionEditing.none,
  profileActiveId: 1,
  validatePhoneRequestStatus: RequestStatus.NotSent,
  validatePhoneRequestResponse: undefined,
};

export const ProfileReducer = createReducer(
  ProfileInitialState,

  on(ProfileActions.getPhoenixAddress, (state) => ({
    ...state,
    getPhoenixAddressStatus: RequestStatus.NotSent,
    phoenixReturnedAddress: { ...ProfileInitialState.phoenixReturnedAddress },
  })),

  on(ProfileActions.getPhoenixAddressSuccess, (state, { addressToUpdate }) => ({
    ...state,
    getPhoenixAddressStatus: RequestStatus.Success,
    phoenixReturnedAddress: { ...addressToUpdate },
  })),

  on(ProfileActions.getPhoenixAddressFailure, (state) => ({
    ...state,
    getPhoenixAddressStatus: RequestStatus.Failure,
    phoenixReturnedAddress: { ...ProfileInitialState.phoenixReturnedAddress },
  })),

  on(ProfileActions.deletePatientAccount, (state) => ({
    ...state,
    deletePatientAccountRequestStatus: RequestStatus.Pending,
  })),

  on(ProfileActions.deletePatientAccountSuccess, (state) => ({
    ...state,
    deletePatientAccountRequestStatus: RequestStatus.Success,
  })),

  on(ProfileActions.deletePatientAccountFailure, (state) => ({
    ...state,
    deletePatientAccountRequestStatus: RequestStatus.Failure,
  })),

  on(ProfileActions.setSectionInEditMode, (state, {section}) => ({
    ...state,
    sectionInEditMode: section,
  })),
  
  on(ProfileActions.changeProfileSelection, (state, { activeId }) => ({
    ...state,
    profileActiveId: activeId,
  })),

  on(ProfileActions.validatePhone, (state) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.Pending,
    validatePhoneRequestResponse: undefined,
  })),

  on(ProfileActions.validatePhoneSuccess, (state, {response}) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.Success,
    validatePhoneRequestResponse: response,
  })),

  on(ProfileActions.validatePhoneFailure, (state) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.Failure,
    validatePhoneRequestResponse: undefined,
  })),

  on(ProfileActions.resetPhone, (state) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.NotSent,
    validatePhoneRequestResponse: undefined,
  })),
);
