<div *ngIf="showFooterContent" class="bg-primary pt-6">
  <div class="container-xl text-white">
    <div class="row">
      <div class="col-md-3">
        <a href="https://www.labcorp.com/" target="_blank"
          ><img
            alt="Labcorp"
            class="footer-logo mb-6"
            src="assets/images/logo-labcorp-white.svg"
        /></a>
      </div>
      <div class="col-md-9">
        <!-- Desktop View -->
        <div class="row d-none d-md-flex mb-3">
          <div class="col-md-4">
            <h4 class="text-white mt-0 opacity-4">Guest Services</h4>
            <ul class="list-unstyled">
              <li class="mb-3">
                <a [href]="preCheckUrl" class="text-white" target="_blank"
                  >Make an Appointment</a
                >
              </li>
              <li class="mb-3">
                <a routerLink='invoices/find/#heading' class="text-white"
                  >Billing and Insurance</a
                >
              </li>
              <li class="mb-3">
                <a
                  [href]="patientLitholinkUrl"
                  class="text-white"
                  target="_blank"
                  >Order an At-home 24 Hour Urine Kit
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="text-white mt-0 opacity-4">Labcorp Patient</h4>
            <ul class="list-unstyled">
              <li class="mb-3">
                <a routerLink="landing/#signin" class="text-white">Sign In</a>
              </li>
              <li class="mb-3">
                <a routerLink="/account/registration/" class="text-white"
                  >Create an Account</a
                >
              </li>
              <li class="mb-3">
                <a routerLink="/account/password/reset" class="text-white"
                  >Change Password</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="text-white mt-0 opacity-4">Get Help</h4>
            <ul class="list-unstyled">
              <li class="mb-3">
                <a [href]="helpCenterUrl" class="text-white" target="_blank"
                  >Help Center</a
                >
              </li>
              <li class="mb-3">
                <a
                  href="https://www.labcorp.com/help/contact-info-for-patient/patient-portal-inquiry"
                  class="text-white"
                  target="_blank"
                  >Contact Us</a
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- Mobile View -->
        <div class="row d-md-none mb-3">
          <div class="col-md-12">
            <ul class="lc-footer-accordion">
              <li>
                <a
                  class="d-flex align-items-center"
                  [routerLink]=""
                  [class.active]="active[0]"
                  (click)="toggleSection(0)"
                >
                  <span class="flex-fill">Guest Services</span>
                  <i class="material-icons">{{
                    active[0] ? 'expand_less' : 'expand_more'
                  }}</i>
                </a>
                <ul class="content" [class.collapse]="!active[0]">
                  <li>
                    <a [href]="preCheckUrl" class="small" target="_blank"
                      >Make an Appointment</a
                    >
                  </li>
                  <li>
                    <a routerLink='invoices/find/#heading' class="small"
                      >Billing and Insurance</a
                    >
                  </li>
                  <li>
                    <a
                      [href]="patientLitholinkUrl"
                      class="small"
                      target="_blank"
                      >Order an At-home 24 Hour Urine Kit
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  class="d-flex align-items-center"
                  [routerLink]=""
                  [class.active]="active[1]"
                  (click)="toggleSection(1)"
                >
                  <span class="flex-fill">Labcorp Patient</span>
                  <i class="material-icons">{{
                    active[1] ? 'expand_less' : 'expand_more'
                  }}</i>
                </a>
                <ul class="content" [class.collapse]="!active[1]">
                  <li><a routerLink="/landing/#signin" class="small">Sign In</a></li>
                  <li>
                    <a routerLink="/account/registration/" class="small"
                      >Create an Account</a
                    >
                  </li>
                  <li>
                    <a routerLink="/account/password/reset" class="small"
                      >Change Password</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a
                  class="d-flex align-items-center"
                  [routerLink]=""
                  [class.active]="active[2]"
                  (click)="toggleSection(2)"
                >
                  <span class="flex-fill">Get Help</span>
                  <i class="material-icons">{{
                    active[2] ? 'expand_less' : 'expand_more'
                  }}</i>
                </a>
                <ul class="content" [class.collapse]="!active[2]">
                  <li>
                    <a [href]="helpCenterUrl" class="small" target="_blank"
                      >Help Center</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.labcorp.com/help/contact-info-for-patient/patient-portal-inquiry"
                      class="small"
                      target="_blank"
                      >Contact Us</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showAppButtons">
      <div class="col d-flex justify-content-center justify-content-md-end">
        <ul class="list-inline mb-2">
          <li class="list-inline-item mr-4">
            <a
              href="https://apps.apple.com/us/app/labcorp-patient/id1361264072"
              target="_blank"
              ><img
                alt="Apple App Store"
                class="img-fluid"
                src="assets/images/apple-app-store.svg"
            /></a>
          </li>
          <li class="list-inline-item">
            <a
              href="https://play.google.com/store/apps/details?id=com.labcorp.patientportal&hl=en_US"
              target="_blank"
              ><img
                alt="Google Play"
                class="img-fluid"
                src="assets/images/google-play.svg"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="bg-navy py-4">
  <div class="container-xl text-white">
    <small
      >Labcorp | Patient™ © {{ appYear }} Laboratory Corporation of America®
      Holdings. All Rights Reserved. Visit
      <a href="https://www.labcorp.com/" target="_blank" class="text-white"
        >labcorp.com</a
      >
      |
      <a
        href="https://www.labcorp.com/hipaa-privacy"
        target="_blank"
        class="text-white"
        >Privacy Statement</a
      ></small
    >
  </div>
</div>
