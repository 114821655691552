import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenDetails } from '@patient-ui/shared/models';

@Component({
  template: `
    <div class="d-flex h-100 justify-content-center">
      <div class="text-center align-self-center">
        <div class="spinner-border text-gray" role="status"></div>
        <p class="small text-gray">Loading...</p>
      </div>
    </div>
  `,
})
export class AciTokenHandler implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const tokenDetails: TokenDetails = {
      token: '',
      paymentType: 'CARD',
      debitLast4: '',
      expirationDate: '',
      cardType: '',
      cardBrand: '',
      accountType: '',
      routingNumber: '',
    };
    tokenDetails.token = this.route.snapshot.queryParamMap.get('token') || '';
    tokenDetails.paymentType =
      this.route.snapshot.queryParamMap.get('paymentType') === 'CARD'
        ? 'CARD'
        : 'echeck';
    tokenDetails.debitLast4 =
      this.route.snapshot.queryParamMap.get('debitLast4') || '';
    tokenDetails.expirationDate =
      this.route.snapshot.queryParamMap.get('expirationDate') || '';
    tokenDetails.cardType =
      this.route.snapshot.queryParamMap.get('cardType') || '';
    tokenDetails.cardBrand =
      this.route.snapshot.queryParamMap.get('cardBrand') || '';
    tokenDetails.accountType =
      this.route.snapshot.queryParamMap.get('accountType') || '';
    tokenDetails.routingNumber =
      this.route.snapshot.queryParamMap.get('routingNumber') || '';

    window.top.postMessage(tokenDetails, '*');
  }
}
