<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li *ngFor="let link of links" class="breadcrumb-item">
      <a [routerLink]="link.url" (click)="onClick()"
        ><i *ngIf="links.length === 1" class="material-icons">chevron_left </i
        >{{ link.text }}</a
      >
    </li>
  </ol>
</nav>
