import { MicrobiomeState } from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/constants';

export const INITIAL_MICROBIOME_STATE: MicrobiomeState = {
  microbiomeSurvey: {
    labcorpOrderId: '',
    successful: false,
    microbiomeQuestion: [],
  },
  microbiomeResponse: {
    labcorpOrderId: '',
    dob: '',
    questionAndAnswers: [],
  },
  serviceUnavailable: false,
  getMicrobiomeSurveyRequestStatus: RequestStatus.NotSent,
  getMicrobiomeSurveyRequestError: '',
  postMicrobiomeSurveyResponseStatus: RequestStatus.NotSent,
  postMicrobiomeSurveyResponseError: '',
};
