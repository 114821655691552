import { RequestStatus } from '@patient-ui/shared/constants';
import {
  MicrobiomeSurvey,
  MicrobiomeSurveyResponse,
} from '@patient-ui/shared/models';
import { MicrobiomeActions, MicrobiomeActionTypes } from './microbiome.actions';

export const microbiomeFeatureKey = 'microbiome';

export type MicrobiomeState = {
  microbiomeSurvey: MicrobiomeSurvey;
  microbiomeResponse: MicrobiomeSurveyResponse;
  serviceUnavailable: boolean;
  getMicrobiomeSurveyRequestStatus: RequestStatus;
  getMicrobiomeSurveyRequestError: string;
  postMicrobiomeSurveyResponseStatus: RequestStatus;
  postMicrobiomeSurveyResponseError: string;
};

export const initialState: MicrobiomeState = {
  microbiomeSurvey: {
    labcorpOrderId: '',
    successful: false,
    microbiomeQuestion: [],
  },
  microbiomeResponse: {
    labcorpOrderId: '',
    dob: '',
    questionAndAnswers: [],
  },
  serviceUnavailable: false,
  getMicrobiomeSurveyRequestStatus: RequestStatus.NotSent,
  getMicrobiomeSurveyRequestError: '',
  postMicrobiomeSurveyResponseStatus: RequestStatus.NotSent,
  postMicrobiomeSurveyResponseError: '',
};

export function reducer(
  state: MicrobiomeState = initialState,
  action: MicrobiomeActions
) {
  switch (action.type) {
    case MicrobiomeActionTypes.GetMicrobiomeSurvey:
      // console.log('GetMicrobiomeSurvey');
      state = {
        ...state,
        getMicrobiomeSurveyRequestStatus: RequestStatus.Pending,
      };
      break;
    case MicrobiomeActionTypes.GetMicrobiomeSurveySuccess:
      // console.log('GetMicrobiomeSurveySuccess');
      state = {
        ...state,
        microbiomeSurvey: {
          ...action.payload,
        },
        getMicrobiomeSurveyRequestStatus: RequestStatus.Success,
      };
      break;
    case MicrobiomeActionTypes.GetMicrobiomeSurveyFailure:
      // console.log('GetMicrobiomeSurveyFailure');
      state = {
        ...state,
        getMicrobiomeSurveyRequestStatus: action.payload.status,
        getMicrobiomeSurveyRequestError: action.payload.error,
      };
      break;
    case MicrobiomeActionTypes.ClearMicrobiomeSurveyData:
      // console.log('ClearMicrobiomeSurveyData');
      state = {
        ...state,
        microbiomeSurvey: { ...initialState.microbiomeSurvey },
      };
      break;
    case MicrobiomeActionTypes.UpdateMicrobiomeSurveyAnswers:
      // console.log('UpdateMicrobiomeSurveyAnswers');
      state = {
        ...state,
        microbiomeResponse: {
          ...action.payload,
        },
      };
      break;
    case MicrobiomeActionTypes.PostMicrobiomeSurveyResponse:
      // console.log('PostMicrobiomeSurveyResponse');
      state = {
        ...state,
        postMicrobiomeSurveyResponseStatus: RequestStatus.Pending,
      };
      break;
    case MicrobiomeActionTypes.PostMicrobiomeSurveyResponseSuccess:
      // console.log('PostMicrobiomeSurveyResponseSuccess');
      state = {
        ...state,
        postMicrobiomeSurveyResponseStatus: action.payload,
      };
      break;
    case MicrobiomeActionTypes.PostMicrobiomeSurveyResponseFailure:
      // console.log('PostMicrobiomeSurveyResponseFailure');
      state = {
        ...state,
        postMicrobiomeSurveyResponseStatus: action.payload.status,
        postMicrobiomeSurveyResponseError: action.payload.error,
      };
      break;
  }

  return state;
}
