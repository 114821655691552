import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { phoneTypes } from '@patient-ui/shared/constants';
import {
  Address,
  IAddress,
  IAppointment,
  IDependentRegistration,
  IEmailAddress,
  INotificationPreference,
  INotificationPreferences,
  IPatient,
  IPatientDemographicsUpdate,
  IPhone,
  IUpdatePassword,
  Patient,
  PortalUser,
  WalletDeleteResponse,
  WalletPayment,
  WalletPaymentResponse,
  WalletRetrieveResponse,
} from '@patient-ui/shared/models';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  addPaymentMethod(
    paymentMethod: WalletPayment
  ): Observable<WalletPaymentResponse> {
    return this.http.post<WalletPaymentResponse>(
      `${this.envService.baseUrl}/patients/current/wallet/paymentmethod`,
      paymentMethod
    );
  }

  deletePaymentMethod(
    cardId: string,
    forceDelete: boolean
  ): Observable<WalletDeleteResponse> {
    return this.http.delete<WalletDeleteResponse>(
      `${this.envService.baseUrl}/patients/current/wallet/paymentmethod/${cardId}?forceDelete=${forceDelete}`
    );
  }
  retrievePaymentMethod(): Observable<WalletRetrieveResponse> {
    const retrievePaymentUrl = `${this.envService.baseUrl}/patients/current/wallet/paymentmethod/retrievePayments`;
    return this.http.get<WalletRetrieveResponse>(retrievePaymentUrl);
  }

  populatePatient(patientJson: IPatient): Observable<any> {
    const patient = this.patientFactory(patientJson, false);
    const userArray: PortalUser[] = [];
    const patientName = patient.fullName ? patient.fullName : '';
    const patientId = patient.id ? patient.id : 999;
    //Populate PAH
    userArray.push({
      displayName: patientName,
      id: patientId,
      isPrimary: 'primary',
      quizStatus: patient.authenticationQuizStatus,
    });

    const dependentsJsonObjects: IPatient[] = <IPatient[]>(
      patientJson.dependents
    );
    const depList: Patient[] = [];
    for (const dependentJson of dependentsJsonObjects) {
      const dependent = this.patientFactory(dependentJson['dependent']!, true);
      dependent.relationshipType = dependentJson.relationshipType;
      dependent.usePrimaryAddressOfPrimaryPatient =
        dependentJson.usePrimaryAddressOfPrimaryPatient;
      dependent.usePrimaryPhoneOfPrimaryPatient =
        dependentJson.usePrimaryPhoneOfPrimaryPatient;
      dependent.usePrimaryEmailOfPrimaryPatient =
        dependentJson.usePrimaryEmailOfPrimaryPatient;
      depList.push(dependent);

      const dependentId = dependent.id ? dependent.id : 999;
      const dependentName = dependent.fullName ? dependent.fullName : '';
      // Populate Dependent
      userArray.push({
        displayName: dependentName,
        id: dependentId,
        isPrimary: 'dependent',
        quizStatus: dependent.authenticationQuizStatus,
      });
    }
    if (depList.length > 0) {
      userArray.push({ displayName: 'All Patients', id: 0 });
    }

    const ptState = {
      primaryPatient: patient,
      dependentsList: depList,
      patientUserList: userArray,
    };
    return of(ptState);
  }

  updatePatientAddress(
    patient: IPatient,
    addressId: number,
    addressAction: string,
    address?: IAddress,
    switchToPrimary?: boolean
  ): Observable<Patient> {
    let patientWithSwitch: Patient;
    if (switchToPrimary) {
      patientWithSwitch = <Patient>{
        ...patient,
        addresses: patient.addresses
          .map((iAddresses) => ({ ...iAddresses }))
          .map((iAddress) => {
            if (iAddress!.isPrimary) {
              return {
                ...iAddress,
                isPrimary: false,
              };
            } else {
              return {
                ...iAddress,
                isPrimary: true,
              };
            }
          }),
      };
    } else {
      patientWithSwitch = patient;
    }
    const addressOwner = new Patient();
    let updatedAddress: IAddress[] = [];
    switch (addressAction) {
      case 'delete':
        updatedAddress = patientWithSwitch.addresses.filter(
          (address) => address.id !== addressId
        );
        addressOwner.addresses = [...updatedAddress];
        break;
      case 'update':
        addressOwner.addresses = patientWithSwitch.addresses
          .map((iaddresses) => ({ ...iaddresses }))
          .map((iaddress) => {
            if (iaddress.id === address!.id) {
              return address!;
            } else {
              return iaddress;
            }
          });
        break;
      case 'create':
        const newAddress = address!;
        newAddress.id = addressId;
        addressOwner.addresses = [...patientWithSwitch.addresses, newAddress];
        break;
    }
    const patientToUpdate = <IPatient>{
      ...patientWithSwitch,
      addresses: addressOwner.addresses,
    };
    if (patientWithSwitch.isDependent) {
      patientToUpdate.usePrimaryAddressOfPrimaryPatient =
        address?.usePrimaryAddressOfPrimaryPatient;
    }
    return of(
      this.patientFactory(patientToUpdate, patientToUpdate.isDependent)
    );
  }

  populateUserListItems(patient: IPatient): Observable<PortalUser[]> {
    const userArray: PortalUser[] = [];
    if (patient) {
      const patientName = patient.fullName ? patient.fullName : '';
      const patientId = patient.id ? patient.id : 999;
      //Populate PAH
      userArray.push({
        displayName: patientName,
        id: patientId,
        isPrimary: 'primary',
      });
      if (patient.dependents && patient.dependents.length > 0) {
        for (const dependent of patient.dependents) {
          const dependentId = dependent.id ? dependent.id : 999;
          const dependentName = dependent.fullName ? dependent.fullName : '';
          // Populate Dependent
          userArray.push({
            displayName: dependentName,
            id: dependentId,
            isPrimary: 'dependent',
          });
        }
        //Populate All when dependents.
        userArray.push({ displayName: 'All Patients', id: 0 });
      }
    }

    return of(userArray);
  }

  patientFactory(json: IPatient, isDependent: boolean): Patient {
    const patient = new Patient();
    patient.id = json.id;
    patient.firstName = json.firstName;
    patient.middleName = json.middleName;
    patient.lastName = json.lastName;
    patient.dateOfBirth = json.dateOfBirth;
    patient.addresses = json.addresses;
    patient.phones = json.phones;
    patient.emailAddresses = json.emailAddresses;
    patient.hashId = json.hashId;
    patient.gender = json.gender?.toUpperCase();
    patient.ethnicity = json.ethnicity?.toUpperCase();
    patient.race = json.race?.toUpperCase();
    patient.dependents = [];
    patient.notificationPreferences = json.notificationPreferences;
    patient.relationshipType = json.relationshipType;
    patient.usePrimaryAddressOfPrimaryPatient =
      json.usePrimaryAddressOfPrimaryPatient;
    patient.usePrimaryPhoneOfPrimaryPatient =
      json.usePrimaryPhoneOfPrimaryPatient;
    patient.usePrimaryEmailOfPrimaryPatient =
      json.usePrimaryEmailOfPrimaryPatient;
    patient.authenticationQuizStatus = json.authenticationQuizStatus || '';
    patient.hasFailedMaxVerificationAttempts =
      json.hasFailedMaxVerificationAttempts;
    patient.clinicalTrialsPreferenceStatus = json.clinicalTrialsPreferenceStatus?.toUpperCase();
    patient.profileVerificationDate = json.profileVerificationDate;
    patient.emailVerified = json.emailVerified;
    patient.isDependent = isDependent;
    return patient;
  }

  getPatientWallet(): Observable<WalletRetrieveResponse> {
    const retrieveWalletUrl = `${this.envService.baseUrl}/patients/current/wallet/paymentmethod/retrievePayments`;
    return this.http.get<WalletRetrieveResponse>(retrieveWalletUrl);
  }

  updatePassword(data: IUpdatePassword): Observable<any> {
    const updatePasswordUrl = `${this.envService.baseUrl}/patients/current/password`;
    return this.http.post(updatePasswordUrl, data);
  }

  createOrUpdateAddress(
    address: Address,
    dependentId?: number
  ): Observable<any> {
    const dependentsUrlSegment = dependentId
      ? `dependents/${dependentId}/`
      : '';
    const url =
      `${this.envService.baseUrl}/patients/current/${dependentsUrlSegment}addresses` +
      (address.id ? `/${address.id}` : ``);
    const data = address;
    const POST = 'post';
    const PUT = 'put';
    const verb = address.id ? PUT : POST;
    return this.http[verb](url, data);
  }

  createOrUpdatePhone(phone: IPhone, dependentId?: number): Observable<any> {
    const dependentsUrlSegment = dependentId
      ? `dependents/${dependentId}/`
      : '';
    const url =
      `${this.envService.baseUrl}/patients/current/${dependentsUrlSegment}phones` +
      (phone.id ? `/${phone.id}` : ``);
    const phoneType =
      phoneTypes.filter((pt) => pt.value === phone.type)[0] || phoneTypes[-1];
    let data = <IPhone>{
      ...phone,
      type: phoneType.databaseValue!,
    };
    const POST = 'post';
    const PUT = 'put';
    const verb = phone.id ? PUT : POST;
    return this.http[verb](url, data);
  }

  createDependent(dependentRegistration: IDependentRegistration) {
    return this.http.post<IPatient>(
      `${this.envService.baseUrl}/patients/current/dependents`,
      dependentRegistration,
      {
        observe: 'response',
      }
    );
  }

  deleteDependent(depId: string): Observable<any> {
    return this.http.delete(
      `${this.envService.baseUrl}/patients/current/dependents/${depId}`
    );

    // return this.http.delete(
    //   `http://localhost:8080/patients/current/dependents`
    // );
  }

  deleteAddress(address: Address): Observable<any> {
    return this.http.delete(
      `${this.envService.baseUrl}/patients/current/addresses/${address.id}`
    );
  }

  deletePhone(phone: IPhone): Observable<any> {
    return this.http.delete(
      `${this.envService.baseUrl}/patients/current/phones/${phone.id}`
    );
  }

  updatePatientPhone(
    patient: IPatient,
    phoneId: number,
    phoneAction: string,
    phone?: IPhone,
    switchToPrimary?: boolean
  ): Observable<Patient> {
    let patientWithSwitch: Patient;
    if (switchToPrimary) {
      patientWithSwitch = <Patient>{
        ...patient,
        phones: patient.phones
          .map((iPhones) => ({ ...iPhones }))
          .map((iPhone) => {
            if (iPhone!.isPrimary) {
              return {
                ...iPhone,
                isPrimary: false,
              };
            } else {
              return {
                ...iPhone,
                isPrimary: true,
              };
            }
          }),
      };
    } else {
      patientWithSwitch = patient;
    }
    const phoneOwner = new Patient();
    let updatedPhone: IPhone[] = [];
    switch (phoneAction) {
      case 'delete':
        updatedPhone = patientWithSwitch.phones.filter(
          (phoneItem) => phoneItem.number !== phone?.number
        );
        phoneOwner.phones = [...updatedPhone];
        break;
      case 'update':
        phoneOwner.phones = patientWithSwitch.phones
          .map((iphones) => ({ ...iphones }))
          .map((iphone) => {
            if (iphone.id === phone!.id) {
              return phone!;
            } else {
              return iphone;
            }
          });
        break;
      case 'create':
        const newPhone = phone!;
        newPhone.id = phoneId;
        phoneOwner.phones = [...patientWithSwitch.phones, newPhone];
        break;
    }
    const patientToUpdate = <IPatient>{
      ...patientWithSwitch,
      phones: phoneOwner.phones,
    };
    if (patientWithSwitch.isDependent) {
      patientToUpdate.usePrimaryPhoneOfPrimaryPatient =
        phone?.usePrimaryPhoneOfPrimaryPatient;
    }
    return of(
      this.patientFactory(patientToUpdate, patientToUpdate.isDependent)
    );
  }

  getEncryptedLPID(patientId: number): Observable<string> {
    return this.http.get(
      `${this.envService.baseUrl}/patients/current/${patientId}/encryptLpid`,
      {
        responseType: 'text',
      }
    );
  }

  updatePatientDemographics(
    data: Object,
    dependentId?: number
  ): Observable<any> {
    const dependentsUrlSegment = dependentId
      ? `/dependents/${dependentId}/`
      : '';
    const url = `${this.envService.baseUrl}/patients/current${dependentsUrlSegment}`;
    return this.http.put(url, data);
  }

  updatePatientWithNewDemographics(
    patient: IPatient,
    patientDemographics: IPatientDemographicsUpdate
  ): Observable<Patient> {
    const patientToUpdate = <IPatient>{
      ...patient,
      firstName: patientDemographics.firstName,
      middleName: patientDemographics.middleName,
      lastName: patientDemographics.lastName,
      gender: patientDemographics.gender,
      race: patientDemographics.race,
      ethnicity: patientDemographics.ethnicity,
      relationshipType: patientDemographics.relationshipType,
    };
    return of(
      this.patientFactory(patientToUpdate, !!patientDemographics.isDependent)
    );
  }

  updatePatientNotifications(
    data: INotificationPreferences,
    dependentId?: number
  ): Observable<any> {
    const dependentsUrlSegment = dependentId
      ? `dependents/${dependentId}/`
      : '';
    const url = `${this.envService.baseUrl}/patients/current/${dependentsUrlSegment}notificationPreferences`;
    return this.http.put(url, data);
  }

  updatePatientWithNewNotifications(
    patient: IPatient,
    patientNotifications: INotificationPreference[],
    patientPrimaryEmail: string
  ): Observable<Patient> {
    let updatedEmailList: IEmailAddress[] = [];
    const index = patient.emailAddresses.findIndex((email) => email.isPrimary);
    const newEmailObj = <IEmailAddress>{
      ...patient.emailAddresses[index],
      address: patientPrimaryEmail,
    };
    updatedEmailList = [
      ...patient.emailAddresses.slice(0, index),
      newEmailObj!,
      ...patient.emailAddresses.slice(index + 1),
    ];
    const patientToUpdate = <IPatient>{
      ...patient,
      emailAddresses: updatedEmailList,
      notificationPreferences: patientNotifications,
    };
    return of(
      this.patientFactory(patientToUpdate, patientToUpdate.isDependent)
    );
  }

  getAppointments(): Observable<IAppointment[]> {
    return this.http
      .get<IAppointment[]>(
        `${this.envService.baseUrl}/patients/current/appointments`
      )
      .pipe(
        map((appointments) => {
          const appts: IAppointment[] = [];
          appointments.forEach((appointment) => {
            appts.push({
              ...appointment,
              changeCancelURL: `${this.envService.expressUrl}/manage/${appointment.confirmationNum}?firstName=${appointment.firstName}&=lastName=${appointment.lastName}`,
              confirmationURL: `${
                this.envService.expressUrl
              }/confirmation?lpid=${encodeURIComponent(
                appointment.encryptedLpid
              )}&confirmationNbr=${appointment.confirmationNum}`,
              mapURL: `https://www.google.com/maps?q=${
                appointment.location.line1
              }${
                appointment.location.line2
                  ? `+${appointment.location.line2}`
                  : ''
              }+${appointment.location.city}+${appointment.location.state}+${
                appointment.location.zipCode
              }`,
            });
          });
          return appts;
        })
      );
  }
  setClinicalTrialPreference(status: string) {
    return this.http.post(
      `${this.envService.baseUrl}/patients/current/clinicalTrialsPreference`,
      { status: status }
    );
  }

  getClinicalTrialsAgreementPDF(): Observable<Blob> {
    return this.http.get(
      `${this.envService.baseUrl}/patients/current/clinicalTrialsPreference/pdf?versionNumber=2`,
      { responseType: 'blob' }
    );
  }

  populateUserListWithDependents(
    updatedPatient: Patient,
    primaryPatient: Patient,
    dependents: Patient[]
  ): Observable<PortalUser[]> {
    const userArray: PortalUser[] = [];
    if (primaryPatient) {
      const patientName = primaryPatient.fullName
        ? primaryPatient.fullName
        : '';
      const patientId = primaryPatient.id ? primaryPatient.id : 999;
      const quizStatus =
        primaryPatient.id === updatedPatient.id
          ? updatedPatient.authenticationQuizStatus
          : primaryPatient.authenticationQuizStatus;
      userArray.push({
        displayName: patientName,
        id: patientId,
        isPrimary: 'primary',
        quizStatus: quizStatus,
      });
      if (dependents && dependents.length > 0) {
        for (const dependent of dependents) {
          const dependentId = dependent.id ? dependent.id : 999;
          const dependentName = dependent.fullName ? dependent.fullName : '';
          let quizStatus;
          if (dependent.isMinor && primaryPatient.id === updatedPatient.id) {
            quizStatus = updatedPatient.authenticationQuizStatus;
          } else {
            quizStatus =
              dependent.id === updatedPatient.id
                ? updatedPatient.authenticationQuizStatus
                : dependent.authenticationQuizStatus;
          }

          userArray.push({
            displayName: dependentName,
            id: dependentId,
            isPrimary: 'dependent',
            quizStatus: quizStatus,
          });
        }
        userArray.push({ displayName: 'All Patients', id: 0 });
      }
    }
    return of(userArray);
  }
}
