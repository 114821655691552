<span class="d-inline-block position-relative">
  <svg
    class="marker"
    [class.text-primary]="color === 'blue'"
    [class.red]="color === 'red'"
    viewBox="0 0 27 37"
  >
    <path
      d="M13.125 0C5.869 0 0 5.877 0 13.143c0 7.83 8.287 18.625 11.7 22.737a1.86 1.86 0 002.869 0c3.393-4.112 11.681-14.908 11.681-22.737C26.25 5.877 20.381 0 13.125 0z"
      fill="currentColor"
      fill-rule="nonzero"
    />
  </svg>
  <span class="label position-absolute text-white text-center">
    <ng-content></ng-content>
  </span>
</span>
