import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { IToken } from './../../../../../shared/models/src/lib/IAccountPasswordReset.interface';
import * as AccountActions from './account.actions';
import { AccountService } from './account.service';

@Injectable()
export class AccountEffects {
  getPasswordResetPayload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getPasswordResetPayload),
      exhaustMap((action) =>
        this.accountService.getPasswordResetPayload(action.email).pipe(
          map((passwordResetPayload) =>
            AccountActions.getPasswordResetPayloadSuccess({
              passwordResetPayload,
            })
          ),
          catchError((_error) =>
            of(AccountActions.getPasswordResetPayloadFailure())
          )
        )
      )
    )
  );

  sendPasswordResetVerifyCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.sendPasswordResetVerifyCode),
      exhaustMap((action) =>
        this.accountService
          .sendPasswordResetVerifyCode(action.email, action.sendCodeRequest)
          .pipe(
            map((response: IToken) =>
              AccountActions.sendPasswordResetVerifyCodeSuccess({
                token: response.token,
              })
            ),
            catchError((_error) =>
              of(AccountActions.getPasswordResetPayloadFailure())
            )
          )
      )
    )
  );

  validatePasswordResetVerifyCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.validatePasswordResetVerifyCode),
      exhaustMap((action) =>
        this.accountService
          .validatePasswordResetVerifyCode(
            action.pin,
            action.token,
            action.email
          )
          .pipe(
            map((response) =>
              AccountActions.validatePasswordResetVerifyCodeSuccess({
                token: response.token,
              })
            ),
            catchError((error) =>
              of(
                AccountActions.validatePasswordResetVerifyCodeFailure({
                  error: error.error,
                })
              )
            )
          )
      )
    )
  );

  setNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.setNewPassword),
      exhaustMap((action) =>
        this.accountService
          .setNewPassword(action.newPassword, action.token, action.email)
          .pipe(
            map((_response) => AccountActions.setNewPasswordSuccess()),
            catchError((_error) => of(AccountActions.setNewPasswordFailure()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountService
  ) {}
}
