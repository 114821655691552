import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { Subscription } from 'rxjs';
import { LayoutService } from './../../shared/services/layout.service';

@Component({
  selector: 'patient-ui-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicHeaderComponent implements OnDestroy, OnInit {
  patientLitholinkUrl = '';
  showButton = true;
  withStripe = true;
  headerLinks = true;
  stripeSubscription!: Subscription;
  buttonSubscription!: Subscription;
  headerLinksSubscription!: Subscription;
  isMenuCollapsed = true;
  helpCenterUrl = '';
  preCheckUrl = '';

  constructor(
    private envService: EnvironmentService,
    private layoutService: LayoutService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.helpCenterUrl = `${this.envService.patientUrl}/help-center/home`;
    this.preCheckUrl = this.envService.preCheckUrl;
    this.patientLitholinkUrl = `${this.envService.patientUrl}/litholink`;

    this.stripeSubscription = this.layoutService
      .getShowNavbarStripe()
      .subscribe((stripeState) => {
        this.withStripe = stripeState;
        this.ref.detectChanges();
      });
    this.buttonSubscription = this.layoutService
      .getShowNavbarButton()
      .subscribe((buttonState) => {
        this.showButton = buttonState;
        this.ref.detectChanges();
      });
    this.headerLinksSubscription = this.layoutService
      .getShowHeaderLinks()
      .subscribe((headerLinksState) => {
        this.headerLinks = headerLinksState;
        this.ref.detectChanges();
      });

    const self = this;
    window.addEventListener('click', (evt: any) => {
      if (!document.getElementById('public-header')?.contains(evt.target)) {
        self.collapseMenu();
      }
    }, true)
  }

  ngOnDestroy(): void {
    this.stripeSubscription.unsubscribe();
    this.buttonSubscription.unsubscribe();
    this.headerLinksSubscription.unsubscribe();
  }

  collapseMenu(): void {
    this.isMenuCollapsed = true;
    this.ref.detectChanges();
  }
}
