import { Action } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import {
  IMicrobiomeKitSearch,
  MicrobiomeSurveyResponse,
} from '@patient-ui/shared/models';
import { MicrobiomeSurvey } from '@patient-ui/shared/models';

export enum MicrobiomeActionTypes {
  GetMicrobiomeSurvey = '[Microbiome] Get Microbiome Survey',
  GetMicrobiomeSurveySuccess = '[Microbiome] Get Microbiome Survey Success',
  GetMicrobiomeSurveyFailure = '[Microbiome] Get Microbiome Survey Failure',
  ClearMicrobiomeSurveyData = '[Microbiome] Clear Microbiome Survey Data',
  UpdateMicrobiomeSurveyAnswers = '[Microbiome] Update Microbiome Survey Answers',
  PostMicrobiomeSurveyResponse = '[Microbiome] Post Microbiome Survey Response',
  PostMicrobiomeSurveyResponseSuccess = '[Microbiome] Post Microbiome Survey Response Success',
  PostMicrobiomeSurveyResponseFailure = '[Microbiome] Post Microbiome Survey Response Failure',
}

export class GetMicrobiomeSurvey implements Action {
  readonly type = MicrobiomeActionTypes.GetMicrobiomeSurvey;
  constructor(public readonly payload: IMicrobiomeKitSearch) {}
}

export class GetMicrobiomeSurveySuccess implements Action {
  readonly type = MicrobiomeActionTypes.GetMicrobiomeSurveySuccess;
  constructor(public readonly payload: MicrobiomeSurvey) {}
}

export class GetMicrobiomeSurveyFailure implements Action {
  readonly type = MicrobiomeActionTypes.GetMicrobiomeSurveyFailure;
  constructor(public readonly payload: any) {}
}

export class ClearMicrobiomeSurveyData implements Action {
  readonly type = MicrobiomeActionTypes.ClearMicrobiomeSurveyData;
}

export class UpdateMicrobiomeSurveyAnswers implements Action {
  readonly type = MicrobiomeActionTypes.UpdateMicrobiomeSurveyAnswers;
  constructor(public readonly payload: MicrobiomeSurveyResponse) {}
}

export class PostMicrobiomeSurveyResponse implements Action {
  readonly type = MicrobiomeActionTypes.PostMicrobiomeSurveyResponse;
  constructor(public readonly payload: MicrobiomeSurveyResponse) {}
}

export class PostMicrobiomeSurveyResponseSuccess implements Action {
  readonly type = MicrobiomeActionTypes.PostMicrobiomeSurveyResponseSuccess;
  constructor(public readonly payload: RequestStatus) {}
}

export class PostMicrobiomeSurveyResponseFailure implements Action {
  readonly type = MicrobiomeActionTypes.PostMicrobiomeSurveyResponseFailure;
  constructor(public readonly payload: any) {}
}

export type MicrobiomeActions =
  | GetMicrobiomeSurvey
  | GetMicrobiomeSurveySuccess
  | GetMicrobiomeSurveyFailure
  | ClearMicrobiomeSurveyData
  | UpdateMicrobiomeSurveyAnswers
  | PostMicrobiomeSurveyResponse
  | PostMicrobiomeSurveyResponseSuccess
  | PostMicrobiomeSurveyResponseFailure;
