import { InvoiceState } from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import { PaymentType } from '@patient-ui/shared/models';
import { currentPatient, otherPatient } from './patient.mock';

export const INITIAL_INVOICE_STATE: InvoiceState = {
  invoice: {
    number: '12345',
    zipCode: '27215',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '3060 S. Church St.',
      line2: '',
      city: 'Burlington',
      state: 'NC',
      zipCode: '27215',
    },
    specimenNumber: '12345',
    dateOfService: '2020-01-01',
    invoiceDate: '2020-01-02',
    documentKey: '',
    patientName: 'Test Patient',
    pid: '12345',
    physicianDetail: {
      firstName: 'Test',
      lastName: 'Doctor',
      middleName: '',
      prefix: 'Dr.',
      orgName: '',
      practiceLocationAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    orderingPhyAccountNumber: '',
  },
  findInvoice: {
    number: '',
    zipCode: '',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    specimenNumber: '',
    dateOfService: '',
    invoiceDate: '',
    documentKey: '',
    patientName: '',
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
      middleName: '',
      prefix: '',
      orgName: '',
      practiceLocationAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    orderingPhyAccountNumber: '',
  },
  generateReceiptRequestStatus: RequestStatus.NotSent,
  findInvoiceRequestStatus: RequestStatus.NotSent,
  selectedInvoices: [],
  invoiceSummary: {
    totalNumberOfInvoices: 3,
    collectionsInvoices: [
      {
        number: '67890',
        zipCode: '27215',
        balanceDue: 100,
        invoiceAmount: 100,
        billingAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
        specimenNumber: 'testSpeciman',
        dateOfService: '2020-01-01',
        invoiceDate: '2020-01-01',
        documentKey: '12345_20200101_something_3_filename.pdf',
        patientName: otherPatient.displayName,
        pid: otherPatient.id.toString(),
        physicianDetail: {
          firstName: 'Jane',
          lastName: 'Doe',
          middleName: '',
          prefix: 'Dr.',
          orgName: '',
          practiceLocationAddress: {
            line1: '3060 S. Church St.',
            line2: '',
            city: 'Burlington',
            state: 'NC',
            zipCode: '27215',
          },
        },
        totalCharges: 100,
        adjustments: 0,
        medicarePaid: 0,
        medicaidPaid: 0,
        insurancePaid: 0,
        patientPaid: 0,
        orderingPhyAccountNumber: '1234',
      },
    ],
    currentInvoices: [
      {
        number: '12345',
        zipCode: '27215',
        balanceDue: 100,
        invoiceAmount: 100,
        billingAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
        specimenNumber: 'testSpeciman',
        dateOfService: '2020-01-01',
        invoiceDate: '2020-01-01',
        documentKey: '12345_20200101_something_1_filename.pdf',
        patientName: otherPatient.displayName,
        pid: otherPatient.id.toString(),
        physicianDetail: {
          firstName: 'Jane',
          lastName: 'Doe',
          middleName: '',
          prefix: 'Dr.',
          orgName: '',
          practiceLocationAddress: {
            line1: '3060 S. Church St.',
            line2: '',
            city: 'Burlington',
            state: 'NC',
            zipCode: '27215',
          },
        },
        totalCharges: 100,
        adjustments: 0,
        medicarePaid: 0,
        medicaidPaid: 0,
        insurancePaid: 0,
        patientPaid: 0,
        orderingPhyAccountNumber: '1234',
      },
    ],
    pastDueInvoices: [
      {
        number: '234567',
        zipCode: '27215',
        balanceDue: 100,
        invoiceAmount: 100,
        billingAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
        specimenNumber: 'testSpeciman',
        dateOfService: '2020-01-01',
        invoiceDate: '2020-01-01',
        documentKey: '12345_20200101_something_2_filename.pdf',
        patientName: currentPatient.displayName,
        pid: currentPatient.id.toString(),
        physicianDetail: {
          firstName: 'Jane',
          lastName: 'Doe',
          middleName: '',
          prefix: 'Dr.',
          orgName: '',
          practiceLocationAddress: {
            line1: '3060 S. Church St.',
            line2: '',
            city: 'Burlington',
            state: 'NC',
            zipCode: '27215',
          },
        },
        totalCharges: 100,
        adjustments: 0,
        medicarePaid: 0,
        medicaidPaid: 0,
        insurancePaid: 0,
        patientPaid: 0,
        orderingPhyAccountNumber: '1234',
      },
    ],
    paymentHistory: [],
  },
  invoicePaymentsFilteredByPatient: [],
  invoicePaymentsAllPatients: [],
  serviceUnavailable: false,
  getInvoiceRequestStatus: RequestStatus.NotSent,
  updateInsuranceRequestStatus: RequestStatus.NotSent,
  getPhoenixAddressStatus: RequestStatus.NotSent,
  phoenixReturnedAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipCode: '',
    valid: false,
  },
  getProviderListRequestStatus: RequestStatus.NotSent,
  getInvoiceSummaryRequestStatus: RequestStatus.NotSent,
  providerList: [],
  processingInvoices: [],
  getProcessingInvoicesStatus: RequestStatus.NotSent,
  paymentAuthorizationRequest: {
    accountId: '',
    paymentAccountId: '',
    paymentMethod: PaymentType.CREDIT,
    paymentDate: '',
    firstName: '',
    lastName: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    invoiceList: [],
    emailAddress: '',
    isGuestUser: true,
    browser: '',
    mobile: false,
  },
  paymentAuthorizationResponse: {
    successful: false,
    encryptedTransactionId: '',
    transactionId: '',
    reasonCode: '',
  },
  getPaymentAuthorizationStatus: RequestStatus.NotSent,
  paymentAuthorizationTimeout: false,
  payments: [],
  paymentAuthorizationReceipt: {
    isSuccessful: false,
    transactionId: '',
  },
  getPaymentAuthorizationReceiptStatus: RequestStatus.NotSent,
  getInvoicePdfRequestStatus: RequestStatus.NotSent,
  invoicePdf: undefined,
  tokenDetails: {
    token: '',
    paymentType: 'CARD',
    debitLast4: '',
    expirationDate: '',
    cardType: '',
    cardBrand: '',
    accountType: '',
    routingNumber: '',
  },
  selectDuplicateInvoice: false,
};

export const FILTERED_INVOICES = {
  currentInvoices: [
    {
      number: '1234566',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_2_filename.pdf',
      patientName: 'name one',
      pid: '1111',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 100,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
    {
      number: '12345672',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_1_filename.pdf',
      patientName: 'name two',
      pid: '2222',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 200,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
  ],
};

export const SELECTED_INVOICES = {
  currentInvoices: [
    {
      number: '1234566',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_1_filename.pdf',
      patientName: 'name one',
      pid: '1111',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 100,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
    {
      number: '12345672',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_1_filename.pdf',
      patientName: 'name two',
      pid: '2222',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 200,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
  ],
};
