<nav class="navbar navbar-expand-lg navbar-light bg-white shadow">
  <div class="container-xl justify-content-start">
    <button
      class="navbar-toggler"
      type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed"
      aria-controls="navbarPublic"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="material-icons icon-lg">menu</i>
    </button>
    <a
      class="navbar-brand navbar-logo"
      id="lnkBrandingHome"
      href="https://patient.labcorp.com/"
      ><img alt="Labcorp | Patient" src="assets/images/logo-patient-color.svg"
    /></a>
    <div
      [ngbCollapse]="isMenuCollapsed"
      class="collapse navbar-collapse"
      id="navbarPublic"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngFor="let link of navLinks">
          <a
            [href]="link.url"
            class="nav-link"
            (click)="isMenuCollapsed = true"
            >{{ link.text }}</a
          >
        </li>
      </ul>
      <div *ngIf="showButton" class="form-inline">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</nav>
<div *ngIf="withStripe" class="p-1 bg-primary"></div>
