export interface INotificationPreference {
  notificationType: string;
  isEmailEnabled: boolean;
  isTextEnabled: boolean;
  display?: string;
}
export interface INotificationPreferences {
  primaryEmail: string;
  notificationPreferences: INotificationPreference[];
  usePrimaryEmailOfPrimaryPatient: boolean;
}

export const notificationPreferenceTypes = [
  <INotificationPreference>{
    notificationType: 'TEST_RESULT',
    isEmailEnabled: true,
    isTextEnabled: true,
    display: 'Lab Test Results',
  },
  {
    notificationType: 'INVOICE',
    isEmailEnabled: true,
    isTextEnabled: true,
    display: 'Invoice',
  },
];

export class NotificationPreference implements INotificationPreference {
  notificationType!: string;
  isEmailEnabled: boolean = true;
  isTextEnabled: boolean = true;

  get display(): string {
    return notificationPreferenceTypes.filter(notificationPreferenceType => {
      return notificationPreferenceType.notificationType = this.notificationType;
    })[0].display!;
  }
}
