import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'stripNonNumeric' })
export class StripNonNumericPipe implements PipeTransform {
  // strips all non-numeric values from a string
  // example: (555) 555-5555 == 5555555555
  transform(value: string): string {
    if (!value) return value;
    return value.replace(/\D/g, '');
  }
}
