import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import { profileFeatureKey, ProfileState } from './profile.reducer';

export const selectProfileStateSelector = createFeatureSelector<ProfileState>(
  profileFeatureKey
);

export const selectPhoenixReturnedAddress = createSelector(
  selectProfileStateSelector,
  (state) => state.phoenixReturnedAddress
);

export const selectDeletePatientAccountStatus = createSelector(
  selectProfileStateSelector,
  (state) => state.deletePatientAccountRequestStatus
);

export const selectSectionInEditMode = createSelector(
  selectProfileStateSelector,
  (state) => state.sectionInEditMode
);

export const selectActiveProfileId = createSelector(
  selectProfileStateSelector,
  (state) => state.profileActiveId
);

export const selectValidatePhoneRequestStatus = createSelector(
  selectProfileStateSelector,
    (state) => {
      if (state.validatePhoneRequestStatus === RequestStatus.Success) {
        const responseDescription = {
          responseStatus: RequestStatus.Success,
          phoneNumber: state.validatePhoneRequestResponse?.phoneNumber,
          type: state.validatePhoneRequestResponse?.type,
          ext: state.validatePhoneRequestResponse?.ext,
          status: state.validatePhoneRequestResponse?.status,
        };
        return responseDescription;
      } else {
        const responseDescription = {
          responseStatus: state.validatePhoneRequestStatus,
          phoneNumber: undefined,
          type: undefined,
          ext: undefined,
          status: undefined,
        };
        return responseDescription;
      }
    }
);

export const profileQuery = {
  selectProfileStateSelector,
  selectPhoenixReturnedAddress,
  selectDeletePatientAccountStatus,
  selectSectionInEditMode,
  selectActiveProfileId,
  selectValidatePhoneRequestStatus,
};
