import { createReducer, on } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import {
  PortalUser,
  Report,
  ReportHeader,
  ReportHeaderSummary,
} from '@patient-ui/shared/models';
import * as ResultsActions from './results.actions';
export const resultsFeatureKey = 'results';

export interface ResultsState {
  results: ReportHeaderSummary;
  resultHeaders: ReportHeader[];
  loadResultsPayloadRequestStatus: RequestStatus;
  loadResultHeadersStatus: RequestStatus;
  resultDetail?: Report;
  getResultDetailStatus?: RequestStatus;
  getResultPdfRequestStatus: RequestStatus;
  resultPdf: Blob | undefined;
  resultHistorySelectedUser?: PortalUser;
}

export const ResultsInitialState: ResultsState = {
  results: {
    new: [],
    upcoming: [],
    updated: [],
    recently_viewed: [],
    totalNew: 0,
    totalUpcoming: 0,
    totalUpdated: 0,
    totalRecentlyViewed: 0,
    totalAll: 0,
  },
  resultHeaders: [],
  loadResultsPayloadRequestStatus: RequestStatus.NotSent,
  loadResultHeadersStatus: RequestStatus.NotSent,
  getResultDetailStatus: RequestStatus.NotSent,
  getResultPdfRequestStatus: RequestStatus.NotSent,
  resultPdf: undefined,
};

export const resultsReducer = createReducer(
  ResultsInitialState,
  on(ResultsActions.loadResultsSuccess, (state, { results }) => ({
    ...state,
    results,
    loadResultsPayloadRequestStatus: RequestStatus.Success,
  })),
  on(ResultsActions.loadResultsFailure, (state) => ({
    ...state,
    loadResultsPayloadRequestStatus: RequestStatus.Failure,
  })),
  on(ResultsActions.loadResultHeaders, (state) => ({
    ...state,
    resultHeaders: [],
    loadResultHeadersStatus: RequestStatus.Pending,
  })),
  on(ResultsActions.loadResultHeadersSuccess, (state, results) => ({
    ...state,
    resultHeaders: results.resultHeaders,
    loadResultHeadersStatus: RequestStatus.Success,
  })),
  on(ResultsActions.loadResultHeadersFailure, (state) => ({
    ...state,
    resultHeaders: [],
    loadResultHeadersStatus: RequestStatus.Failure,
  })),
  on(ResultsActions.getResultDetail, (state) => ({
    ...state,
    getResultDetailStatus: RequestStatus.Pending,
  })),
  on(ResultsActions.getResultDetailSuccess, (state, reportDetail) => ({
    ...state,
    resultDetail: reportDetail.report,
    getResultDetailStatus: RequestStatus.Success,
  })),
  on(ResultsActions.getResultDetailFailure, (state) => ({
    ...state,
    getResultDetailStatus: RequestStatus.Failure,
  })),
  on(ResultsActions.getResultPdfSuccess, (state, action) => ({
    ...state,
    resultPdf: action.payload,
    getResultPdfRequestStatus: RequestStatus.Success,
  })),
  on(ResultsActions.getResultPdfFailure, (state) => ({
    ...state,
    resultPdf: undefined,
    getResultPdfRequestStatus: RequestStatus.Failure,
  })),
  on(ResultsActions.clearResultPdf, (state) => ({
    ...state,
    resultPdf: undefined,
    getResultPdfRequestStatus: RequestStatus.NotSent,
  })),
  on(ResultsActions.setResultHistorySelectedUser, (state, {selectedUser}) => ({
    ...state,
    resultHistorySelectedUser: selectedUser,
  })),
  on(ResultsActions.clearResults, (state) => ({
    ...state,
    results: ResultsInitialState.results,
    resultHeaders:ResultsInitialState.resultHeaders,
    loadResultHeadersStatus:ResultsInitialState.loadResultHeadersStatus
  }))
);
