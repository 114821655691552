import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

import {
  BootstrapEnvironment,
  Environment,
  IEnvironmentService,
} from '../types/environment.types';

export const BOOTSTRAP_ENVIRONMENT_TOKEN = new InjectionToken<
  BootstrapEnvironment
>('BootstrapEnvironment');

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironmentService {
  private static _bootstrapEnv: BootstrapEnvironment;
  private env: Environment = {} as Environment;
  readonly APP_ENV_PATH = 'assets/environment.json';

  constructor(
    private http: HttpClient,
    @Inject(BOOTSTRAP_ENVIRONMENT_TOKEN)
    bootstrapEnvironment: BootstrapEnvironment
  ) {
    if (!EnvironmentService._bootstrapEnv) {
      EnvironmentService._bootstrapEnv = bootstrapEnvironment;
    }
  }

  get features() {
    return this.env.feature;
  }

  get isProduction() {
    return EnvironmentService.isProduction;
  }

  get environmentName() {
    return this.env.name;
  }

  get baseUrl() {
    return this.env.api.baseUrl;
  }

  get logoutUrl() {
    return this.env.api.logoutUrl;
  }

  get aciUrl() {
    return this.env.application.aciUrl;
  }

  get patientUrl() {
    return this.env.application.patientUrl;
  }

  get expressUrl() {
    return this.env.application.expressUrl;
  }

  get preCheckUrl() {
    return this.env.application.preCheckUrl;
  }

  get smartyStreetsBaseUrl() {
    return this.env.api.smartyStreetsBaseUrl;
  }

  get smartyStreetsSiteKey() {
    return this.env.api.smartyStreetsSiteKey;
  }

  get paymentContextUrl() {
    return this.env.api.paymentContextUrl;
  }

  get paymentCallbackUrl() {
    return this.env.api.paymentCallbackUrl;
  }

  get paymentSource() {
    return this.env.api.paymentSource;
  }

  get loginOktaUrl() {
    return this.env.okta.loginUrl;
  }

  get logoutOktaUrl() {
    return this.env.okta.logoutUrl;
  }

  get apiGatewayUrl() {
    return this.env.okta.apiGatewayUrl;
  }

  get sessionUrl() {
    return this.env.okta.sessionUrl;
  }

  get relayState() {
    return this.env.okta.relayState;
  }

  get recaptchaKey() {
    console.log(`Env? ${JSON.stringify(this.env)}`);
    console.log(`Env -> Google? ${JSON.stringify(this.env.google)}`);
    console.log(`Returning ${this.env.google.recaptchaKey}`);
    return this.env.google.recaptchaKey;
  }

  loadEnvironment() {
    return this.http
      .get<Environment>(this.APP_ENV_PATH)
      .toPromise()
      .then((runtimeEnv: Environment) => {
        this.env = runtimeEnv;
      });
  }

  static get isProduction() {
    return !EnvironmentService._bootstrapEnv.isDebugEnabled;
  }
}
