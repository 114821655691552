import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import * as patientActions from '@patient-ui/patient-web/store';
import {
  AuthState,
  PatientState,
  selectAuthState,
} from '@patient-ui/patient-web/store';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { Patient } from '@patient-ui/shared/models';
import { BehaviorSubject } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationRequiredGuard implements CanActivate {
  authLoggedIn = new BehaviorSubject(false);
  loggedInPatient = new BehaviorSubject(false);
  destroyed = new Subject();

  constructor(
    private authStore: Store<AuthState>,
    private patientStore: Store<PatientState>,
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, _reject) => {
      this.setAuthState();
      if (this.authLoggedIn.getValue() || this.loggedInPatient.getValue()) {
        resolve(true);
      } else {
        this.http
          .post(`${this.envService.baseUrl}/patients/current/login`, '')
          .toPromise()
          .then((response) => {
            const payload = <Patient>response;
            this.authStore.dispatch(patientActions.updateLoginInfoSuccess());
            this.patientStore.dispatch(
              patientActions.loadLoggedInPatient({ payload })
            );

            resolve(true);
          })
          .catch((_error) => {
            // Please keep this commented code for reference.
            // this.patientStore.dispatch(patientActions.logOut());
            // this.router.navigate(['/landing']);
            resolve(false);
          });
      }
    });
  }

  setAuthState(): void {
    this.authStore
      .select(selectAuthState)
      .pipe(take(1))
      .subscribe((state) => {
        this.authLoggedIn.next(state.loggedIn);
      });
  }
}
