import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalLayoutComponent implements OnInit {
  @HostBinding('class') cssClass = 'd-flex flex-column vh-100';
  @ViewChild('ieNotificationModalRef', { static: true })
  ieNotificationModalRef!: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.msIeVersion();
  }

  openNotificationModal() {
    return this.modalService.open(this.ieNotificationModalRef, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  msIeVersion(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      this.openNotificationModal();
      return true;
    } // If another browser, return 0
    else {
      return false;
    }
  }
}
