import { ILinkURLText } from '@patient-ui/shared/models';

export const navLinksPublic: ILinkURLText[] = [
  {
    url: 'https://patient.labcorp.com/help-center/home',
    text: 'Help Center',
  },
  {
    url: 'https://patient.labcorp.com/registration',
    text: 'Create an Account',
  },
];
