import { CardType, PaymentType } from '@patient-ui/shared/models';
import { WalletInfo } from '@patient-ui/shared/models';
export class PaymentMethod {
  cardId = '';
  paymentType!: PaymentType;
  debitLast4!: number;
  accountType = '';
  routingNumber = '';
  institutionName = '';
  expirationDate = '';
  cardType: CardType = 'OTHER';
  accountId = '';
  token = '';
  firstName = '';
  lastName = '';
  addressLine1 = '';
  addressLine2 = '';
  city = '';
  state = '';
  zip = '';
  country = '';
  walletId = '';
  walletName = '';
}

export class WalletDelete {
  cardId = '';
  forceDelete = false;
}

export class WalletDeleteResponse {
  cardId = '';
  isSuccessful = true;
  reasonCode = 200;
  reasonDescription = 'Success';

  automatedPaymentIds: String[] = [];
  authorizationIds: String[] = [];
  paymentPlanIds: String[] = [];
  scheduledPayments: String[] = [];
}

export class WalletPayment {
  firstName = '';
  lastName = '';
  emailAddress = '';
  token = '';
  accountId = '';
  ccOrEc: 'ECHECK' | 'CREDIT_CARD' = 'CREDIT_CARD';
  maskedCardNumber = '';
  addressLine1? = '';
  addressLine2? = '';
  cityName? = '';
  stateCode? = '';
  postalCode? = '';
  countryCode? = '';
  expirationMonth = '';
  expirationYear = '';
  cardTypeCD?: 'MC' | 'DI' | 'AX' | 'VI' = 'MC';
  accountType? = '';
  routingNumber? = '';
}

export class WalletPaymentResponse {
  cardID = '';
  isSuccessful = true;
  reasonCode = 200;
  reasonDescription = 'Success';
}

export class WalletRetrieveResponse {
  listWalletPayments: WalletInfo[] = [];
  isSuccessful = true;
  reasonCode = 200;
  reasonDescription = 'Success';
}
