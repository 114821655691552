import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { RequestStatus } from '@patient-ui/shared/constants';
import { map } from 'rxjs/operators';
import * as microbiomeActions from './microbiome.actions';
import { microbiomeFeatureKey, MicrobiomeState } from './microbiome.reducer';
import { MicrobiomeService } from './microbiome.service';

@Injectable()
export class MicrobiomeEffects {
  @Effect() getMicrobiomeSurvey$ = this.s.fetch<
    microbiomeActions.GetMicrobiomeSurvey
  >(microbiomeActions.MicrobiomeActionTypes.GetMicrobiomeSurvey, {
    id: (action, _state) => `${action.payload}`,
    run: (action, _state) => {
      return this.microbiomeService
        .getMicrobiomeSurvey(action.payload)
        .pipe(
          map((res) => new microbiomeActions.GetMicrobiomeSurveySuccess(res))
        );
    },
    onError: (_action, _error) => {
      const requestStatus = RequestStatus.Failure;
      let errorCode = '';
      if (_error.status === 400) {
        errorCode = _error.error.errorMessages[0].code;
      }
      return new microbiomeActions.GetMicrobiomeSurveyFailure({
        status: requestStatus,
        error: errorCode,
      });
    },
  });

  @Effect() postMicrobiomeSurveyResponse$ = this.s.fetch<
    microbiomeActions.PostMicrobiomeSurveyResponse
  >(microbiomeActions.MicrobiomeActionTypes.PostMicrobiomeSurveyResponse, {
    id: (action, _state) => `${action.payload}`,
    run: (action, _state) => {
      return this.microbiomeService
        .postMicrobiomeSurveyResponse(action.payload)
        .pipe(
          map(
            () =>
              new microbiomeActions.PostMicrobiomeSurveyResponseSuccess(
                RequestStatus.Success
              )
          )
        );
    },
    onError: (_action, _error) => {
      const requestStatus = RequestStatus.Failure;
      let errorCode = '';
      if (_error.status === 400) {
        errorCode = _error.error.errorMessages[0].code;
      }
      return new microbiomeActions.PostMicrobiomeSurveyResponseFailure({
        status: requestStatus,
        error: errorCode,
      });
    },
  });

  constructor(
    private s: DataPersistence<{ [microbiomeFeatureKey]: MicrobiomeState }>,
    private microbiomeService: MicrobiomeService
  ) {}
}
