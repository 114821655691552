import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  deletePatientAccount(): Observable<any> {
    return this.http.delete(`${this.envService.baseUrl}/patients/current`);
  }

  validatePhone(phoneNumber: string): Observable<any> {
    return this.http.get(
      `${this.envService.baseUrl}/phoneNumber?phoneNumber=${phoneNumber}`
    );
  }
}
