import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import {
  IInsuranceProvider,
  InsuranceProvider,
  NOT_LISTED,
  NO_INSURANCE,
  Payment,
} from '@patient-ui/shared/models';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as invoiceActions from './invoice.actions';
import { InvoiceState } from './invoice.reducer';
import { InvoiceService } from './invoice.service';

@Injectable()
export class InvoiceEffects {
  findInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.findInvoice),
      exhaustMap((action) =>
        this.invoiceService.getInvoice(action.payload).pipe(
          map((response) => {
            return invoiceActions.findInvoiceSuccess({ payload: response });
          }),
          catchError((error) => {
            switch (error.status) {
              case 409:
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: true })
                );
                return of(
                  invoiceActions.findInvoiceFailure({
                    payload: RequestStatus.Failure,
                  })
                );
              case 404 || 400:
                const requestStatus =
                  error.status === 404 || error.status === 400
                    ? RequestStatus.SuccessButEmptyResponse
                    : RequestStatus.Failure;
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: false })
                );
                return of(
                  invoiceActions.findInvoiceFailure({ payload: requestStatus })
                );

              default:
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: false })
                );
                return of(
                  invoiceActions.findInvoiceFailure({
                    payload: RequestStatus.Failure,
                  })
                );
            }
          })
        )
      )
    )
  );

  generateReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.generateReceipt),
      exhaustMap((action) => {
        return this.invoiceService
          .generateReceipt(action.confirmationNumber)
          .pipe(
            map((response) => {
              return invoiceActions.generateReceiptSuccess({
                response: response,
              });
            }),
            catchError((error) => {
              console.error('error', error);
              switch (error.status) {
                case 400:
                  return of(
                    invoiceActions.generateReceiptFailure({
                      response: {
                        successful: false,
                        reasonCode: 400,
                        reasonDescription: `Invalid confirmation number.`,
                      },
                    })
                  );
                default:
                  return of(
                    invoiceActions.generateReceiptFailure({
                      response: {
                        successful: false,
                        reasonCode: 500,
                        reasonDescription: `Error sending email.`
                      },
                    })
                  );
              }
            })
          );
      })
    )
  );

  getInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getInvoice),
      exhaustMap((action) => {
        this.store.dispatch(invoiceActions.clearInvoiceSearchResult());
        return this.invoiceService.getInvoice(action.payload).pipe(
          map((response) => {
            return invoiceActions.getInvoiceSuccess({ payload: response });
          }),
          catchError((error) => {
            console.error('error', error);
            switch (error.status) {
              case 409:
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: true })
                );
                return of(
                  invoiceActions.getInvoiceFailure({
                    payload: RequestStatus.Failure,
                  })
                );
              case 404 || 400:
                const requestStatus =
                  error.status === 404 || error.status === 400
                    ? RequestStatus.SuccessButEmptyResponse
                    : RequestStatus.Failure;
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: false })
                );
                return of(
                  invoiceActions.getInvoiceFailure({ payload: requestStatus })
                );
              default:
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: false })
                );
                return of(
                  invoiceActions.getInvoiceFailure({
                    payload: RequestStatus.Failure,
                  })
                );
            }
          })
        );
      })
    )
  );

  getInvoicePdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getInvoicePdf),
      exhaustMap((action) => {
        return this.invoiceService.getInvoicePdf(action.payload).pipe(
          map((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            return invoiceActions.getInvoicePdfSuccess({ payload: blob });
          }),
          catchError(() => of(invoiceActions.getInvoicePdfFailure()))
        );
      })
    )
  );

  getInvoiceSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getInvoiceSummary),
      exhaustMap(() => {
        const allPatientSummary = {
          id: 0,
          displayName: `All Patients`,
        };
        return this.invoiceService.getInvoiceSummary(allPatientSummary).pipe(
          map((invoiceSummary) => {
            let allPayments: Payment[] = [];
            invoiceSummary.paymentHistory?.forEach((paymentHistoryItem) => {
              paymentHistoryItem.payments.forEach((payment) => {
                let paymentItem: Payment;
                paymentItem = new Payment();
                paymentItem.pid = paymentHistoryItem.pid ?? 0;
                paymentItem.paymentDisplayName =
                  paymentHistoryItem.patientFirstName +
                  ' ' +
                  paymentHistoryItem.patientLastName;
                paymentItem.lcaInvoiceNumber =
                  paymentHistoryItem.lcaInvoiceNumber ?? '';
                paymentItem.dateOfService = paymentHistoryItem.dateOfService;
                paymentItem.paymentStatus = payment.paymentStatus;
                paymentItem.transactionId = payment.transactionId;
                paymentItem.encryptedTransactionId =
                  payment.encryptedTransactionId;
                paymentItem.amountPaid = payment.amountPaid;
                paymentItem.paymentDate = payment.paymentDate;
                paymentItem.scheduledPaymentId = payment.scheduledPaymentId;
                paymentItem.scheduledPaymentInvoiceId =
                  payment.scheduledPaymentInvoiceId;
                paymentItem.documentKey = payment.documentKey;
                paymentItem.dateOfServiceValue = new Date(
                  paymentHistoryItem.dateOfService
                );
                const dateComponents = payment.paymentDate.split(`/`);
                if (dateComponents.length === 3) {
                  paymentItem.paymentDateValue = new Date(
                    parseInt(dateComponents[2]),
                    parseInt(dateComponents[0]) - 1,
                    parseInt(dateComponents[1])
                  );
                } else {
                  /*
                   * Something happened that has prevented us from parsing a data from the EPS
                   * response, I'm assuming this bad data is historical, and won't be on the most
                   * recent payments, which is what we want to show the user first anyways, so
                   * I'm setting the "date" on those to 00:00:00.000 on January 1, 1970 GMT to
                   * hide them at the end of the payment history.
                   */
                  console.error(
                    `Error creating a date value from ${paymentItem.paymentDate}`
                  );
                  paymentItem.paymentDateValue = new Date(0);
                }
                allPayments.push(paymentItem);
              });
            });
            allPayments = allPayments.sort((a, b) => {
              if (!b.paymentDateValue) {
                console.error(
                  `No 'b' payment date value in ${JSON.stringify(b)}`
                );
              }
              if (!a.paymentDateValue) {
                console.error(
                  `No 'a' payment date value in ${JSON.stringify(a)}`
                );
              }
              b.paymentDateValue = b.paymentDateValue || new Date(0);
              a.paymentDateValue = a.paymentDateValue || new Date(0);
              return (
                b.paymentDateValue.getTime() - a.paymentDateValue.getTime()
              );
            }); //sort desc by date
            this.store.dispatch(
              invoiceActions.populateInvoicePaymentsAll({
                payload: allPayments,
              })
            );
            this.store.dispatch(
              invoiceActions.populateInvoicePaymentsFiltered({
                payload: allPayments,
              })
            );
            return invoiceActions.getInvoiceSummarySuccess({
              payload: invoiceSummary,
            });
          }),
          catchError(() => of(invoiceActions.getInvoiceSummaryFailure()))
        );
      })
    )
  );

  updateInsurance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.updateInsurance),
      exhaustMap((action) => {
        return this.invoiceService
          .updateInsurance(
            action.payload.invoiceNumber,
            action.payload.zipCode,
            action.payload.data
          )
          .pipe(
            map(() => {
              return invoiceActions.updateInsuranceSuccess();
            }),
            catchError(() => of(invoiceActions.updateInsuranceFailure()))
          );
      })
    )
  );

  getInsuranceProviderList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getProviderList),
      exhaustMap(() => {
        return this.invoiceService.getInsuranceProviderList().pipe(
          map((providers) => {
            providers.map(
              (provider: IInsuranceProvider) => new InsuranceProvider(provider)
            );
            providers.sort((a, b) =>
              a.payerName.toLocaleLowerCase('en-US') >
              b.payerName.toLocaleLowerCase('en-US')
                ? 1
                : -1
            );
            const noInsuranceProvider = providers.find(
              (provider) =>
                provider.payerName
                  .toLocaleLowerCase('en-US')
                  .search(NO_INSURANCE) > -1
            );
            const notListedProvider = providers.find(
              (provider) =>
                provider.payerName
                  .toLocaleLowerCase('en-US')
                  .search(NOT_LISTED) > -1
            );
            if (noInsuranceProvider) {
              providers.unshift(noInsuranceProvider);
            }
            if (notListedProvider) {
              providers.unshift(notListedProvider);
            }
            const uniqueProividers = new Set(providers); // Removes the duplicates created by unshift
            const setToProviderArray = [...uniqueProividers]; // Go back to array
            return invoiceActions.getProviderListSuccess({
              payload: setToProviderArray,
            });
          }),
          catchError(() => of(invoiceActions.getProviderListFailure()))
        );
      })
    )
  );

  getPhoenixAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getInvoicePhoenixAddress),
      exhaustMap((action) => {
        return this.invoiceService.getPhoenixAddress(action.payload).pipe(
          map((response) => {
            return invoiceActions.getInvoicePhoenixAddressSuccess({
              payload: response,
            });
          }),
          catchError(() => of(invoiceActions.getInvoicePhoenixAddressFailure()))
        );
      })
    )
  );

  getProcessingInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getProcessingInvoices),
      exhaustMap(() => {
        return this.invoiceService.getProcessingInvoices().pipe(
          map((response) => {
            return invoiceActions.getProcessingInvoicesSuccess({
              payload: response,
            });
          }),
          catchError(() => of(invoiceActions.getProcessingInvoicesFailure()))
        );
      })
    )
  );

  getPaymentAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getPaymentAuthorization),
      exhaustMap((action) => {
        return this.invoiceService.authorizePayment(action.payload).pipe(
          map((response) => {
            if (response.reasonCode === '408') {
              this.store.dispatch(
                invoiceActions.getPaymentAuthorizationTimeout({ payload: true })
              );
            }
            return invoiceActions.getPaymentAuthorizationSuccess({
              payload: response,
            });
          }),
          catchError(() => of(invoiceActions.getPaymentAuthorizationFailure()))
        );
      })
    )
  );

  getPaymentAuthorizationReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getPaymentAuthorizationReceipt),
      exhaustMap((action) => {
        return this.invoiceService
          .getPdfDetails(action.payload.transactionId)
          .pipe(
            map(() => {
              return invoiceActions.getPaymentAuthorizationReceiptSuccess();
            }),
            catchError(() =>
              of(invoiceActions.getPaymentAuthorizationReceiptFailure())
            )
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private invoiceService: InvoiceService,
    private store: Store<InvoiceState>
  ) {}
}
