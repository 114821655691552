import { createReducer, on } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import {
  Address,
  InsuranceProvider,
  Invoice,
  InvoiceSummary,
  Payment,
  PaymentAuthorizationReceipt,
  PaymentAuthorizationRequest,
  PaymentAuthorizationResponse,
  PaymentInvoice,
  PaymentType,
  ProcessingInvoice,
  TokenDetails,
} from '@patient-ui/shared/models';
import * as invoiceActions from './invoice.actions';

export const invoiceFeatureKey = 'invoice';

export type InvoiceState = {
  invoice: Invoice;
  findInvoice: Invoice;
  findInvoiceRequestStatus: RequestStatus;
  selectedInvoices: Invoice[];
  invoiceSummary: InvoiceSummary;
  invoicePaymentsFilteredByPatient: Payment[];
  invoicePaymentsAllPatients: Payment[];
  generateReceiptRequestStatus: RequestStatus;
  serviceUnavailable: boolean;
  getInvoiceRequestStatus: RequestStatus;
  getInvoicePdfRequestStatus: RequestStatus;
  getInvoiceSummaryRequestStatus: RequestStatus;
  updateInsuranceRequestStatus: RequestStatus;
  getPhoenixAddressStatus: RequestStatus;
  phoenixReturnedAddress: Address;
  getProviderListRequestStatus: RequestStatus;
  providerList: InsuranceProvider[];
  processingInvoices: ProcessingInvoice[];
  getProcessingInvoicesStatus: RequestStatus;
  paymentAuthorizationRequest: PaymentAuthorizationRequest;
  paymentAuthorizationResponse: PaymentAuthorizationResponse;
  getPaymentAuthorizationStatus: RequestStatus;
  paymentAuthorizationTimeout: boolean;
  paymentAuthorizationReceipt: PaymentAuthorizationReceipt;
  getPaymentAuthorizationReceiptStatus: RequestStatus;
  payments: PaymentInvoice[];
  invoicePdf: Blob | undefined;
  tokenDetails: TokenDetails;
  selectDuplicateInvoice: boolean;
};

export const initialState: InvoiceState = {
  invoice: {
    number: '',
    zipCode: '',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    specimenNumber: '',
    dateOfService: '',
    invoiceDate: '',
    documentKey: '',
    patientName: '',
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
      middleName: '',
      prefix: '',
      orgName: '',
      practiceLocationAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    dunningCycleCode: '',
    orderingPhyAccountNumber: '',
  },
  findInvoice: {
    number: '',
    zipCode: '',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    specimenNumber: '',
    dateOfService: '',
    invoiceDate: '',
    documentKey: '',
    patientName: '',
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
      middleName: '',
      prefix: '',
      orgName: '',
      practiceLocationAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    dunningCycleCode: '',
    orderingPhyAccountNumber: '',
  },
  generateReceiptRequestStatus: RequestStatus.NotSent,
  findInvoiceRequestStatus: RequestStatus.NotSent,
  selectedInvoices: [],
  invoiceSummary: {
    collectionsInvoices: [],
    currentInvoices: [],
    pastDueInvoices: [],
    paymentHistory: [],
  },
  invoicePaymentsFilteredByPatient: [],
  invoicePaymentsAllPatients: [],
  serviceUnavailable: false,
  getInvoiceRequestStatus: RequestStatus.NotSent,
  updateInsuranceRequestStatus: RequestStatus.NotSent,
  getPhoenixAddressStatus: RequestStatus.NotSent,
  phoenixReturnedAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipCode: '',
    valid: false,
  },
  getProviderListRequestStatus: RequestStatus.NotSent,
  getInvoiceSummaryRequestStatus: RequestStatus.NotSent,
  providerList: [],
  processingInvoices: [],
  getProcessingInvoicesStatus: RequestStatus.NotSent,
  paymentAuthorizationRequest: {
    accountId: '',
    paymentAccountId: '',
    paymentMethod: PaymentType.CREDIT,
    paymentDate: '',
    firstName: '',
    lastName: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    invoiceList: [],
    emailAddress: '',
    isGuestUser: true,
    browser: '',
    mobile: false,
  },
  paymentAuthorizationResponse: {
    successful: false,
    transactionId: '',
    encryptedTransactionId: '',
    reasonCode: '',
  },
  getPaymentAuthorizationStatus: RequestStatus.NotSent,
  paymentAuthorizationTimeout: false,
  payments: [],
  paymentAuthorizationReceipt: {
    isSuccessful: false,
    transactionId: '',
  },
  getPaymentAuthorizationReceiptStatus: RequestStatus.NotSent,
  getInvoicePdfRequestStatus: RequestStatus.NotSent,
  invoicePdf: undefined,
  tokenDetails: {
    token: '',
    paymentType: 'CARD',
    debitLast4: '',
    expirationDate: '',
    cardType: '',
    cardBrand: '',
    accountType: '',
    routingNumber: '',
  },
  selectDuplicateInvoice: false,
};

export const invoiceReducer = createReducer(
  initialState,
  on(invoiceActions.addInvoice, (state, { payload }) => ({
    ...state,
    selectedInvoices: state.selectedInvoices
      .filter((value: Invoice) => value.number !== payload.number)
      .concat([payload]),
  })),
  on(invoiceActions.addPayment, (state, { payload }) => ({
    ...state,
    payments: state.payments
      .filter((value) => value.invoiceNumber !== payload.invoiceNumber)
      .concat([payload]),
  })),
  on(invoiceActions.addSelectedPaymentList, (state, { payload }) => ({
    ...state,
    selectedInvoices: payload,
  })),
  on(invoiceActions.clearAllSelectedPaymentsAndInvoices, (state) => ({
    ...state,
    selectedInvoices: initialState.selectedInvoices,
    payments: initialState.payments,
  })),
  on(invoiceActions.clearFindInvoiceStatus, (state) => ({
    ...state,
    findInvoiceRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.clearInvoicePdf, (state) => ({
    ...state,
    invoicePdf: undefined,
    getInvoicePdfRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.clearInvoiceSearchResult, (state) => ({
    ...state,
    invoice: { ...initialState.invoice },
    getInvoiceRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.clearPaymentProcessOnConfirmation, (state) => ({
    ...state,
    selectedInvoices: initialState.selectedInvoices,
    payments: initialState.payments,
    paymentAuthorizationRequest: initialState.paymentAuthorizationRequest,
    paymentAuthorizationResponse: initialState.paymentAuthorizationResponse,
    getPaymentAuthorizationStatus: initialState.getPaymentAuthorizationStatus,
    paymentAuthorizationReceipt: initialState.paymentAuthorizationReceipt,
    getPaymentAuthorizationReceiptStatus:
      initialState.getPaymentAuthorizationReceiptStatus,
  })),
  on(invoiceActions.clearTokenData, (state) => ({
    ...state,
    tokenDetails: initialState.tokenDetails,
  })),
  on(invoiceActions.deletePayment, (state, { payload }) => ({
    ...state,
    payments: state.payments.filter(
      (value) => value.invoiceNumber !== payload.invoiceNumber
    ),
  })),
  on(invoiceActions.duplicateInvoice, (state, { payload }) => ({
    ...state,
    selectDuplicateInvoice: payload,
  })),
  on(invoiceActions.findInvoice, (state) => ({
    ...state,
    findInvoiceRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.findInvoiceFailure, (state, { payload }) => ({
    ...state,
    findInvoiceRequestStatus: payload,
  })),
  on(invoiceActions.findInvoiceSuccess, (state, { payload }) => ({
    ...state,
    findInvoice: {
      ...payload,
    },
    findInvoiceRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.generateReceipt, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.generateReceiptFailure, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.generateReceiptReset, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.generateReceiptSuccess, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getInvoice, (state) => ({
    ...state,
    getInvoiceRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getInvoiceFailure, (state, { payload }) => ({
    ...state,
    getInvoiceRequestStatus: payload,
  })),
  on(invoiceActions.getInvoiceSuccess, (state, { payload }) => ({
    ...state,
    invoice: {
      ...payload,
    },
    getInvoiceRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getInvoicePdf, (state) => ({
    ...state,
    getInvoicePdfRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getInvoicePdfFailure, (state) => ({
    ...state,
    getInvoicePdfRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getInvoicePdfSuccess, (state, { payload }) => ({
    ...state,
    invoicePdf: payload,
    getInvoicePdfRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getInvoiceSummary, (state) => ({
    ...state,
    getInvoiceSummaryRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getInvoiceSummaryFailure, (state) => ({
    ...state,
    getInvoiceSummaryRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getInvoiceSummarySuccess, (state, { payload }) => ({
    ...state,
    invoiceSummary: {
      ...payload,
    },
    getInvoiceSummaryRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getInvoicePhoenixAddress, (state) => ({
    ...state,
    getPhoenixAddressStatus: RequestStatus.NotSent,
    phoenixReturnedAddress: { ...initialState.phoenixReturnedAddress },
  })),
  on(invoiceActions.getInvoicePhoenixAddressFailure, (state) => ({
    ...state,
    getPhoenixAddressStatus: RequestStatus.Failure,
    phoenixReturnedAddress: { ...initialState.phoenixReturnedAddress },
  })),
  on(invoiceActions.getInvoicePhoenixAddressSuccess, (state, { payload }) => ({
    ...state,
    getPhoenixAddressStatus: RequestStatus.Success,
    phoenixReturnedAddress: { ...payload },
  })),
  on(invoiceActions.getPaymentAuthorization, (state, { payload }) => ({
    ...state,
    paymentAuthorizationRequest: payload,
    getPaymentAuthorizationStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getPaymentAuthorizationFailure, (state) => ({
    ...state,
    getPaymentAuthorizationStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getPaymentAuthorizationReset, (state) => ({
    ...state,
    getPaymentAuthorizationStatus: RequestStatus.NotSent,
    paymentAuthorizationResponse: initialState.paymentAuthorizationResponse,
    paymentAuthorizationTimeout: initialState.paymentAuthorizationTimeout,
  })),
  on(invoiceActions.getPaymentAuthorizationSuccess, (state, { payload }) => ({
    ...state,
    paymentAuthorizationResponse: payload,
    getPaymentAuthorizationStatus: RequestStatus.Success,
    tokenDetails: initialState.tokenDetails,
  })),
  on(invoiceActions.getPaymentAuthorizationTimeout, (state, { payload }) => ({
    ...state,
    paymentAuthorizationTimeout: payload,
  })),
  on(invoiceActions.getPaymentAuthorizationReceipt, (state) => ({
    ...state,
    paymentAuthorizationReceipt: {
      ...initialState.paymentAuthorizationReceipt,
    },
  })),
  on(invoiceActions.getPaymentAuthorizationReceiptFailure, (state) => ({
    ...state,
    getPaymentAuthorizationReceiptStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getPaymentAuthorizationReceiptReset, (state) => ({
    ...state,
    paymentAuthorizationReceipt: {
      ...initialState.paymentAuthorizationReceipt,
    },
  })),
  on(invoiceActions.getPaymentAuthorizationReceiptSuccess, (state) => ({
    ...state,
    getPaymentAuthorizationReceiptStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getProcessingInvoices, (state) => ({
    ...state,
    getProcessingInvoicesStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getProcessingInvoicesFailure, (state) => ({
    ...state,
    getProcessingInvoicesStatus: RequestStatus.Failure,
    processingInvoices: { ...initialState.processingInvoices },
  })),
  on(invoiceActions.getProcessingInvoicesSuccess, (state, { payload }) => ({
    ...state,
    getProcessingInvoicesStatus: RequestStatus.Success,
    processingInvoices: payload,
  })),
  on(invoiceActions.getProviderList, (state) => ({
    ...state,
    getProviderListRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getProviderListFailure, (state) => ({
    ...state,
    getProviderListRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getProviderListReset, (state) => ({
    ...state,
    getProviderListRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.getProviderListSuccess, (state, { payload }) => ({
    ...state,
    providerList: {
      ...payload,
    },
    getProviderListRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.populateInvoicePaymentsAll, (state, { payload }) => ({
    ...state,
    invoicePaymentsAllPatients: payload,
  })),
  on(invoiceActions.populateInvoicePaymentsFiltered, (state, { payload }) => ({
    ...state,
    invoicePaymentsFilteredByPatient: payload,
  })),
  on(invoiceActions.removeInvoice, (state, { payload }) => ({
    ...state,
    selectedInvoices: state.selectedInvoices.filter(
      (value) => value.number !== payload.number
    ),
  })),
  on(invoiceActions.resetState, () => ({ ...initialState })),
  on(invoiceActions.savePaymentData, (state, { payload }) => ({
    ...state,
    paymentAuthorizationRequest: payload,
  })),
  on(invoiceActions.saveTokenData, (state, { payload }) => ({
    ...state,
    tokenDetails: payload,
  })),
  on(invoiceActions.updateInsurance, (state) => ({
    ...state,
    updateInsuranceRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.updateInsuranceFailure, (state) => ({
    ...state,
    updateInsuranceRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.updateInsuranceReset, (state) => ({
    ...state,
    updateInsuranceRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.updateInsuranceSuccess, (state) => ({
    ...state,
    updateInsuranceRequestStatus: RequestStatus.Success,
  }))
);
