import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumberPipe',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    let areaCode = value.substring(0, 3);
    let coCode = value.substring(3, 6);
    let line = value.substring(6);

    return `(${areaCode}) ${coCode}-${line}`;
  }
}
