import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from './auth.reducer';

export const authStateSelector = createFeatureSelector<AuthState>(
  authFeatureKey
);

export const selectAuthState = createSelector(
  authStateSelector,
  (state) => state
);
