import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import {
  Address,
  DownloadInvoiceRequest,
  IInsuranceDetail,
  IInvoiceSearch,
  InsuranceProvider,
  Invoice,
  InvoiceSummary,
  PaymentAuthorizationRequest,
  PaymentAuthorizationResponse,
  PaymentReceiptResponse,
  PortalUser,
  ProcessingInvoice,
} from '@patient-ui/shared/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  authorizePayment(
    authorizationRequest: PaymentAuthorizationRequest
  ): Observable<PaymentAuthorizationResponse> {
    let pymtUrl = '';
    if (authorizationRequest.isGuestUser) {
      pymtUrl = `${this.envService.baseUrl}/invoices/payment`;
    } else {
      pymtUrl = `${this.envService.baseUrl}/patients/current/invoices/payment/submit`;
    }

    return this.http.post<PaymentAuthorizationResponse>(
      pymtUrl,
      authorizationRequest
    );
  }

  generateReceipt(
    confirmationNumber: string
  ): Observable<PaymentReceiptResponse> {
    return this.http.get<PaymentReceiptResponse>(
      `${this.envService.baseUrl}/invoices/receiptEmail?confirmationNumber=${confirmationNumber}`
    );
  }

  getInvoice(invoiceSearch: IInvoiceSearch): Observable<Invoice> {
    let invoiceDOB = '';
    if (invoiceSearch.dateOfBirth) {
      invoiceDOB = `&patientDob=${invoiceSearch.dateOfBirth}`;
    }
    const invoiceSearchUrl =
      `${this.envService.baseUrl}/invoices?invoiceNumber=${invoiceSearch.invoiceNumber}&zipCode=${invoiceSearch.zipCode}` +
      invoiceDOB;
    return this.http.get<Invoice>(invoiceSearchUrl);
  }

  getInvoicePdf(invoiceRequest: DownloadInvoiceRequest) {
    let dependentsUrlSegment = '';
    /* Failsafe to make sure the document key is a top-level field */
    if (!invoiceRequest.documentKey) {
      invoiceRequest.documentKey = invoiceRequest.invoice?.documentKey
        ? invoiceRequest.invoice.documentKey
        : ``;
    }
    if (
      invoiceRequest.currentPatient &&
      invoiceRequest.currentPatient.isPrimary === 'dependent' &&
      invoiceRequest.currentPatient.id.toString() !== '0'
    ) {
      dependentsUrlSegment = this.getDependentsURlSegment(
        invoiceRequest.currentPatient.id.toString()
      );
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob' as 'text',
    };
    const invoicePdfUrl = `${this.envService.baseUrl}/patients/current${dependentsUrlSegment}/invoices/pdf?documentKey=${invoiceRequest.documentKey}`;
    return this.http.get<any>(invoicePdfUrl, httpOptions as object);
  }

  getInvoiceSummary(currentPatient: PortalUser): Observable<InvoiceSummary> {
    let dependentsUrlSegment = '';
    if (
      currentPatient &&
      currentPatient.isPrimary === 'dependent' &&
      currentPatient.id.toString() !== '0'
    ) {
      dependentsUrlSegment = this.getDependentsURlSegment(
        currentPatient.id.toString()
      );
    }
    let allUrlSegment = '';
    if (currentPatient && currentPatient.id.toString() === '0') {
      allUrlSegment = '?all=true';
    }
    const invoiceSummaryUrl = `${this.envService.baseUrl}/patients/current${dependentsUrlSegment}/invoices/summary${allUrlSegment}`;
    return this.http.get<InvoiceSummary>(invoiceSummaryUrl);
  }

  getProcessingInvoices(): Observable<ProcessingInvoice[]> {
    // TODO: CALL THE BACKEND WILL THAT'S ADDED
    // TODO For mock data:
    const resp: ProcessingInvoice[] = [];
    //   {
    //     "dateOfService": "01/09/2019",
    //     "number": "12345678",
    //     "patientName": "Jill Smith",
    //     "locationName": "LabCorp Location",
    //     "location": {
    //       "line1": "3060 S. Church St.",
    //       "city": "Burlington",
    //       "state": "NC",
    //       "zipCode": "27302"
    //     }
    //   },
    //   {
    //     "dateOfService": "08/23/2015",
    //     "number": "23456789",
    //     "patientName": "Jack Smith",
    //     "locationName": "LabCorp Location",
    //     "location": {
    //       "line1": "3060 S. Church St.",
    //       "line2": "Suite 004",
    //       "city": "Burlington",
    //       "state": "NC",
    //       "zipCode": "27302"
    //     }
    //   },
    //   {
    //     "dateOfService": "01/09/2019",
    //     "number": "01234567",
    //     "patientName": "James Smith",
    //     "locationName": "LabCorp Location",
    //     "location": {
    //       "line1": "3060 S. Church St.",
    //       "city": "Burlington",
    //       "state": "NC",
    //       "zipCode": "27302"
    //     }
    //   }
    // ];
    return of(resp);
  }

  updateInsurance(
    invoiceNumber: string,
    invoiceZipCode: string,
    insuranceData: IInsuranceDetail
  ): Observable<any> {
    return this.http.post<any>(
      `${this.envService.baseUrl}/invoices/${invoiceNumber}/${invoiceZipCode}/insurance`,
      insuranceData
    );
  }

  getInsuranceProviderList(): Observable<InsuranceProvider[]> {
    return this.http.get<InsuranceProvider[]>(
      `${this.envService.baseUrl}/insurance/payerList`
    );
    // TODO For mock data
    // const provider: InsuranceProvider[] = [{
    //   payerCode: '1234',
    //   payerType: 'Other',
    //   payerId: 1,
    //   payerName: 'Not Listed',
    //   alwaysShowDuringSearch: false
    // }];
    // return of(provider);
  }

  getPhoenixAddress(address: Address): Observable<Address> {
    const enteredAddress = `${this.envService.baseUrl}/address?city=${address.city}&state=${address.state}&street=${address.line1}&zip=${address.zipCode}`;
    return this.http.get<Address>(enteredAddress);
  }

  getDependentsURlSegment(dependentId?: string): string {
    return dependentId ? `/dependents/${dependentId}` : '';
  }
  getPdfDetails(id: String): Observable<Blob> {
    const pdfServiceUrl = `${this.envService.baseUrl}/invoices/paymentPdf?transactionId=${id}`;
    return this.http.get(pdfServiceUrl, {
      headers: this.getHeaders(),
      responseType: 'blob',
    });
  }
  private getHeaders() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/pdf');
    return headers;
  }
}
