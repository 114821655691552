<ng-container>
  <label [for]="domId" [class.text-white]="labelColor === 'white'">{{
    label
  }}</label>
  <!-- select.blur() is a workaround for this bug: https://github.com/ng-select/ng-select/issues/1451 -->
  <!-- It also dismisses the keyboard on Android once a selection is made -->
  <ng-select
    [inputAttrs]="{ name: domId }"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [formControl]="control"
    [clearable]="clearable"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [searchFn]="customSearchFn"
    (change)="onChange($event)"
    #select
    (close)="select.blur()"
  >
    <ng-template
      *ngIf="useTemplate"
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="searchTerm"
    >
      <div style="white-space: normal;">{{ item[bindLabel] }}</div>
    </ng-template>
  </ng-select>
</ng-container>
