import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../shared/services/layout.service';

@Component({
  selector: 'patient-ui-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicFooterComponent implements OnInit, OnDestroy {
  active: boolean[] = [false, false, false];
  appYear: number = new Date().getFullYear();

  patientLitholinkUrl = '';
  helpCenterUrl = '';
  preCheckUrl = '';
  buttonSubscription!: Subscription;
  showAppButtons = true;
  footerSubscription!: Subscription;
  showFooterContent = true;

  constructor(
    private envService: EnvironmentService,
    private layoutService: LayoutService
  ) {}

  toggleSection(i: number) {
    this.active[i] = !this.active[i];
  }

  ngOnInit(): void {
    this.helpCenterUrl = `${this.envService.patientUrl}/help-center/home`;
    this.patientLitholinkUrl = `${this.envService.patientUrl}/litholink`;
    this.preCheckUrl = this.envService.preCheckUrl;

    this.buttonSubscription = this.layoutService
      .getShowNavbarButton()
      .subscribe((buttonsState) => {
        this.showAppButtons = buttonsState;
      });

    this.footerSubscription = this.layoutService
      .getShowFooterContent()
      .subscribe((footerState) => {
        this.showFooterContent = footerState;
      });
  }

  ngOnDestroy(): void {
    this.buttonSubscription.unsubscribe();
    this.footerSubscription.unsubscribe();
  }
}
