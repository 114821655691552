import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFastRegistration from './fast-registration.reducer';

export const fastRegistrationStateSelector = createFeatureSelector<
  fromFastRegistration.FastRegistrationState
>(fromFastRegistration.fastRegistrationFeatureKey);

export const selectVerificationResponse = createSelector(
  fastRegistrationStateSelector,
  (state) => state.fastRegistrationPatient
);
