import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  PatientState,
  selectMobileNavigation,
} from '@patient-ui/patient-web/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'patient-ui-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalFooterComponent implements OnInit, OnDestroy {
  browserTarget = '_blank';
  destroyed = new Subject();
  constructor(private patientStore: Store<PatientState>) {}

  ngOnInit(): void {
    this.patientStore
      .select(selectMobileNavigation)
      .pipe(takeUntil(this.destroyed))
      .subscribe((isMobile) => {
        if (isMobile) {
          this.browserTarget = '_system';
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
