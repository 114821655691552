import { createAction, props } from '@ngrx/store';
import {
  IRequestError,
  ISendCodeRequest,
  PasswordReset,
} from '@patient-ui/shared/models';

export const getPasswordResetPayload = createAction(
  '[Account] Get Password Reset Payload',
  props<{ email: string }>()
);

export const getPasswordResetPayloadSuccess = createAction(
  '[Account] Get Password Reset Payload Success',
  props<{ passwordResetPayload: PasswordReset }>()
);

export const getPasswordResetPayloadFailure = createAction(
  '[Account] Get Password Reset Payload Failure'
);

export const clearPasswordResetPayload = createAction(
  '[Account] Clear Password Reset'
);

export const setPasswordResetToken = createAction(
  '[Account] Set Password Reset Token',
  props<{ token: string }>()
);

export const setPasswordResetLast4Mobile = createAction(
  '[Account] Set Password Reset Last4 Mobile',
  props<{ last4Mobile: string }>()
);

export const setPasswordResetEmail = createAction(
  '[Account] Set Password Reset Email',
  props<{ email: string }>()
);

export const setPasswordResetMethod = createAction(
  '[Account] Set Password Reset Method',
  props<{ method: string }>()
);

export const sendPasswordResetVerifyCode = createAction(
  '[Account] Send Password Reset Verify Code',
  props<{ email: string; sendCodeRequest: ISendCodeRequest }>()
);

export const sendPasswordResetVerifyCodeSuccess = createAction(
  '[Account] Send Password Reset Verify Code Success',
  props<{ token: string }>()
);

export const sendPasswordResetVerifyCodeFailure = createAction(
  '[Account] Send Password Reset Verify Code Failue'
);

export const clearSendPasswordResetVerifyCodeRequestStatus = createAction(
  '[Account] Clear Send Password Reset Verify Code Request Status'
);

export const validatePasswordResetVerifyCode = createAction(
  '[Account] Validate Password Reset Verify Code',
  props<{ pin: number; token: string; email: string }>()
);

export const validatePasswordResetVerifyCodeSuccess = createAction(
  '[Account] Validate Password Reset Verify Code Success',
  props<{ token: string }>()
);

export const validatePasswordResetVerifyCodeFailure = createAction(
  '[Account] Validate Password Reset Verify Code Failue',
  props<{ error: IRequestError }>()
);

export const clearValidatePasswordResetVerifyCodeRequestStatus = createAction(
  '[Account] Clear Validate Password Reset Verify Code Status'
);

export const clearPasswordResetVerifyCodeRequest = createAction(
  '[Account] Clear Password Reset Verify Code Request'
);

export const setNewPassword = createAction(
  '[Account] Set New Password',
  props<{ newPassword: string; token: string; email: string }>()
);

export const setNewPasswordSuccess = createAction(
  '[Account] Set New Password Success'
);

export const setNewPasswordFailure = createAction(
  '[Account] Set New Password Failure'
);

export const clearAllResetPasswordState = createAction(
  '[Account] Clear All Reset Password State'
);
