<div *ngIf="group" [formGroup]="group" class="form-group">
  <label>{{ label }}</label>
  <div *ngFor="let item of items" class="custom-control custom-checkbox">
    <input
      type="checkbox"
      [id]="item.domId"
      [formControlName]="item.formControlName"
      class="custom-control-input"
    />
    <label [for]="item.domId" class="custom-control-label font-weight-normal">{{
      item.label
    }}</label>
  </div>
</div>
