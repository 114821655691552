import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {EnvironmentService} from "@patient-ui/shared-ui/utils";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  validatePhone(phoneNumber: string): Observable<any> {

    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const validatePhoneUrl = `${this.envService.baseUrl}/phoneNumber?phoneNumber=${formattedPhoneNumber}`;

    return this.http.get<any>(
      validatePhoneUrl
    );
  }
  
  getPhoneType(type: string): string {
    let phoneType: string;

    switch (type) {
      case 'undetermined':
      case 'voip': {
        phoneType = 'unknown';
        break;
      }
      case 'landline': {
        phoneType = 'home';
        break;
      }
      case 'freephone':
      case 'other': {
        phoneType = 'work';
        break;
      }
      default: {
        phoneType = type;
      }
    }
    return phoneType;
  }

  getPhoneDisplayText(value: string): string {
    let displayText: string;

    switch (value.toLowerCase()) {
      case 'mobile': {
        displayText = 'Mobile';
        break;
      }
      case 'home': {
        displayText = 'LandLine';
        break;
      }
      case 'unknown': {
        displayText = 'Undetermined';
        break;
      }
      case 'work': {
        displayText = 'Other';
        break;
      }
      default: {
        displayText = 'Other';
      }
    }
    return displayText;
  }
}
