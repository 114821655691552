import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { numericRange } from './numericRange';

export const months = [
  {
    value: 0,
    display: 'January',
  },
  {
    value: 1,
    display: 'February',
  },
  {
    value: 2,
    display: 'March',
  },
  {
    value: 3,
    display: 'April',
  },
  {
    value: 4,
    display: 'May',
  },
  {
    value: 5,
    display: 'June',
  },
  {
    value: 6,
    display: 'July',
  },
  {
    value: 7,
    display: 'August',
  },
  {
    value: 8,
    display: 'September',
  },
  {
    value: 9,
    display: 'October',
  },
  {
    value: 10,
    display: 'November',
  },
  {
    value: 11,
    display: 'December',
  },
];

export function yearRange(
  span: number,
  allowMinors: boolean = false,
  startingYear: number = new Date().getFullYear()
): number[] {
  // returns an array of years in reverse order starting at the "startingYear"
  // and spanning the number of years provided. If minors are NOT allowed, offset
  // the years by 18. Otherweise return all years for the span.

  const offset = allowMinors ? 0 : 18;

  const years = [];
  startingYear = startingYear - offset;

  for (let i = startingYear; i > startingYear - span; i--) {
    years.push(i);
  }

  return years;
}

export function monthsInYear(year: number): Object[] {
  // Returns the number of months available in the year.
  // if it's the current year, the months up to and including
  // the current month are returned.
  // Prevents future months from being selected.

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  if (year < currentYear) {
    return months;
  } else if (year === currentYear) {
    return months.filter((month) => {
      return month.value <= currentMonth;
    });
  } else {
    return months;
  }
}

export function daysInMonth(month: number, year: number): number[] {
  // returns the number of days for the given month
  // month must be 1-based, as using 0 for the day returns the last
  // day of the month. If the current month and year match the arguments
  // return the days up to and including the current day.

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const day =
    year === currentYear && month === currentMonth ? today.getDate() : 0;
  const lastDay = new Date(year, month + 1, day).getDate();

  return numericRange(1, lastDay);
}

export function dateValidator(max: Date, min: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const maxDate = moment(max);
    const minDate = moment(min);
    let date = moment(control.value, 'YYYYMMDD');

    if (control.pristine || control.value === '') {
      return null;
    }

    const numbers = /^[0-9]+$/;
    let isOnlyNumbers = false; // if special chars have been stripped
    if (control.value.match(numbers)) {
      isOnlyNumbers = true;
      date = moment(control.value, 'MM/DD/YYYY');
    }

    if (isOnlyNumbers && control.value.length < 8) {
      return {
        invalidDate: true,
      };
    } else if (!isOnlyNumbers && control.value.length > 10) {
      return {
        invalidYear: true,
      };
    }

    // Invalidate bad dates like 2/29/2012.
    // Extract the month/day/year and confirm that it matches the parsed date.
    // First assume this is a valid yyyy-mm-dd formatted date, as
    // input[type=date] returns. Otherwise, use placeholder format of
    // 'mm/dd/yyyy'
    let year, month, day;
    let match = control.value.match(/^(\d{4})-(\d{2})-(\d{2})$/);

    if (match) {
      [year, month, day] = [match[1], match[2], match[3]];
      date = moment(control.value, 'YYYY-MM-DD');
    } else if (isOnlyNumbers) {
      [month, day, year] = [
        control.value.substring(0, 2),
        control.value.substring(2, 4),
        control.value.substring(4),
      ];
    } else {
      // assume mm/dd/yyyy
      match = control.value.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
      date = moment(control.value, 'MM/DD/YYYY');
      if (!match) {
        return {
          invalidDate: true,
        };
      }

      [month, day, year] = [match[1], match[2], match[3]];
    }

    if (
      date.toString() === 'Invalid Date' ||
      date < minDate ||
      date > maxDate
    ) {
      return {
        invalidDate: true,
      };
    }

    const isValid =
      date.year() === Number.parseInt(year, 10) &&
      date.month() + 1 === Number.parseInt(month, 10) &&
      date.date() === Number.parseInt(day, 10);

    if (!isValid) {
      return {
        invalidDate: true,
      };
    }

    return null;
  };
}
