import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  smartyStreetsFeatureKey,
  SmartyStreetsState,
} from './smartystreets.reducer';

export const smartyStreetsStateSelector = createFeatureSelector<
  SmartyStreetsState
>(smartyStreetsFeatureKey);

export const selectSmartyStreetsInitializeSuccess = createSelector(
  smartyStreetsStateSelector,
  (state) => state.initializeSuccess
);

export const selectSmartyStreetsSuggestResults = createSelector(
  smartyStreetsStateSelector,
  (state) => state.suggestResults
);
