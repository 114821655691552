import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesReviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
