import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import {
  GradedQuizResponse,
  LexisNexisCompletedQuiz,
  LexisNexisQuiz,
} from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountVerificationLexisService {

  constructor(private config: EnvironmentService, private http: HttpClient) {}

  getQuestions(
    patientToken?: string,
    activePatientIsDependent?: boolean,
    patientId?: number
  ): Observable<LexisNexisQuiz> {
    let url = `/authenticationQuiz/v2/registration/${patientToken}`;
    if (patientId) {
      url = activePatientIsDependent
        ? `/patients/current/dependents/${patientId}/authenticationQuiz/v2`
        : '/authenticationQuiz/v2';
    }
    return this.http.post<LexisNexisQuiz>(`${this.config.baseUrl}${url}`, '');
  }

  gradeQuiz(
    quiz: LexisNexisCompletedQuiz,
    activePatientIsDependent?: boolean,
    patientId?: number
  ): Observable<GradedQuizResponse> {
    let url = '/authenticationQuiz/v2/registration/grading';
    if (patientId) {
      url = activePatientIsDependent
        ? `/patients/current/dependents/${patientId}/authenticationQuiz/v2/grading`
        : '/authenticationQuiz/v2/grading';
    }
    return this.http.post<GradedQuizResponse>(`${this.config.baseUrl}${url}`, quiz);
  }
}
