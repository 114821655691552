import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import {
  IPortalAddress,
  ISmartyStreetsQuery,
  ISmartyStreetsSuggestResponse,
} from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SmartyStreetsService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  searchAddress(
    payload: ISmartyStreetsQuery
  ): Observable<ISmartyStreetsSuggestResponse> {
    let addressQuery = `prefix=${payload.prefix}`;
    addressQuery += payload.city ? `&city_filter=${payload.city}` : '';
    addressQuery += payload.state ? `&state_filter=${payload.state}` : '';
    return this.http.get<ISmartyStreetsSuggestResponse>(
      `${this.envService.smartyStreetsBaseUrl}/suggest?key=${this.envService.smartyStreetsSiteKey}&${addressQuery}`
    );
  }

  addZipCode(payload: IPortalAddress[]): Observable<IPortalAddress[]> {
    return this.http.post<IPortalAddress[]>(
      `${this.envService.baseUrl}/address/validate`,
      payload
    );
  }
}
