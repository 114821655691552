import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ILinkURLText } from '@patient-ui/shared/models';

@Component({
  selector: 'ui-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  @Input() withStripe!: boolean;
  @Input() showButton!: boolean;
  @Input() navLinks: ILinkURLText[] = [];

  public isMenuCollapsed = true;

  constructor() {}

  ngOnInit(): void {}
}
