import { InvoiceHistory } from './invoicehistory.model';

export class Invoice {
  number!: string;
  zipCode?: string;
  balanceDue!: number;
  invoiceAmount!: number;
  billingAddress = {} as {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    zipCode: string;
  };
  pid!: string | null;
  specimenNumber!: string;
  dateOfService!: string;
  invoiceDate!: string;
  documentKey?: string | null;
  state?: string;
  patientName?: string;
  patientFirstName?: string;
  patientLastName?: string;
  physicianDetail:
    | {
        firstName: string;
        lastName: string;
        middleName?: string;
        prefix?: string;
        orgName?: string;
        practiceLocationAddress?:
          | {
              line1: string;
              line2?: string;
              city: string;
              state: string;
              zipCode: string;
            }
          | undefined;
      }
    | undefined
    | null;
  totalCharges!: number | null;
  adjustments!: number | null;
  medicarePaid!: number | null;
  medicaidPaid!: number | null;
  insurancePaid!: number | null;
  patientPaid!: number | null;
  npi?: string | null;
  accessionNumber?: string;
  invoiceHistory?: InvoiceHistory[];
  isPastDue?: boolean;
  encryptedTransactionIds?: string[];
  transactionIds?: string[];
  dunningCycleCode?: string;
  patientDateOfBirth?: string;
  patientDOB?: string;
  orderingPhyAccountNumber!: string;
}
