const insuranceCoverageTypeValues = {
  Medicare: 'Medicare' as 'Medicare',
  Medicaid: 'Medicaid' as 'Medicaid',
  Other: 'Other' as 'Other',
  None: 'None' as 'Other',
};

type insuranceCoverageTypeValues = typeof insuranceCoverageTypeValues[keyof typeof insuranceCoverageTypeValues];

export { insuranceCoverageTypeValues };
