import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import * as pendoActions from './pendo.actions';
import { pendoFeatureKey, PendoState } from './pendo.reducer';
import { PendoService } from './pendo.service';

@Injectable()
export class PendoEffects {
  @Effect() pendoSetGuestUser$ = this.s.fetch<pendoActions.PendoSetGuestUser>(
    pendoActions.PendoActionTypes.PendoSetGuestUser,
    {
      id: (action, _state) =>
        `${action.payload[0].invoiceNumber}${action.payload[0].zipCode}`,
      run: (action, _state) => {
        this.store.dispatch(new pendoActions.PendoInitializeSuccess(true));
        return this.pendoService.setGuestUser(action.payload);
      },
    }
  );

  @Effect() pendoSetUnknowntUser$ = this.s.fetch<
    pendoActions.PendoSetUnknownUser
  >(pendoActions.PendoActionTypes.PendoSetUnknownUser, {
    run: (_state) => {
      this.store.dispatch(new pendoActions.PendoInitializeSuccess(true));
      return this.pendoService.setUnknownUser();
    },
  });

  @Effect() pendoSetRegisteredUser$ = this.s.fetch<
    pendoActions.PendoSetRegisteredUser
  >(pendoActions.PendoActionTypes.PendoSetRegisteredUser, {
    id: (action, _state) => `${action.payload.id}`,
    run: (action, _state) => {
      return this.pendoService.setRegisteredUser(action.payload);
    },
  });

  constructor(
    private s: DataPersistence<{ [pendoFeatureKey]: PendoState }>,
    private pendoService: PendoService,
    private store: Store<PendoState>
  ) {}
}
