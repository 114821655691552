import { Component, Input, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  FormGroupName,
} from '@angular/forms';
import { getControl } from '../form-control-helper.utils';

export interface ChangedFieldEntry {
  /** The label for the control whose value is being displayed */
  label: string;
  /** The control itself. Can be a FormControl, FormGroup, etc. Required unless `controlName` is specified instead */
  control?: AbstractControl;
  /** When hosted within a FormGroup, the name of the control. Required unless `control` is specified instead */
  controlName?: string;
  /** An optional formatter function to apply to the control value */
  formatter?: (value: any) => string;
  /** An optional function to determine when the value is considered "changed" */
  display?: (control: AbstractControl) => boolean;
}

/** The default formatter, which simply passes through the value */
export function unitFormatter(value: any): string {
  return value;
}

/** The default display logic, which checks the field for dirty, valid, non-null and non-empty */
function changedAndNonEmpty(control: AbstractControl): boolean {
  return (
    control.dirty &&
    control.valid &&
    control.value !== '' &&
    control.value !== null
  );
}

interface NormalizedFieldEntry {
  label: string;
  control: AbstractControl;
  formatter: (value: any) => string;
  display: (control: AbstractControl) => boolean;
}

@Component({
  selector: 'ui-changed-fields',
  templateUrl: './changed-fields.component.html',
  styleUrls: ['./changed-fields.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: [FormGroupDirective, FormGroupName],
    },
  ],
})
export class ChangedFieldsComponent implements OnInit {
  @Input() items: ChangedFieldEntry[] = [];
  _items: NormalizedFieldEntry[] = [];

  constructor(
    @Optional() private parentForm: FormGroupDirective,
    @Optional() private groupName: FormGroupName
  ) {}

  ngOnInit(): void {
    this._items = this.items.map((item) => {
      return {
        label: item.label,
        control:
          item.control ??
          getControl(
            'ui-changed-fields',
            this.parentForm,
            this.groupName,
            item.controlName || ''
          ),
        formatter: item.formatter ?? unitFormatter,
        display: item.display ?? changedAndNonEmpty,
      };
    });
  }
}
