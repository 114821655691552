export interface IVerificationMethodType {
  value: string;
  display: string;
}

export const SSN_VERIFICATION = 'ssn';
export const DL_VERIFICATION = 'dl';
export const CST_VERIFICATION = 'cst';
import * as moment from 'moment';

export const verificationMethods: IVerificationMethodType[] = [
  {
    value: SSN_VERIFICATION,
    display: 'Last 4 digits of SSN',
  },
  {
    value: DL_VERIFICATION,
    display: 'Drivers License',
  },
];

export const supportedStates = [
  'CT',
  'DE',
  'FL',
  'ID',
  'IL',
  'LA',
  'KY',
  'MA',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'NE',
  'NH',
  'NV',
  'NC',
  'ND',
  'OH',
  'SC',
  'TN',
  'TX',
  'WI',
  'WV',
  'WY'
];

export function getVerificationMethods(usState: string, dob: Date): IVerificationMethodType[] {
  if (checkStateSupported(usState, dob)) {
    return verificationMethods;
  }

  return verificationMethods.filter(verificationMethod => {
    return verificationMethod.value !== DL_VERIFICATION;
  });
}

export function checkStateSupported(usState: string, dob: Date): boolean {
  if (!usState || !dob) {
    return false;
  }


  let age = moment.duration(moment().diff(dob)).asYears();

  if (usState === 'NV') {
    return age >= 16 && age <= 24;
  }

  return supportedStates.indexOf(usState) !== -1;
}
