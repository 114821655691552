import { Action } from '@ngrx/store';
import { IInvoiceSearch, IPatient } from '@patient-ui/shared/models';

export enum PendoActionTypes {
  PendoInit = '[Pendo] Pendo Init',
  PendoInitializeSuccess = '[Pendo] Pendo Initialize Success',
  PendoSetUnknownUser = '[Pendo] Pendo Set Unknown User',
  PendoSetGuestUser = '[Pendo] Pendo Set Guest User',
  PendoSetRegisteredUser = '[Pendo] Pendo Set Registered User',
  PendoResetState = '[Pendo] Reset State',
}
export class PendoInit implements Action {
  readonly type = PendoActionTypes.PendoInit;
}

export class PendoSetUnknownUser implements Action {
  readonly type = PendoActionTypes.PendoSetUnknownUser;
}

export class PendoSetGuestUser implements Action {
  readonly type = PendoActionTypes.PendoSetGuestUser;
  constructor(public readonly payload: IInvoiceSearch[]) {}
}

export class PendoSetRegisteredUser implements Action {
  readonly type = PendoActionTypes.PendoSetRegisteredUser;
  constructor(public readonly payload: IPatient) {}
}

export class PendoInitializeSuccess implements Action {
  readonly type = PendoActionTypes.PendoInitializeSuccess;
  constructor(public readonly payload: boolean) {}
}
export class PendoResetState implements Action {
  readonly type = PendoActionTypes.PendoResetState;
}

export type PendoActions =
  | PendoInit
  | PendoSetUnknownUser
  | PendoSetGuestUser
  | PendoInitializeSuccess
  | PendoResetState
  | PendoSetRegisteredUser;
