import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as FastRegistrationActions from './fast-registration.actions';
import { FastRegistrationService } from './fast-registration.service';

@Injectable()
export class FastRegistrationEffects {
  constructor(
    private actions$: Actions,
    private fastRegistrationService: FastRegistrationService
  ) {}

  VerifyFastRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FastRegistrationActions.VerifyFastRegistration),
      exhaustMap((action) =>
        this.fastRegistrationService
          .verifyFastRegistrationInputs(action.payload)
          .pipe(
            map((response) => {
              return FastRegistrationActions.VerifyFastRegistrationSuccess({
                response,
              });
            }),
            catchError((_error) => {
              let errorCode = '';
              if (_error.status === 400) {
                if (_error.error && _error.error.code) {
                  errorCode = _error.error.code;
                } else {
                  //Generic data mismatch or Bad request error
                  errorCode = 'PP-FR-004';
                }
              } else {
                errorCode = '500';
              }
              const payload = {
                error: errorCode,
              };
              return of(
                FastRegistrationActions.VerifyFastRegistrationFailure({
                  payload,
                })
              );
            })
          )
      )
    )
  );

  SubmitFastRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FastRegistrationActions.SubmitFastRegistration),
      exhaustMap((action) =>
        this.fastRegistrationService
          .submitFastRegistrationInputs(action.payload)
          .pipe(
            map((response) => {
              return FastRegistrationActions.SubmitFastRegistrationSuccess({
                response,
              });
            }),
            catchError((_error) => {
              let errorCode = '';
              if (_error.status === 400) {
                errorCode = '400';
              } else {
                errorCode = '500';
              }
              const payload = {
                error: errorCode,
              };
              return of(
                FastRegistrationActions.SubmitFastRegistrationFailure({
                  payload,
                })
              );
            })
          )
      )
    )
  );
}
