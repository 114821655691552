import { IInsuranceProvider } from '@patient-ui/shared/models';

export const NO_INSURANCE = 'i do not have';
export const NOT_LISTED = 'provider is not listed';
export class InsuranceProvider {
  payerCode = '';
  payerType = '';
  payerId = 0;
  payerName = '';
  alwaysShowDuringSearch = false;

  constructor(iProvider: IInsuranceProvider) {
    if (iProvider) {
      const payerName = iProvider.payerName.toLocaleLowerCase('en-US');
      const notListed = NOT_LISTED;
      const none = NO_INSURANCE;
      const alwaysShow =
        payerName.search(none) > -1 || payerName.search(notListed) > -1;

      Object.assign(iProvider, {
        alwaysShowDuringSearch: alwaysShow,
      });
    }
  }
}
