<ng-template #modalRef let-modal>
  <div class="modal-header">
    <h2 *ngIf="header === 'simple'" class="modal-title flex-fill text-center">
      {{ headerText }}
    </h2>
    <ng-content
      *ngIf="header === 'custom'"
      select=".custom-modal-header"
    ></ng-content>
    <button
      *ngIf="closeIcon"
      type="button"
      class="btn btn-link close-icon"
      aria-label="Close"
      (click)="modal.dismiss(false)"
    >
      <i class="material-icons">close</i>
    </button>
  </div>

  <div class="modal-body">
    <ng-content select=".custom-modal-body"></ng-content>
  </div>

  <div
    *ngIf="footer === 'simple' && footerLinkText !== ''"
    class="modal-footer justify-content-between"
  >
    <button
      type="button"
      class="btn btn-outline-primary ml-n1"
      (click)="modal.close(footerLinkFlag)"
    >
      {{ footerLinkText }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close(footerButtonFlag)"
    >
      {{ footerButtonText }}
    </button>
  </div>
  <div
    *ngIf="footer === 'simple' && footerLinkText === ''"
    class="modal-footer justify-content-end"
  >
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close(footerButtonFlag)"
    >
      {{ footerButtonText }}
    </button>
  </div>
</ng-template>

<!-- the following button is used in storybook to launch the modal -->
<button *ngIf="story" type="button" class="btn btn-primary" (click)="open()">
  Launch Demo Modal
</button>
