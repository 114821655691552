import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  authenticate(email: string, password: string): Observable<any> {
    const postUrl = this.envService.loginOktaUrl;
    const postData = { username: email, password: password };
    return this.http.post(postUrl, postData);
  }

  getLoginInfo(): Observable<any> {
    return this.http.post(
      `${this.envService.baseUrl}/patients/current/login`,
      ''
    );
  }

  logOut(): Observable<any> {
    return this.http.get(this.envService.logoutUrl);
  }

  logOutOkta(): Observable<any> {
    return this.http.delete(this.envService.logoutOktaUrl);
  }
}
