import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { PdfDocType } from '@patient-ui/shared/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PdfViewerService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getPdfDetails(id: String, docType: String): Observable<Blob> {
    let pdfServiceUrl = '';

    if (PdfDocType.Invoice === docType) {
      pdfServiceUrl = `${this.envService.baseUrl}/invoices/paymentPdf?transactionId=${id}`;
    } else if (PdfDocType.Result === docType) {
      pdfServiceUrl = `${this.envService.baseUrl}/${docType}?resultId=${id}`;
    }

    return this.http
      .get(pdfServiceUrl, { headers: this.getHeaders(), responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getInvoicePdfDetails(
    id: String,
    docType: String,
    dob: String,
    portalUser: boolean
  ): Observable<Blob> {
    let pdfServiceUrl = '';
    const uuid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    if (PdfDocType.Invoice === docType) {
      const portalUserSnippet = portalUser ? `/patients/current` : ``;
      pdfServiceUrl = `${this.envService.baseUrl}${portalUserSnippet}/invoices/paymentPdf`;
    }
    const request = {
      transactionId: id,
      dateOfBirth: dob,
      uuid: dob.length === 0 ? uuid : '',
    };

    return this.http
      .post(pdfServiceUrl, request, {
        headers: this.getHeaders(),
        responseType: 'blob',
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  private getHeaders() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/pdf');
    return headers;
  }

  getCombinedInvoicePdfDetails(
    transIdList: String[],
    docType: String,
    portalUser: boolean
  ): Observable<Blob> {
    let pdfServiceUrl = '';
    const uuid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    if (PdfDocType.Invoice === docType) {
      const portalUserSnippet = portalUser ? `/patients/current` : ``;
      pdfServiceUrl = `${this.envService.baseUrl}${portalUserSnippet}/invoices/paymentCombinedPdf`;
    }
    const request = {
      transIdList: transIdList,
      uuid: uuid ? uuid : '',
    };
    return this.http
      .post(pdfServiceUrl, request, {
        headers: this.getHeaders(),
        responseType: 'blob',
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
