import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { ISendCodeRequest } from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getPasswordResetPayload(email: string): Observable<any> {
    return this.http.post(
      `${this.envService.baseUrl}/accounts/${email}/password/initiateReset`,
      ''
    );
  }

  sendPasswordResetVerifyCode(
    email: string,
    sendCodeRequest: ISendCodeRequest
  ): Observable<any> {
    return this.http.post(
      `${this.envService.baseUrl}/accounts/${email}/password/sendCode`,
      sendCodeRequest
    );
  }

  validatePasswordResetVerifyCode(
    pin: number,
    token: string,
    email: string
  ): Observable<any> {
    const httpOptions = {
      token: token,
      code: pin.toString(),
    };
    return this.http.post(
      `${this.envService.baseUrl}/accounts/${email}/password/validateCode`,
      httpOptions
    );
  }

  setNewPassword(
    newPassword: string,
    token: string,
    email: string
  ): Observable<any> {
    const httpOptions = {
      newPassword,
      token,
      email,
    };
    return this.http.post(`${this.envService.baseUrl}/accounts/${email}/password`, httpOptions);
  }
}
