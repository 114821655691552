import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import {
  routerReducer,
  RouterState,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PatientWebFeatureShellModule } from '@patient-ui/patient-web/feature-shell';
import {
  BOOTSTRAP_ENVIRONMENT_TOKEN,
  EnvironmentService,
  SelectivePreloadingStrategyService,
} from '@patient-ui/shared-ui/utils';
import { bootstrapEnvironment } from '../environments/bootstrap/environment';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app.component';
import { DeviceDetectorService } from 'ngx-device-detector';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(options),
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
      useHash: false,
      preloadingStrategy: SelectivePreloadingStrategyService,
      relativeLinkResolution: 'legacy',
      enableTracing: bootstrapEnvironment.isDebugEnabled,
    }),
    PatientWebFeatureShellModule,
    StoreModule.forRoot(
      { router: routerReducer },
      {
        metaReducers: bootstrapEnvironment.isDebugEnabled ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionSerializability: false,
          strictStateSerializability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    bootstrapEnvironment.isDebugEnabled
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          name: 'Patient Web NgRx Store',
        })
      : [],
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (es: EnvironmentService) => () => es.loadEnvironment(),
      deps: [EnvironmentService],
      multi: true,
    },
    {
      provide: BOOTSTRAP_ENVIRONMENT_TOKEN,
      useValue: bootstrapEnvironment,
    },
    EnvironmentService,
    DeviceDetectorService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
