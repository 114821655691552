import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Patient } from '@patient-ui/shared/models';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as patientActions from '../patient/patient.actions';
import * as authActions from './auth.actions';
import { AuthService } from './auth.service';

@Injectable()
export class AuthEffects {
  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.authenticate),
      exhaustMap((action) =>
        this.authService.authenticate(action.email, action.password).pipe(
          map((response) => authActions.authenticateSuccess({ response })),
          catchError((_error) => of(authActions.authenticateFailure()))
        )
      )
    )
  );

  getLoginInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getLoginInfo),
      exhaustMap(() =>
        this.authService.getLoginInfo().pipe(
          map((payload: Patient) =>
            patientActions.loadLoggedInPatient({ payload })
          ),
          catchError((_error) =>
            of(patientActions.loadLoggedInPatientFailure())
          )
        )
      )
    )
  );

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOut),
      exhaustMap(() =>
        this.authService.logOut().pipe(
          map((_success) => authActions.logOutSuccess()),
          catchError((_error) => of(authActions.logOutSuccess()))
        )
      )
    )
  );

  logOutOkta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOutSuccess),
      exhaustMap(() =>
        this.authService.logOutOkta().pipe(
          map((_success) => authActions.logOutOkta()),
          catchError((_error) => of(authActions.logOutOktaSuccess()))
        )
      )
    )
  );

  ClearPatientOnLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logOutOktaSuccess),
        exhaustMap(() =>
          this.authService
            .logOutOkta()
            .pipe(map((_success) => patientActions.clearLoggedInPatient))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
