import { Action, createAction, props } from '@ngrx/store';

export const authenticate = createAction(
  '[Auth] authenticate',
  props<{ email: string; password: string }>()
);

export const authenticateSuccess = createAction(
  '[Auth] authenticate Success',
  props<{ response: any }>()
);

export const updateLoginInfoSuccess = createAction(
  '[Auth] Update LoginInfo Success'
);

export const authenticateFailure = createAction('[Auth] authenticate Failure');

export const getLoginInfo = createAction('[Auth] Get Login Info');

export const logOut = createAction('[Auth] Logout');

export const logOutSuccess = createAction('[Auth] Logout Success');

export const logOutOkta = createAction('[Auth] Logout Okta');

export const logOutOktaSuccess = createAction('[Auth] Logout Okta Success');

export enum AuthActionTypes {
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success'
}
export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;
}
