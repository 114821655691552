<nav class="navbar navbar-expand-lg navbar-light bg-white shadow" id='public-header'>
  <div class="container-xl justify-content-start">
    <button *ngIf='headerLinks'
      class="navbar-toggler"
      type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed"
      aria-controls="navbarPublic"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="material-icons icon-lg">menu</i>
    </button>
    <a
      class="navbar-brand navbar-logo"
      id="lnkBrandingHome"
      routerLink="/landing"
      (click)="isMenuCollapsed = true"
      ><img alt="Labcorp | Patient" src="assets/images/logo-patient-color.svg"
    /></a>
    <div
      *ngIf='headerLinks'
      [ngbCollapse]="isMenuCollapsed"
      class="collapse navbar-collapse"
      id="navbarPublic"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            [href]="preCheckUrl"
            class="nav-link" data-label="Make an Appointment"
            (click)="isMenuCollapsed = true"
            target="_blank"
          ><span class="nav-link-label">Make an Appointment</span></a
          >
        </li>
        <li class="nav-item">
          <a
            routerLink="invoices/find"
            class="nav-link" data-label="Billing and Insurance"
            (click)="isMenuCollapsed = true"
          ><span class="nav-link-label">Billing and Insurance</span></a
          >
        </li>
        <li class="nav-item">
          <a
            [href]="helpCenterUrl"
            class="nav-link" data-label="Help Center"
            (click)="isMenuCollapsed = true"
            target="_blank"
          ><span class="nav-link-label">Help Center</span></a
          >
        </li>
      </ul>
      <form class="form-inline">
         <a *ngIf="showButton" class="ml-2 btn btn-primary" (click)="isMenuCollapsed = true" routerLink='/landing'>Sign In</a>
      </form>
    </div>
  </div>
</nav>
<div class="p-1 bg-primary"></div>
