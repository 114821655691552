import { Component, Input, OnInit, Optional } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  FormGroupName,
} from '@angular/forms';
import { getControl } from '../form-control-helper.utils';

@Component({
  selector: 'ui-form-field-msg',
  templateUrl: './form-field-msg.component.html',
  styleUrls: ['./form-field-msg.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: [FormGroupDirective, FormGroupName],
    },
  ],
})
export class FormFieldMsgComponent implements OnInit {
  @Input() validationMessages!: AnyObject;
  @Input() control!: FormControl;
  @Input() controlName = '';

  constructor(
    @Optional() private parentForm: FormGroupDirective,
    @Optional() private groupName: FormGroupName
  ) {}

  ngOnInit(): void {
    if (!this.control) {
      this.control = getControl(
        'ui-form-field-msg',
        this.parentForm,
        this.groupName,
        this.controlName
      ) as FormControl;
    }
  }

  get errorMessage(): string {
    let error = '';

    if (this.control.invalid && this.control.errors) {
      const errorKeys = Object.keys(this.control.errors);
      for (const errorKey of errorKeys) {
        error += this.validationMessages[errorKey];
        if (errorKeys.length > 1) {
          error += ' ';
        }
      }
    }

    return error;
  }
}

export interface AnyObject {
  [key: string]: any;
}
