import { createAction, props } from '@ngrx/store';
import { OrderEntity } from '@patient-ui/shared/models';

export const loadSelfOrders = createAction('[Orders] Load Self Orders');

export const loadSelfOrdersSuccess = createAction(
  '[Orders] Load Self Orders Success',
  props<{ selfOrders: OrderEntity[] }>()
);

export const loadSelfOrdersFailure = createAction(
  '[Orders] Load Self Orders Failure'
);

export const getOrderPdf = createAction(
  '[Orders] Get Orders Pdf',
  props<{ orderId: string }>()
);

export const getOrderPdfSuccess = createAction(
  '[Orders] Get Order Pdf Success',
  props<{ payload: any }>()
);

export const getOrderPdfFailure = createAction(
  '[Orders] Load Order Pdf Failure'
);

export const clearOrderPdf = createAction('[Orders] Clear Order Pdf');

export const SelfOrdersActions = {
  loadSelfOrders,
  loadSelfOrdersSuccess,
  loadSelfOrdersFailure,
  getOrderPdf,
  getOrderPdfSuccess,
  getOrderPdfFailure,
  clearOrderPdf,
};
