import { createFeatureSelector, createSelector } from '@ngrx/store';
import { invoiceFeatureKey, InvoiceState } from './invoice.reducer';

export const invoiceStateSelector = createFeatureSelector<InvoiceState>(
  invoiceFeatureKey
);

export const selectCurrentInvoice = createSelector(
  invoiceStateSelector,
  (state) => state.invoice
);

export const selectInvoiceSummary = createSelector(
  invoiceStateSelector,
  (state) => state.invoiceSummary
);

export const selectInvoiceSummaryRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getInvoiceSummaryRequestStatus
);

export const selecAllInvoicePaymentsFiltered = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePaymentsFilteredByPatient
);

export const selectAllInvoicePaymentsForAllPatients = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePaymentsAllPatients
);

export const selectInvoicePaymentsForFilteredPatient = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePaymentsFilteredByPatient
);

export const selectGenerateReceiptRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.generateReceiptRequestStatus
);

export const selectLoadedProviderList = createSelector(
  invoiceStateSelector,
  (state) => state.providerList
);

export const selectAllPhoenixValues = createSelector(
  invoiceStateSelector,
  (state) => state.phoenixReturnedAddress
);

export const selectDuplicateInvoiceFlag = createSelector(
  invoiceStateSelector,
  (state) => state.selectDuplicateInvoice
);

export const selectSelectedInvoices = createSelector(
  invoiceStateSelector,
  (state) => state.selectedInvoices
);

export const selectPaymentAuthorizationRequest = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationRequest
);

export const selectPaymentAccountId = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationRequest.paymentAccountId
);

export const selectPaymentAuthorizationResponse = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationResponse
);

export const selectGetPaymentAuthorizationStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getPaymentAuthorizationStatus
);

export const selectFindInvoice = createSelector(
  invoiceStateSelector,
  (state) => state.findInvoice
);

export const selectFindInvoiceRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.findInvoiceRequestStatus
);

export const selectGetPaymentAuthorizationTimeout = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationTimeout
);

export const selectPayments = createSelector(
  invoiceStateSelector,
  (state) => state.payments
);

export const selectPaymentAuthorizationReceiptStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getPaymentAuthorizationReceiptStatus
);

export const selectInvoicePdf = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePdf
);

export const selectInvoicePdfRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getInvoicePdfRequestStatus
);

export const selectTokenDetails = createSelector(
  invoiceStateSelector,
  (state) => state.tokenDetails
);

export const invoiceQuery = {
  invoiceStateSelector,
  selectLoadedProviderList,
  selectSelectedInvoices,
  selecAllInvoicePaymentsFiltered,
  selectAllInvoicePaymentsForAllPatients,
  selectInvoicePaymentsForFilteredPatient,
  selectInvoiceSummary,
  selectInvoiceSummaryRequestStatus,
  selectCurrentInvoice,
  selectAllPhoenixValues,
  selectPayments,
  selectInvoicePdf,
  selectGetPaymentAuthorizationStatus,
  selectGetPaymentAuthorizationTimeout,
  selectPaymentAuthorizationRequest,
  selectPaymentAuthorizationReceiptStatus,
  selectInvoicePdfRequestStatus,
  selectTokenDetails,
};
