export const genders = [
  {
    display: 'Male',
    value: 'MALE',
  },
  {
    display: 'Female',
    value: 'FEMALE',
  },
  {
    display: "I'd rather not say",
    value: 'UNKNOWN',
  },
];
