// tslint:disable-next-line:nx-enforce-module-boundaries
export interface IPhoneType {
  display: string;
  value: string;
  databaseValue?: string;
}

export const phoneTypes: IPhoneType[] = [
  {
    display: 'Mobile',
    value: 'mobile',
    databaseValue: 'MOBILE',
  },
  {
    display: 'Landline',
    value: 'home',
    databaseValue: 'HOME',
  },
  {
    display: 'VOIP',
    value: 'unknown',
    databaseValue: 'UNKNOWN',
  },
  {
    display: 'Other',
    value: 'work',
    databaseValue: 'WORK',
  },
];

export enum PhoneTypeValues {
  MOBILE = 'mobile',
  HOME = 'home',
  UNKNOWN = 'unknown',
  OTHER = 'work',
}

export interface IUiSelectPhoneType {
  text: string;
  value: string;
}

export const uiSelectPhoneTypes: IUiSelectPhoneType[] = [
  {
    text: 'Mobile',
    value: 'Mobile',
  },
  {
    text: 'Landline',
    value: 'LandLine',
  },
  {
    text: 'VOIP',
    value: 'Undetermined',
  },
  {
    text: 'Other',
    value: 'Other',
  },
];

export const phoneTypeMap = new Map<string, string>([
  ['Mobile', 'MOBILE'],
  ['LandLine', 'HOME'],
  ['Undetermined', 'UNKNOWN'],
  ['Other', 'WORK'],
]);
