import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private showNavbarStripe: BehaviorSubject<boolean>;
  private showNavbarButton: BehaviorSubject<boolean>;
  private showFooterAppButtons: BehaviorSubject<boolean>;
  private showFooterContent: BehaviorSubject<boolean>;
  private showHeaderLinks: BehaviorSubject<boolean>;

  constructor() {
    this.showNavbarStripe = new BehaviorSubject<boolean>(true);
    this.showNavbarButton = new BehaviorSubject<boolean>(true);
    this.showFooterAppButtons = new BehaviorSubject<boolean>(true);
    this.showFooterContent = new BehaviorSubject<boolean>(true);
    this.showHeaderLinks = new BehaviorSubject<boolean>(true);
  }

  getShowNavbarStripe(): Observable<boolean> {
    return this.showNavbarStripe.asObservable();
  }

  setShowNavbarStripe(showStripe: boolean): void {
    this.showNavbarStripe.next(showStripe);
  }

  getShowNavbarButton(): Observable<boolean> {
    return this.showNavbarButton.asObservable();
  }

  setShowNavbarButton(showButton: boolean): void {
    this.showNavbarButton.next(showButton);
  }

  getShowFooterAppButtons(): Observable<boolean> {
    return this.showFooterAppButtons.asObservable();
  }

  setShowFooterAppButtons(showButtons: boolean): void {
    this.showFooterAppButtons.next(showButtons);
  }

  getShowFooterContent(): Observable<boolean> {
    return this.showFooterContent.asObservable();
  }

  setShowFooterContent(showFooterContent: boolean): void {
    this.showFooterContent.next(showFooterContent);
  }

  getShowHeaderLinks(): Observable<boolean> {
    return this.showHeaderLinks.asObservable();
  }

  setShowHeaderLinks(showHeaderLinks: boolean): void {
    this.showHeaderLinks.next(showHeaderLinks);
  }

  resetDefaults() {
    this.setShowNavbarStripe(true);
    this.setShowNavbarButton(true);
    this.setShowFooterContent(true);
    this.setShowHeaderLinks(true);
  }
}
