import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { IPortalAddress } from '@patient-ui/shared/models';
import { map } from 'rxjs/operators';
import * as smartyStreetsActions from './smartystreets.actions';
import {
  smartyStreetsFeatureKey,
  SmartyStreetsState,
} from './smartystreets.reducer';
import { SmartyStreetsService } from './smartystreets.service';

@Injectable()
export class SmartyStreetsEffects {
  @Effect() smartyStreetsSearch$ = this.s.fetch<
    smartyStreetsActions.SmartyStreetsSearch
  >(smartyStreetsActions.SmartyStreetsActionTypes.SmartyStreetsSearch, {
    id: (action, _state) => `${action.payload}`,
    run: (action, _state) => {
      return this.smartyStreetsService.searchAddress(action.payload).pipe(
        map((smartyStreetsResult) => {
          if (smartyStreetsResult && smartyStreetsResult.suggestions) {
            return new smartyStreetsActions.SmartyStreetsAddZipCode(
              smartyStreetsResult
            );
          } else {
            return new smartyStreetsActions.SmartyStreetsSuggestResults([]);
          }
        })
      );
    },
    onError: (_action, _error) => {
      return new smartyStreetsActions.SmartyStreetsSuggestResults([]);
    },
  });

  @Effect() smartyStreetsAddZipCode$ = this.s.fetch<
    smartyStreetsActions.SmartyStreetsAddZipCode
  >(smartyStreetsActions.SmartyStreetsActionTypes.SmartyStreetsAddZipCode, {
    id: (action, _state) => `${action.payload}`,
    run: (action, _state) => {
      const portalAddressList: IPortalAddress[] = [];
      action.payload.suggestions.forEach((suggestion) => {
        portalAddressList.push({
          street: suggestion.street_line,
          city: suggestion.city,
          state: suggestion.state,
        });
      });
      return this.smartyStreetsService
        .addZipCode(portalAddressList)
        .pipe(
          map(
            (res) => new smartyStreetsActions.SmartyStreetsSuggestResults(res)
          )
        );
    },
    onError: (_action, _error) => {
      return new smartyStreetsActions.SmartyStreetsSuggestResults([]);
    },
  });

  constructor(
    private s: DataPersistence<{
      [smartyStreetsFeatureKey]: SmartyStreetsState;
    }>,
    private smartyStreetsService: SmartyStreetsService
  ) {}
}
