import { createAction, props } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import {
  Address,
  DownloadInvoiceRequest,
  IInsuranceUpdate,
  IInvoiceSearch,
  InsuranceProvider,
  Invoice,
  InvoiceSummary,
  Payment,
  PaymentAuthorizationRequest,
  PaymentAuthorizationResponse,
  PaymentInvoice,
  PaymentReceiptResponse,
  PortalUser,
  ProcessingInvoice,
  TokenDetails,
} from '@patient-ui/shared/models';

export const addInvoice = createAction(
  `[Invoice] Add Invoice`,
  props<{ payload: Invoice }>()
);

export const addPayment = createAction(
  `[Invoice] Add Payment`,
  props<{ payload: PaymentInvoice }>()
);

export const addSelectedPaymentList = createAction(
  `[Invoice] Add Selected Payment List`,
  props<{ payload: Invoice[] }>()
);

export const clearAllSelectedPaymentsAndInvoices = createAction(
  `[Invoice] Clear All Selected Payments and Invoices`
);

export const clearInvoicePdf = createAction(`[Invoice] Clear Invoice PDF`);

export const clearFindInvoiceStatus = createAction(
  `[Invoice] Clear Find Invoice Status`
);

export const clearInvoiceSearchResult = createAction(
  `[Invoice] Clear Invoice Search Result`
);

export const clearPaymentDeclined = createAction(
  `[Invoice] ClearPaymentDeclined`
);

export const clearPaymentProcessOnConfirmation = createAction(
  `[Invoice] Clear Payment Process State`
);

export const clearTokenData = createAction(`[Invoice] Clear Token Data`);

export const deletePayment = createAction(
  `[Invoice] Delete Payment`,
  props<{ payload: PaymentInvoice }>()
);

export const duplicateInvoice = createAction(
  `[Invoice] Duplicate Invoice`,
  props<{ payload: boolean }>()
);

export const findInvoice = createAction(
  `[Invoice] Find Invoice`,
  props<{ payload: IInvoiceSearch }>()
);

export const findInvoiceFailure = createAction(
  `[Invoice] Find Invoice Failure`,
  props<{ payload: RequestStatus }>()
);

export const findInvoiceSuccess = createAction(
  `[Invoice] Find Invoice Success`,
  props<{ payload: Invoice }>()
);

export const generateReceipt = createAction(
  `[Invoice] Generate Receipt`,
  props<{ confirmationNumber: string }>()
);

export const generateReceiptFailure = createAction(
  `[Invoice] Generate Receipt Failure`,
  props<{ response: PaymentReceiptResponse }>()
);

export const generateReceiptReset = createAction(
  `[Invoice] Generate Receipt Reset`
);

export const generateReceiptSuccess = createAction(
  `[Invoice] Generate Receipt Success`,
  props<{ response: PaymentReceiptResponse }>()
);

export const getInvoice = createAction(
  `[Invoice] Get Invoice`,
  props<{ payload: IInvoiceSearch }>()
);

export const getInvoiceFailure = createAction(
  `[Invoice] Get Invoice Failure`,
  props<{ payload: RequestStatus }>()
);

export const getInvoiceSuccess = createAction(
  `[Invoice] Get Invoice Success`,
  props<{ payload: Invoice }>()
);

export const getInvoicePdf = createAction(
  `[Invoice] Get Invoice PDF`,
  props<{ payload: DownloadInvoiceRequest }>()
);

export const getInvoicePdfFailure = createAction(
  `[Invoice] Get Invoice PDF Failure`
);

export const getInvoicePdfSuccess = createAction(
  `[Invoice] Get Invoice PDF Success`,
  props<{ payload: any }>()
);

export const getInvoiceSummary = createAction(
  `[Invoice] Get Invoice Summary`,
  props<{ payload: PortalUser }>()
);

export const getInvoiceSummaryFailure = createAction(
  `[Invoice] Get Invoice Summary Failure`
);

export const getInvoiceSummarySuccess = createAction(
  `[Invoice] Get Invoice Summary Success`,
  props<{ payload: InvoiceSummary }>()
);

export const getInvoicePhoenixAddress = createAction(
  `[Invoice] Get Phoenix Address`,
  props<{ payload: Address }>()
);

export const getInvoicePhoenixAddressFailure = createAction(
  `[Invoice] Get Phoenix Address Failure`
);

export const getInvoicePhoenixAddressSuccess = createAction(
  `[Invoice] Get Phoenix Address Success`,
  props<{ payload: Address }>()
);

export const getPaymentAuthorization = createAction(
  `[Invoice] Get Payment Authorization`,
  props<{ payload: PaymentAuthorizationRequest }>()
);

export const getPaymentAuthorizationFailure = createAction(
  `[Invoice] Get Payment Authorization Failure`
);

export const getPaymentAuthorizationReset = createAction(
  `[Invoice] Get Payment Authorization Reset`
);

export const getPaymentAuthorizationSuccess = createAction(
  `[Invoice] Get Payment Authorization Success`,
  props<{ payload: PaymentAuthorizationResponse }>()
);

export const getPaymentAuthorizationTimeout = createAction(
  `[Invoice] Get Payment Authorization Timeout`,
  props<{ payload: boolean }>()
);

export const getPaymentAuthorizationReceipt = createAction(
  `[Invoice] Get Payment Authorization Receipt`,
  props<{ payload: PaymentAuthorizationResponse }>()
);

export const getPaymentAuthorizationReceiptFailure = createAction(
  `[Invoice] Get Payment Authorization Receipt Failure`
);

export const getPaymentAuthorizationReceiptReset = createAction(
  `[Invoice] Get Payment Authorization Receipt Reset`
);

export const getPaymentAuthorizationReceiptSuccess = createAction(
  `[Invoice] Get Payment Authorization Receipt Success`
);

export const getProcessingInvoices = createAction(
  `[Invoice] Get Invoices Being Processed`
);

export const getProcessingInvoicesFailure = createAction(
  `[Invoice] Get Invoices Being Processed Failure`
);

export const getProcessingInvoicesSuccess = createAction(
  `[Invoice] Get Invoices Being Processed Success`,
  props<{ payload: ProcessingInvoice[] }>()
);

export const getProviderList = createAction(`[Invoice] Get Provider List`);

export const getProviderListFailure = createAction(
  `[Invoice] Get Provider List Failure`
);

export const getProviderListReset = createAction(
  `[Invoice] Get Provider List Reset`
);

export const getProviderListSuccess = createAction(
  `[Invoice] Get Provider List Success`,
  props<{ payload: InsuranceProvider[] }>()
);

export const populateInvoicePaymentsAll = createAction(
  `[Invoice] Populate Invoice Payments for All Patients`,
  props<{ payload: Payment[] }>()
);

export const populateInvoicePaymentsFiltered = createAction(
  `[Invoice] Populate Invoice Payments Filtered by Patients`,
  props<{ payload: Payment[] }>()
);

export const removeInvoice = createAction(
  `[Invoice] Remove Invoice`,
  props<{ payload: Invoice }>()
);

export const resetState = createAction(`[Invoice] Reset State`);

export const savePaymentData = createAction(
  `[Invoice] Save Payment Data`,
  props<{ payload: PaymentAuthorizationRequest }>()
);

export const saveTokenData = createAction(
  `[Invoice] Save Token Data`,
  props<{ payload: TokenDetails }>()
);

export const updateInsurance = createAction(
  `[Invoice] Update Insurance`,
  props<{ payload: IInsuranceUpdate }>()
);

export const updateInsuranceFailure = createAction(
  `[Invoice] Update Insurance Failure`
);

export const updateInsuranceReset = createAction(
  `[Invoice] Update Insurance Reset State`
);

export const updateInsuranceSuccess = createAction(
  `[Invoice] Update Insurance Success`
);
