import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as authActions from '@patient-ui/patient-web/store';
import {
  AuthState,
  PatientState,
  ProfileState,
  selectPatientState
} from '@patient-ui/patient-web/store';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'patient-ui-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalHeaderComponent implements OnInit, OnDestroy {
  patientUrl = '';
  helpCenterUrl = '';
  patientResultsUrl = '';
  patientDependentsUrl = '';
  patientProfileUrl = '';
  patientSignOutUrl = '';
  destroyed = new Subject();
  pahName = '';
  pahAbbreviation = '';
  showAlert$ = new BehaviorSubject<boolean>(false);
  isTechDifficulties$ = new BehaviorSubject<boolean>(false);
  showSuccess$ = new BehaviorSubject<boolean>(true);
  isMenuCollapsed = true;
  minimizedNavBar$ = new BehaviorSubject<boolean>(false);
  subs: Array<Subscription> = [];
  isMobile = false;
  constructor(
    private envService: EnvironmentService,
    private patientStore: Store<PatientState>,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AuthState>,
    private profileStore: Store<ProfileState>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.patientUrl = this.envService.patientUrl;
    this.subs[0] = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.minimizedNavBar$.next(route.data.navbarType === 'minimized');
      });
    this.helpCenterUrl = `${this.patientUrl}/help-center/home?nav=r`;
    this.patientResultsUrl = `${this.patientUrl}/portal/results`;
    this.patientDependentsUrl = `${this.patientUrl}/portal/dependents`;
    this.patientProfileUrl = `${this.patientUrl}/portal/profile`;
    const isMobileNavigation = localStorage.getItem('isMobileNavigation');
    if (isMobileNavigation) {
      localStorage.removeItem('isMobileNavigation');
      this.patientStore.dispatch(
        new authActions.fromPatientActions.PopulateMobile(true)
      );
    }

    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (patientState) => {
          this.isMobile = patientState.isMobile;
          const patient = patientState.primaryAccountHolder;
          if (patient) {
            this.pahName = patient.firstName + ' ' + patient.lastName;
            this.pahAbbreviation =
              patient.firstName.charAt(0) + patient.lastName.charAt(0);
          }
        },
        () => {
          this.showAlert$.next(true);
          this.isTechDifficulties$.next(true);
          this.showSuccess$.next(false);
        }
      );

    const self = this;
    window.addEventListener('click', (evt: any) => {
      if (!document.querySelector('[id^="portal-header"]')?.contains(evt.target)) {
        self.collapseMenu();
      }
    }, true);
  }

  signOut($event: Event): void {
    $event.preventDefault();
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.store.dispatch(authActions.logOut());
    window.history.replaceState(null, '', '/');
    this.router.navigate(['/landing']);
  }

  gotoProfile($event: Event, tabId: number): void {
    this.isMenuCollapsed = true;
    $event.preventDefault();
    this.profileStore.dispatch(
      authActions.changeProfileSelection({ activeId: tabId })
    );
    this.router.navigate(['/portal/account/profile']);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subs.forEach((s) => s.unsubscribe());
  }

  collapseMenu(): void {
    this.isMenuCollapsed = true;
    this.changeDetectorRef.detectChanges();
  }
}
