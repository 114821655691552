import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-registered-footer',
  templateUrl: './registered-footer.component.html',
  styleUrls: ['./registered-footer.component.scss'],
})
export class RegisteredFooterComponent implements OnInit {
  @Input() appName: string = 'AppName';
  @Input() browserTarget: string = '_blank';
  appYear: number = new Date().getFullYear();
  isMobile = false;
  constructor() {}

  ngOnInit(): void {
    if (this.browserTarget === '_system') {
      this.isMobile = true;
    }
  }

  goToTop() {
    window.scrollTo(0, 0);
  }
}
