import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import { IResponseDescription } from '@patient-ui/shared/models';
import { accountFeatureKey, AccountState } from './account.reducer';

export const accountStateSelector = createFeatureSelector<AccountState>(
  accountFeatureKey
);

export const selectPasswordResetPayload = createSelector(
  accountStateSelector,
  (state: AccountState) => state.passwordReset
);

export const selectPasswordResetPayloadRequestStatus = createSelector(
  accountStateSelector,
  (state: AccountState) => state.getPasswordResetPayloadRequestStatus
);

export const selectSendPasswordResetVerifyCodeRequestStatus = createSelector(
  accountStateSelector,
  (state: AccountState) => state.sendPasswordResetVerifyCodeRequestStatus
);

export const selectValidatePasswordResetVerifyCodeResponse = createSelector(
  accountStateSelector,
  (state: AccountState) => {
    if (
      state.validatePasswordResetVerifyCodeRequestStatus ===
      RequestStatus.Failure
    ) {
      const responseDescription: IResponseDescription = {
        status: RequestStatus.Failure,
        error: state.validatePasswordResetVerifyCodeRequestError,
      };
      return responseDescription;
    } else {
      const responseDescription: IResponseDescription = {
        status: state.validatePasswordResetVerifyCodeRequestStatus,
        error: null,
      };
      return responseDescription;
    }
  }
);

export const selectSetNewPasswordRequestStatus = createSelector(
  accountStateSelector,
  (state: AccountState) => state.setNewPasswordRequestStatus
);

export const accountQuery = {
  accountStateSelector,
  selectPasswordResetPayload,
  selectPasswordResetPayloadRequestStatus,
  selectSendPasswordResetVerifyCodeRequestStatus,
  selectValidatePasswordResetVerifyCodeResponse,
  selectSetNewPasswordRequestStatus,
};
