import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  FormGroupName,
} from '@angular/forms';
import { getControl } from '../form-control-helper.utils';

export interface SelectComponentItem {
  text: string;
  value: string | number | object;
  /** Whether to always show this entry during a search */
  alwaysShowDuringSearch?: boolean;
}

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: [FormGroupDirective, FormGroupName],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit {
  @HostBinding('class.form-group') isBootstrapFormGroup = true;
  @HostBinding('class.d-block') isBlock = true;

  @Input() bindLabel = 'text';
  @Input() bindValue = 'value';
  @Input() clearable = false;
  @Input() control!: FormControl;
  @Input() controlName = '';
  @Input() domId = '';
  @Input() useTemplate = false;

  @Input() label = '';
  @Input() labelColor: 'black' | 'white' = 'black';
  @Input() placeholder?: string;
  @Input() searchable = false;
  @Input() items: object[] = [];
  @Output() handleChange: EventEmitter<Event> = new EventEmitter();

  constructor(
    @Optional() private parentForm: FormGroupDirective,
    @Optional() private groupName: FormGroupName
  ) {}

  ngOnInit(): void {
    if (!this.control) {
      this.control = getControl(
        'ui-select',
        this.parentForm,
        this.groupName,
        this.controlName
      ) as FormControl;
    }
  }

  /** This custom search function performs the regular string search, as well as allowing always-visible entries */
  customSearchFn = (term: string, item: any) => {
    term = term.toLocaleLowerCase('en-US');
    return (
      item[this.bindLabel].toLocaleLowerCase('en-US').includes(term) ||
      item['alwaysShowDuringSearch']
    );
  };

  onChange($event: Event) {
    this.handleChange.emit($event);
  }
}
