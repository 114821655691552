import { PatientState } from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/constants';
import { Patient, PortalUser } from '@patient-ui/shared/models';

export const currentPatient: PortalUser = {
  displayName: 'Test Patient',
  id: 12345,
  isPrimary: 'primary',
};

export const otherPatient: PortalUser = {
  displayName: 'Other Patient',
  id: 99876,
  isPrimary: 'dependent',
};

export const thirdPatient: PortalUser = {
  displayName: 'Third Patient',
  id: 34567,
  isPrimary: 'dependent',
};

export const allPatients: PortalUser = {
  displayName: 'All Patients',
  id: 0,
};

export const patientUserList: PortalUser[] = [
  currentPatient,
  otherPatient,
  allPatients,
];

export const INITIAL_PATIENT_STATE: PatientState = {
  primaryAccountHolder: {
    firstName: 'Franklin',
    lastName: 'Roosevelt',
    middleName: 'D',
    dateOfBirth: '1980-01-01',
  } as Patient,
  dependentsList: [],
  addPaymentMethodStatus: RequestStatus.NotSent,
  deletePaymentMethodStatus: RequestStatus.NotSent,
  getPatientRequestStatus: RequestStatus.Success,
  loadLoggedInPatientRequestStatus: RequestStatus.Success,
  patientWallet: [],
  getPatientWalletRequestStatus: RequestStatus.NotSent,
  patientUserList: patientUserList,
  loadPatientUserListRequestStatus: RequestStatus.Success,
  updatePasswordRequestStatus: RequestStatus.NotSent,
  updatePasswordRequestError: {
    code: '',
    description: '',
  },
  createOrUpdateAddress: null,
  createOrUpdateAddressAction: 'none',
  createOrUpdateAddressOwner: null,
  createOrUpdateAddressSwitchToPrimary: false,
  createOrUpdateAddressRequestStatus: RequestStatus.NotSent,
  createOrUpdateAddressRequestResponse: '',
  updatePatientDemographicsOwner: null,
  updatePatientDemographicsData: null,
  updatePatientDemographicsRequestStatus: RequestStatus.NotSent,
  updatePatientDemographicsRequestError: {
    code: '',
    description: '',
  },
  updatePatientNotificationsOwner: null,
  updatePatientNotificationsData: null,
  updatePatientNotificationsEmail: null,
  updatePatientNotificationsRequestResponse: null,
  updatePatientNotificationsRequestStatus: RequestStatus.NotSent,
  updatePatientNotificationsRequestError: {
    code: '',
    description: '',
  },
  createOrUpdatePhone: null,
  createOrUpdatePhoneAction: 'none',
  createOrUpdatePhoneRequestStatus: RequestStatus.NotSent,
  createOrUpdatePhoneOwner: null,
  createOrUpdatePhoneSwitchToPrimary: false,
  createOrUpdatePhoneRequestResponse: '',
  deleteDependentIsLoading: false,
  isMobile: false,
  addPaymentMethodFlag: false,
  patientAppointmentsState: {
    appointments: [],
    requestStatus: RequestStatus.NotSent,
    appointmentsLoading: false,
    encryptedLpid: '',
    selectedPatientDropdownUser: {
      displayName: '',
      id: 0,
    },
    error: null,
  },
};
