import { IPortalAddress } from '@patient-ui/shared/models';
import {
  SmartyStreetsActions,
  SmartyStreetsActionTypes,
} from './smartystreets.actions';

export const smartyStreetsFeatureKey = 'smartyStreets';

export type SmartyStreetsState = {
  initializeSuccess: boolean;
  suggestResults: IPortalAddress[];
};

export const smartyStreetsInitialState: SmartyStreetsState = {
  initializeSuccess: false,
  suggestResults: [],
};

export function reducer(
  state: SmartyStreetsState = smartyStreetsInitialState,
  action: SmartyStreetsActions
) {
  switch (action.type) {
    case SmartyStreetsActionTypes.SmartyStreetsInitializeSuccess:
      state = {
        ...state,
        initializeSuccess: true,
      };
      break;
    case SmartyStreetsActionTypes.SmartyStreetsSuggestResults:
      state = {
        ...state,
        suggestResults: action.payload,
      };
      break;
    case SmartyStreetsActionTypes.ClearSmartyStreetsSuggestResults:
      state = {
        ...state,
        suggestResults: [],
      };
      break;
  }
  return state;
}
