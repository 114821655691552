import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { InvoiceService } from '../invoice/invoice.service';
import * as ProfileActions from './profile.actions';
import { ProfileService } from './profile.service';

@Injectable()
export class ProfileEffects {
  getPhoenixAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.getPhoenixAddress),
      exhaustMap((action) =>
        this.invoiceService.getPhoenixAddress(action.addressToUpdate).pipe(
          map((resp) =>
            ProfileActions.getPhoenixAddressSuccess({ addressToUpdate: resp })
          ),
          catchError((_error) => of(ProfileActions.getPhoenixAddressFailure()))
        )
      )
    )
  );

  deletePatientAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.deletePatientAccount),
      exhaustMap(() =>
        this.profileService.deletePatientAccount().pipe(
          map(() => ProfileActions.deletePatientAccountSuccess()),
          catchError((_error) =>
            of(ProfileActions.deletePatientAccountFailure())
          )
        )
      )
    )
  );

  validatePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.validatePhone),
      exhaustMap((action) =>
        this.profileService.validatePhone(action.phoneNumber).pipe(
          switchMap((response) => 
          [ProfileActions.validatePhoneSuccess({ response: response }),
            ProfileActions.resetPhone()]),
          catchError((_error) =>
            of(ProfileActions.validatePhoneFailure())
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private invoiceService: InvoiceService,
    private profileService: ProfileService
  ) {}
}
