import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { IAddress, IDuplicateRegistrationData, IRegistrationFormData, IToken } from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountRegistrationService {

  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getPostalAddress(address: IAddress): Observable<any> {
    const getUrl = encodeURI(
      `${this.envService.baseUrl}/address?city=${address.city}&state=${address.state}&street=${address.line1}&line2=${address.line2}&zip=${address.zipCode}`
    );
    return this.http.get(getUrl);
  }

  isRegistered(email: string):
    Observable<any> {
    const getUrl = encodeURI(`${this.envService.baseUrl}/patients/${email}/isRegistered`);
    return this.http.get(getUrl);
  }

  registerPatient(
    registrationFormData: IRegistrationFormData
  ): Observable<any> {
    const postUrl = encodeURI(`${this.envService.baseUrl}/patients/register/lite/v2`);
    return this.http.post(postUrl, registrationFormData);
  }

  duplicateRegistration(
    duplicateRegistration: IDuplicateRegistrationData
  ): Observable<any> {
    const postUrl = encodeURI(`${this.envService.baseUrl}/patients/dupRegister`);
    return this.http.post(postUrl, duplicateRegistration);
  }

  patientVerifyEmail(
    code: string, token: string): Observable<any> {
      const patientActivation = {
        'code': code,
        'token': token
      }
      const postUrl = encodeURI(`${this.envService.baseUrl}/patients/register/verifyemail`);
      return this.http.post(postUrl, patientActivation);
    }

  currentPatientVerifyEmail(verificationCode: string): Observable<any> {
    const patientActivation = {
      'verificationCode': verificationCode
    };

    const postUrl = encodeURI(`${this.envService.baseUrl}/patients/current/verifyemail`);
    return this.http.post(postUrl, patientActivation);
  }

  accountResendCode(token: IToken): Observable<any> {
    const postUrl = encodeURI(`${this.envService.baseUrl}/accounts/resendCode`);
    return this.http.post(postUrl, token);
  }

  currentPatientResendCode(): Observable<any> {
    const postUrl = encodeURI(`${this.envService.baseUrl}/patients/current/resendverifyemail`);
    return this.http.post(postUrl, null);
  }

  activateAccount(
    code: string, acttoken: string): Observable<any> {
    const patientActivation = {
      'code': code,
      'token': acttoken
    }
    const postUrl = encodeURI(`${this.envService.baseUrl}/patients/activateAccount`);
    return this.http.post(postUrl, patientActivation);
  }
}
