import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  FormGroupName,
} from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { getControl } from '../form-control-helper.utils';

@Component({
  selector: 'ui-date-picker-header',
  templateUrl: './date-picker-header.component.html',
  styleUrls: ['./date-picker-header.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: [FormGroupDirective, FormGroupName],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerHeaderComponent implements OnInit {
  @Input() control!: FormControl;
  @Input() controlName = '';
  @Input() domId = '';

  today!: NgbDate;
  tomorrow!: NgbDate;

  constructor(
    private ngbCalendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    @Optional() private parentForm: FormGroupDirective,
    @Optional() private groupName: FormGroupName
  ) {}

  ngOnInit(): void {
    if (!this.control) {
      this.control = getControl(
        'ui-select',
        this.parentForm,
        this.groupName,
        this.controlName
      ) as FormControl;
    }

    this.today = this.ngbCalendar.getToday();
    this.tomorrow = this.ngbCalendar.getNext(this.today, 'd', 1);

    if (!this.control.value) {
      this.control.setValue(this.today);
    }
  }

  format(ngbDate: NgbDate): string {
    // See: https://angular.io/api/common/DatePipe
    return formatDate(
      this.ngbDateParserFormatter.format(ngbDate),
      'EEEE, LLL d',
      'en-US'
    );
  }
}
