import { Address, IPhoneValidationResponse } from '@patient-ui/shared/models';
import { createAction, props } from '@ngrx/store';
import { ProfileSectionEditing } from '@patient-ui/shared/constants';

export const getPhoenixAddress = createAction(
  '[Profile] Get Phoenix Address',
  props<{ addressToUpdate: Address }>()
);

export const getPhoenixAddressSuccess = createAction(
  '[Profile] Get Phoenix Address Success',
  props<{ addressToUpdate: Address }>()
);

export const getPhoenixAddressFailure = createAction(
  '[Profile] Get Phoenix Address Failure'
);

export const deletePatientAccount = createAction(
  '[Profile] Delete Patient Account'
);

export const deletePatientAccountSuccess = createAction(
  '[Profile] Delete Patient Account Success'
);

export const deletePatientAccountFailure = createAction(
  '[Profile] Delete Patient Account Failure'
);

export const setSectionInEditMode = createAction(
  '[Profile] Set SectionInEditMode',
  props<{ section: ProfileSectionEditing }>(),
);

export const changeProfileSelection = createAction(
  '[Profile] Change Profile Selection',
  props<{ activeId: number }>()
);

export const validatePhone = createAction(
  '[Profile] Validate Phone',
  props<{ phoneNumber: string }>()
);

export const validatePhoneSuccess = createAction(
  '[Profile] Validate Phone Success',
  props<{ response: IPhoneValidationResponse }>()
);

export const validatePhoneFailure = createAction(
  '[Profile] Validate Phone Failure',
);

export const resetPhone = createAction(
  '[Profile] Reset Phone',
);
