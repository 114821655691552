import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function fieldsMatchValidator(
  controlName: string,
  matchingControlName: string,
  caseSensitive = true
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = (formGroup as FormGroup).controls[controlName];
    const matchingControl = (formGroup as FormGroup).controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // start out assuming they match
    matchingControl.setErrors(null);

    // set error on matchingControl if validation fails
    if (caseSensitive) {
      // default case
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ fieldsMatch: true });
        return { fieldsMatch: true };
      }
    } else if (
      control.value &&
      control.enabled &&
      matchingControl.value &&
      matchingControl.enabled
    ) {
      // case insensitive compare
      if (control.value.toLowerCase() !== matchingControl.value.toLowerCase()) {
        matchingControl.setErrors({ fieldsMatch: true });
        return { fieldsMatch: true };
      }
    }

    return null;
  };
}
