import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PendoState } from '@patient-ui/patient-web/store';
import { IInvoiceSearch, IPatient } from '@patient-ui/shared/models';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { selectPendoInitializeSuccess } from './pendo.selectors';
import { WindowRef } from './window-ref.services';

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  constructor(public windowRef: WindowRef, private store: Store<PendoState>) {}

  getPendoState(): boolean {
    let isInitializeSuccess = false;
    this.store
      .select(selectPendoInitializeSuccess)
      .pipe(take(1))
      .subscribe((state) => {
        isInitializeSuccess = state;
        return isInitializeSuccess;
      });
    return isInitializeSuccess;
  }

  pendoReady(): boolean {
    return (
      this.getPendoState() &&
      typeof this.windowRef.nativeWindow.pendo.isReady === 'function' &&
      this.windowRef.nativeWindow.pendo.isReady()
    );
  }

  pendoInit(visitorInfo: any): void {
    if (this.pendoReady()) {
      this.windowRef.nativeWindow.pendo.identify(visitorInfo);
    } else {
      this.windowRef.nativeWindow.pendo.initialize(visitorInfo);
      this.store.select(selectPendoInitializeSuccess);
    }
  }

  setUnknownUser(): void {
    this.pendoInit({
      visitor: {
        Role: 'UnknownUser',
        Timestamp: moment().format('YYYY/MM/DD HH:mm:ss'),
      },
    });
  }

  setGuestUser(invoiceSearchValues: IInvoiceSearch[]): void {
    const invoiceArray = [...new Set(invoiceSearchValues)];
    let invoicesStr = '';
    invoiceArray.forEach((search, index) => {
      invoicesStr =
        invoicesStr +
        search.invoiceNumber +
        '_' +
        (search.zipCode.length > 5
          ? search.zipCode.substring(0, 5)
          : search.zipCode);
      if (invoiceArray.length > index + 1) {
        invoicesStr = invoicesStr + ',';
      }
    });
    this.pendoInit({
      visitor: {
        Timestamp: moment().format('YYYY/MM/DD HH:mm:ss'),
        InvoiceZip: invoicesStr,
      },
    });
  }

  setRegisteredUser(patient: IPatient) {
    this.pendoInit({
      visitor: {
        id: patient.id?.toString(),
        LPID: patient.hashId,
        Timestamp: moment().format('YYYY/MM/DD HH:mm:ss'),
        Gender: patient.gender,
        Dob: patient.dateOfBirth.toString(),
        City: patient.primaryAddress
          ? patient.primaryAddress?.city
          : patient.addresses
          ? patient.addresses[0].city
          : '',
        State: patient.primaryAddress
          ? patient.primaryAddress?.state
          : patient.addresses
          ? patient.addresses[0].state
          : '',
        Zip: patient.primaryAddress
          ? patient.primaryAddress?.zipCode
          : patient.addresses
          ? patient.addresses[0].zipCode
          : '',
      },
    });
  }
}
