import { createFeatureSelector, createSelector } from '@ngrx/store';
import { microbiomeFeatureKey, MicrobiomeState } from './microbiome.reducer';

export const microbiomeStateSelector = createFeatureSelector<MicrobiomeState>(
  microbiomeFeatureKey
);

export const selectCurrentMicrobiomeSurvey = createSelector(
  microbiomeStateSelector,
  (state) => state.microbiomeSurvey
);

export const selectCurrentMicrobiomeSurveyResponse = createSelector(
  microbiomeStateSelector,
  (state) => state.microbiomeResponse
);

export const selectCurrentMicrobiomeOrderId = createSelector(
  microbiomeStateSelector,
  (state) => state.microbiomeSurvey.labcorpOrderId
);

export const microbiomeQuery = {
  microbiomeStateSelector,
  selectCurrentMicrobiomeSurvey,
  selectCurrentMicrobiomeSurveyResponse,
  selectCurrentMicrobiomeOrderId,
};
