import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';
import { OrderHistoryResponse } from '@patient-ui/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelfOrderService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getSelfOrders(): Observable<OrderHistoryResponse> {
    const url = `${this.envService.baseUrl}/patients/current/orders`;
    // console.log(this.envService.baseUrl);
    // const url = `http://localhost:8080/patients/current/orders`;
    return this.http.get<OrderHistoryResponse>(url);
  }

  getOrderPdf(orderId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob' as 'text',
    };
    const orderPdfUrl = `${this.envService.baseUrl}/patients/current/orders/${orderId}/pdf`;
    return this.http.get<any>(orderPdfUrl, httpOptions as object);
  }
}
