import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit {
  @Input() story = false; // only used for storybook stories
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() header: 'simple' | 'custom' = 'simple';
  @Input() headerText = 'This is the Heading';
  @Input() closeIcon = false;
  @Input() footer: 'none' | 'simple' = 'simple';
  @Input() footerLinkText = 'Cancel';
  @Input() footerButtonText = 'Submit';
  @Input() footerLinkFlag = false;
  @Input() footerButtonFlag = true;
  @Output() modalClickedFlag: EventEmitter<void> = new EventEmitter();

  @ViewChild('modalRef') modal!: TemplateRef<any>;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  open() {
    this.modalService.dismissAll();
    return this.modalService
      .open(this.modal, {
        size: this.size, // define width of modal
        backdrop: 'static', // don't let modal be closed by clicking outside it
        keyboard: false, // don't let modal be closed via Esc key
      })
      .result.then(
        (result) => {
          this.modalClickedFlag.emit(result);
          return result;
        },
        (reason) => reason
      );
  }
}
