export class WalletInfo {
  walletId?: string;
  walletName?: string;
  cardId?: string;
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  cardType?: string;
  expMon?: string;
  expYr?: string;
  routingNumber?: string;
  token?: string;
  maskCard!: number;
  businessName?: string;
  accountType?: string;
  accountId?: string;
  lpid?: string;
  paymentMethod?: string;
}
