import { ReportHeader } from './ReportHeader.model';

export class ReportHeaderSummary {
  new: ReportHeader[] = [];
  upcoming: ReportHeader[] = [];
  updated: ReportHeader[] = [];
  recently_viewed: ReportHeader[] = [];
  totalNew!: number;
  totalUpcoming!: number;
  totalUpdated!: number;
  totalRecentlyViewed!: number;
  totalAll!: number;
  constructor(reportHeaderSummary: ReportHeaderSummary) {
    if (reportHeaderSummary) {
      this.new = reportHeaderSummary.new;
      this.upcoming = reportHeaderSummary.upcoming;
      this.updated = reportHeaderSummary.updated;
      this.recently_viewed = reportHeaderSummary.recently_viewed;
      this.totalNew = reportHeaderSummary.totalNew;
      this.totalUpcoming = reportHeaderSummary.totalUpcoming;
      this.totalUpdated = reportHeaderSummary.totalUpdated;
      this.totalRecentlyViewed = reportHeaderSummary.totalRecentlyViewed;
      this.totalAll = reportHeaderSummary.totalAll;
    }
  }
}
