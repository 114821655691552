import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

let nextId = 0;

@Component({
  selector: 'ui-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseComponent {
  @Input() type: 'simple' | 'add' = 'simple';
  @Input() header = '';
  @Input() headerButton = 'Add';
  @Input() alertTemplate!: TemplateRef<any>;
  @Input() processing!: TemplateRef<any>;

  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();

  @Input() completed = false;
  @Output() completedChange = new EventEmitter<boolean>();

  @Input() saveButton = 'Save';
  @Input() saveDisabled = false;

  @Output() saveClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  @Input() isProcessing = false;

  id = nextId++;

  setOpen(newValue: boolean) {
    this.open = newValue;
    this.openChange.emit(this.open);
  }

  setCompleted(newValue: boolean) {
    this.completed = newValue;
    this.completedChange.emit(this.completed);
  }

  toggleOpen() {
    this.setOpen(!this.open);
    if (this.completed) this.setCompleted(false);
  }

  cancelAndClose() {
    this.setOpen(false);
    if (this.completed) this.setCompleted(false);
    this.cancelClicked.emit();
  }

  saveAndCloseCompleted() {
    this.setOpen(false);
    this.setCompleted(true);
    this.saveClicked.emit();
  }
}
