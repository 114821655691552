import { IPatient } from '@patient-ui/shared/models';
import { PendoActions, PendoActionTypes } from './pendo.actions';

export const pendoFeatureKey = 'pendo';

export type PendoState = {
  initializeSuccess: boolean;
  registeredPatient: IPatient | undefined;
};

export const pendoInitialState: PendoState = {
  initializeSuccess: false,
  registeredPatient: undefined,
};

export function reducer(
  state: PendoState = pendoInitialState,
  action: PendoActions
) {
  switch (action.type) {
    case PendoActionTypes.PendoInitializeSuccess:
      state = {
        ...state,
        initializeSuccess: true,
      };
      break;
    case PendoActionTypes.PendoSetRegisteredUser:
      /*
       * Don't update the state unless the registered user changed.
       */
      if (
        !state.initializeSuccess ||
        action.payload.id !== state.registeredPatient?.id
      ) {
        state = {
          ...state,
          registeredPatient: action.payload,
          initializeSuccess: true,
        };
      }
      break;
  }
  return state;
}
