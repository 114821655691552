import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderEntity } from '@patient-ui/shared/models';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as SelfOrdersActions from './self-orders.actions';
import { SelfOrderService } from './self-orders.service';

@Injectable()
export class SelfOrdersEffects {
  constructor(
    private actions$: Actions,
    private selfOrdersService: SelfOrderService
  ) {}

  loadSelfOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelfOrdersActions.loadSelfOrders),
      exhaustMap(() =>
        this.selfOrdersService.getSelfOrders().pipe(
          map((response) => {
            const oList: OrderEntity[] = [];
            if (response.orderList) {
              for (const order of response.orderList) {
                const newOrder = new OrderEntity(order);
                oList.push(newOrder);
              }
            }
            return SelfOrdersActions.loadSelfOrdersSuccess({
              selfOrders: oList.sort((a, b) => b.orderId - a.orderId),
            });
          }),
          catchError((_error) => of(SelfOrdersActions.loadSelfOrdersFailure()))
        )
      )
    )
  );

  getOrderPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelfOrdersActions.getOrderPdf),
      exhaustMap((action) =>
        this.selfOrdersService.getOrderPdf(action.orderId).pipe(
          map((res) => {
            const payload = new Blob([res], { type: 'application/pdf' });
            return SelfOrdersActions.getOrderPdfSuccess({ payload });
          }),
          catchError((_error) => of(SelfOrdersActions.getOrderPdfFailure()))
        )
      )
    )
  );
}
